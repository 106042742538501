import {
  Box,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import CustomTypography from "./CustomTypography.web";
import { WithTranslation, withTranslation } from "react-i18next";

export enum KitchenOrderAcceptStatus {
  Approved = 1,
  Refund = 2,
  PartyRefund = 3,
}

export interface KitchenOrderAcceptStatusMenuOption {
  id: string;
  name: string;
  value: number | string;
}

export interface Props extends WithStyles, WithTranslation {
  value: string | number;
  options: KitchenOrderAcceptStatusMenuOption[];

  onChange(value: string | number): void;
}

const styles = createStyles({
  status: {
    maxWidth: 150,
    border: "1px solid #CBD5E1",
    cursor: "pointer",
    fontFamily: "Raleway",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    padding: "3px 10px",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
  },

  statusGreen: {
    backgroundColor: "#059669",
  },

  statusRed: {
    backgroundColor: "#DC2626",
  },

  statusLightGreen: {
    backgroundColor: "#D1FAE5",
    color: "#333333",
  },

  statusExpand: {
    marginLeft: "auto",
  },

  acceptStatusItem: {
    display: "flex",
    alignItems: "center",
    padding: "9px 18px",

    "&.is-selected": {
      backgroundColor: "#FC9797",
      borderLeft: 3,
      borderLeftColor: "#FF6666",
    },
  },

  acceptStatusName: {
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    fontWeight: "700 !important" as any,
    display: "flex",
    alignItems: "center",

    "&::before": {
      content: "''",
      width: 5,
      height: 5,
      display: "block",
      borderRadius: "50%",
      marginRight: 8,
    },

    "&.green": {
      color: "#059669",

      "&::before": {
        backgroundColor: "#059669",
      },
    },

    "&.red": {
      color: "#DC2626",

      "&::before": {
        backgroundColor: "#DC2626",
      },
    },

    "&.light-green": {
      color: "#333333",

      "&::before": {
        backgroundColor: "#333333",
      },
    },
  },
});

export class KitchenOrderAcceptStatusMenu extends React.Component<
  Props,
  {
    statusMenu: HTMLElement | null;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      statusMenu: null,
    };
  }

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null,
    });
  };

  changeFilter = (filter: number | string) => {
    this.props.onChange(filter);

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { value, classes, options, t } = this.props;
    const { statusMenu } = this.state;
    const id = uuidv4();

    const selected = options.find((item) => item.value === value);

    return (
      <Box className={classes.acceptStatus}>
        <Box
          className={clsx(classes.status, {
            [classes.statusGreen]: [
              "Approved",
              "Order is Preparing",
              "Completed",
            ].includes(selected!.name),
            [classes.statusRed]: selected!.name === "Declined",
            [classes.statusLightGreen]: selected!.name === "Not Started",
          })}
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          {selected ? t(selected?.name) : ""}

          <ExpandMore className={classes.statusExpand} />
        </Box>
        <Menu
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.acceptStatus,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {options.map((filter) => {
            return (
              <MenuItem
                key={filter.id}
                className={classes.acceptStatusItem}
                onClick={() => this.changeFilter(filter.value)}
              >
                <CustomTypography
                  className={clsx(classes.acceptStatusName, {
                    ["green"]: [
                      "Approved",
                      "Order is Preparing",
                      "Completed",
                    ].includes(filter.name),
                    ["red"]: filter.name === "Declined",
                    ["light-green"]: filter.name === "Not Started",
                  })}
                >
                  {t(filter.name)}
                </CustomTypography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(KitchenOrderAcceptStatusMenu)
);
