import {
  Box,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomDialog from "./CustomDialog.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import { AdminUserManagementAction } from "./AdminUserManagementMenu.web";

export interface Props extends WithStyles, WithTranslation {
  dialog: AdminUserManagementFormDialogProps;

  onClose(): void;
  addAdminUser(values: any): void;
  editAdminUser(values: any): void;
}

export interface AdminUserManagementFormDialogProps {
  mode: AdminUserManagementAction;

  open: boolean;

  loading: boolean;

  form: {
    id: string | number;
    restaurantName: string;
    branch: string;
    staffName: string;
    email: string;
    role: string;
    status: string;
  };

  roleOptions: {
    id: string;
    name: string;
    value: string | number;
  }[];

  branchOptions: {
    id: string;
    name: string;
    value: string | number;
  }[];

  statusOptions: {
    id: string;
    name: string;
    value: string | number;
  }[];
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 0,
    },

    formNameGroup: {
      marginTop: 20,
      display: "flex",
      gap: 15,

      "& > div": {
        marginTop: "0 !important",
      },
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%",
      justifyContent: "end",

      "& > button": {
        width: 120,
      },
    },

    formPriceInput: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    orderNumber: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    datePlaced: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    formInput: {
      marginTop: 20,
    },

    cancel: {
      color: "#FF6666",
      backgroundColor: "#FFEAEA",
    },
  });

export class AdminUserManagementFormDialog extends React.Component<Props> {
  private formProps?: FormikProps<any> = undefined;

  constructor(props: Props) {
    super(props);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render(): React.ReactNode {
    const {
      classes,
      dialog,
      t,
      i18n,

      onClose,
      addAdminUser,
      editAdminUser,
    } = this.props;

    const { mode, open, form, roleOptions, statusOptions, branchOptions } =
      dialog;

    const tilte =
      mode === AdminUserManagementAction.Edit ? t("Edit User") : t("Add User");

    const rtl = i18n.language === "ar";

    const validationSchema = Yup.object({
      restaurantName: Yup.string()
        .nullable()
        .required(t("Please enter Restaurant Name")),
      branch: Yup.string().nullable().required(t("Please enter Branch")),
      staffName: Yup.string().nullable().required(t("Please enter Staff Name")),
      role: Yup.string().nullable().required(t("Please select Role")),
      email: Yup.string()
        .nullable()
        .required(t("Please enter Email"))
        .email(t("Please enter valid Email")),
      status: Yup.string().nullable().required(t("Please enter Status")),
    });

    const { restaurantName, branch, staffName, email, role, status } = form;

    return (
      <CustomDialog
        dir={rtl ? "rtl" : ""}
        width={600}
        open={open}
        title={tilte}
        onClose={onClose}
        content={
          <Formik
            initialValues={{
              restaurantName,
              branch,
              staffName,
              email,
              role,
              status,
            }}
            validationSchema={validationSchema}
            data-test-id="admin-user-formik"
            onSubmit={(values) => {
              if (mode === AdminUserManagementAction.Edit) {
                editAdminUser(values);

                return;
              }

              addAdminUser(values);
            }}
          >
            {(props) => {
              this.formProps = props;

              return (
                <Form
                  data-test-id="kitchen-item-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      className={classes.orderNumber}
                      fullWidth
                      name="restaurantName"
                      label={t("Restaurant Name")}
                      placeholder={t("Enter Restaurant Name")}
                      variant="outlined"
                      error={
                        props.touched.restaurantName &&
                        !!props.errors.restaurantName
                      }
                      data-test-id="restaurant-name-input"
                      required
                    />

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        dir={rtl ? "rtl" : ""}
                        fullWidth
                        name="branch"
                        label={t("Branch")}
                        variant="outlined"
                        error={props.touched.branch && !!props.errors.branch}
                        data-test-id="branch-select"
                        displayEmpty
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return t("Select Branch");
                            }

                            const selectedOption = branchOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return selectedOption.name;
                          },
                        }}
                      >
                        {branchOptions.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        className={classes.orderNumber}
                        fullWidth
                        name="staffName"
                        label={t("Staff Name")}
                        placeholder={t("Enter Staff Name")}
                        variant="outlined"
                        error={
                          props.touched.restaurantName &&
                          !!props.errors.restaurantName
                        }
                        data-test-id="restaurant-name-input"
                        required
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        className={classes.formPriceInput}
                        fullWidth
                        name="email"
                        label={t("Email")}
                        placeholder={t("Enter Email")}
                        variant="outlined"
                        error={props.touched.email && !!props.errors.email}
                        data-test-id="email-input"
                        required
                        value={props.values.email}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        dir={rtl ? "rtl" : ""}
                        fullWidth
                        name="role"
                        label={t("Role")}
                        variant="outlined"
                        error={props.touched.role && !!props.errors.role}
                        data-test-id="role-select"
                        displayEmpty
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return t("Select Role");
                            }

                            const selectedOption = roleOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return t(selectedOption.name);
                          },
                        }}
                      >
                        {roleOptions.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <ListItemText primary={t(option.name)} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        dir={rtl ? "rtl" : ""}
                        fullWidth
                        name="status"
                        label={t("Status")}
                        variant="outlined"
                        error={props.touched.status && !!props.errors.status}
                        data-test-id="status-select"
                        displayEmpty
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return t("Select Status");
                            }

                            const selectedOption = statusOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return t(selectedOption.name);
                          },
                        }}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            <ListItemText primary={t(option.name)} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <Box className={classes.formBottom}>
            <CustomButton
              className={classes.cancel}
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              data-test-id="submit"
              type="submit"
              onClick={() => this.formProps?.handleSubmit()}
            >
              {mode === AdminUserManagementAction.Edit
                ? t("Save")
                : t("Create")}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminUserManagementFormDialog)
);
