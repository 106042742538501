import {
  Box,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomDialog from "./CustomDialog.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import CustomFormDatePickerField from "./CustomFormDatePickerField.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { AdminPromotionManagementAction } from "./AdminPromotionManagementMenu.web";

export interface Props extends WithStyles, WithTranslation {
  dialog: AdminPromotionDetailFormDialogProps;

  onClose(): void;
  editAdminPromotion(values: any): void;
  changePromotionDetailDialogMode(mode: AdminPromotionManagementAction): void;
}

export interface AdminPromotionDetailFormDialogProps {
  mode: AdminPromotionManagementAction;
  open: boolean;
  loading: boolean;
  form: {
    id: string;
    promotionId: string;
    name: string;
    startDate: Date;
    endDate: Date;
    discountType: string;
    discount: string;
    status: string;
    amount: number;
  };
  discountTypeOptions: {
    id: string;
    name: string;
    value: string | number;
  }[];
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 0,
    },

    formNameGroup: {
      marginTop: 20,
      display: "flex",
      gap: 15,

      "& > div": {
        marginTop: "0 !important",
      },
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%",
      justifyContent: "end",

      "& > button": {
        width: 120,
      },
    },

    formPriceInput: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    promotionId: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    startDate: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    endDate: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    formInput: {
      marginTop: 20,
    },

    cancel: {
      color: "#FF6666",
      backgroundColor: "#FFEAEA",
    },
  });

export class AdminPromotionDetailFormDialog extends React.Component<Props> {
  private formProps?: FormikProps<any> = undefined;

  constructor(props: Props) {
    super(props);
  }

  statusOptions: {
    id: string;
    name: string;
    value: string | number;
  }[] = [];

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render(): React.ReactNode {
    const {
      classes,
      dialog,
      t,
      i18n,

      onClose,
      editAdminPromotion,
      changePromotionDetailDialogMode,
    } = this.props;

    const { mode, open, form, discountTypeOptions } = dialog;

    const disabledEdit = mode === AdminPromotionManagementAction.View;

    const tilte =
      mode === AdminPromotionManagementAction.Edit
        ? t("Edit Promotion")
        : t("View Promotion");

    const rtl = i18n.language === "ar";

    const validationSchema = Yup.object({
      name: Yup.string().nullable().required(t("Please enter Promotion Name")),
      discountType: Yup.string()
        .nullable()
        .required(t("Please enter Discount Type")),
      discount: Yup.string().nullable().required(t("Please enter Discount")),
      startDate: Yup.string().nullable().required(t("Please enter Start Date")),
      endDate: Yup.string().nullable().required(t("Please enter EndDate")),
      amount: Yup.number()
        .nullable()
        .required(t("Please enter Amount"))
        .positive(t("Please enter valid Amount"))
        .typeError(t("Please enter valid Amount")),
      status: Yup.string().nullable().required(t("Please enter Status")),
    });

    return (
      <CustomDialog
        dir={rtl ? "rtl" : ""}
        width={600}
        open={open}
        title={tilte}
        onClose={onClose}
        content={
          <Formik
            initialValues={{
              promotionId: form.promotionId,
              name: form.name,
              startDate: form.startDate,
              endDate: form.endDate,
              discountType: form.discountType,
              discount: form.discount,
              status: form.status,
              amount: form.amount,
            }}
            validationSchema={validationSchema}
            onSubmit={editAdminPromotion}
            data-test-id="admin-promotion-formik"
          >
            {(props) => {
              this.formProps = props;

              return (
                <Form
                  data-test-id="admin-promotion-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      className={classes.promotionId}
                      fullWidth
                      name="promotionId"
                      label={t("Promotion ID")}
                      placeholder={t("Enter Promotion ID")}
                      variant="outlined"
                      error={
                        props.touched.promotionId && !!props.errors.promotionId
                      }
                      data-test-id="promotion-id-input"
                      required
                      disabled
                    />

                    <CustomFormInputField
                      fullWidth
                      name="name"
                      label={t("Promotion Name")}
                      placeholder={t("Enter Promotion Name")}
                      variant="outlined"
                      error={props.touched.name && !!props.errors.name}
                      data-test-id="promotion-name-input"
                      required
                      disabled={disabledEdit}
                    />

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        required
                        dir={rtl ? "rtl" : ""}
                        fullWidth
                        name="discountType"
                        label={t("Discount Type")}
                        variant="outlined"
                        error={
                          props.touched.discountType &&
                          !!props.errors.discountType
                        }
                        data-test-id="discount-type-select"
                        displayEmpty
                        disabled={disabledEdit}
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return t("Select Discount Type");
                            }

                            const selectedOption = discountTypeOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return selectedOption.name;
                          },
                        }}
                      >
                        {discountTypeOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.value}
                            style={{
                              textAlign: rtl ? "right" : "left",
                            }}
                          >
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        className={classes.formPriceInput}
                        fullWidth
                        name="discount"
                        label={t("Discount")}
                        placeholder={t("Enter Discount")}
                        variant="outlined"
                        error={
                          props.touched.discount && !!props.errors.discount
                        }
                        data-test-id="discount-input"
                        required
                        value={props.values.discount || ""}
                        disabled={disabledEdit}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        className={classes.formPriceInput}
                        fullWidth
                        name="amount"
                        label={t("Amount")}
                        placeholder={t("Enter Amount")}
                        variant="outlined"
                        error={props.touched.amount && !!props.errors.amount}
                        data-test-id="amount-input"
                        required
                        value={props.values.amount || ""}
                        disabled={disabledEdit}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormDatePickerField
                        className={classes.startDate}
                        dateFormat="yyyy-MM-dd"
                        name="startDate"
                        label={t("Start Date")}
                        placeholder={t("Enter Start Date")}
                        variant="outlined"
                        error={
                          props.touched.startDate && !!props.errors.startDate
                        }
                        data-test-id="start-date-input"
                        required
                        fullWidth
                        value={props.values.startDate}
                        disabled={disabledEdit}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormDatePickerField
                        className={classes.endDate}
                        dateFormat="yyyy-MM-dd"
                        name="endDate"
                        label={t("End Date")}
                        placeholder={t("Enter End Date")}
                        variant="outlined"
                        error={props.touched.endDate && !!props.errors.endDate}
                        data-test-id="end-date-input"
                        required
                        fullWidth
                        value={props.values.endDate}
                        disabled={disabledEdit}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        dir={rtl ? "rtl" : ""}
                        fullWidth
                        name="status"
                        label={t("Status")}
                        variant="outlined"
                        error={props.touched.status && !!props.errors.status}
                        data-test-id="status-select"
                        displayEmpty
                        disabled={disabledEdit}
                        required
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return t("Select Status");
                            }

                            const selectedOption = this.statusOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return t(selectedOption.name);
                          },
                        }}
                      >
                        {this.statusOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.value}
                            style={{
                              textAlign: rtl ? "right" : "left",
                            }}
                          >
                            <ListItemText primary={t(option.name)} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <Box className={classes.formBottom}>
            <CustomButton
              className={classes.cancel}
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              data-test-id="submit"
              type={
                mode === AdminPromotionManagementAction.Edit
                  ? "submit"
                  : "button"
              }
              onClick={() => {
                if (mode === AdminPromotionManagementAction.Edit) {
                  this.formProps?.handleSubmit();

                  return;
                }

                changePromotionDetailDialogMode(
                  AdminPromotionManagementAction.Edit
                );
              }}
            >
              {mode === AdminPromotionManagementAction.Edit
                ? t("Save")
                : t("Edit")}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminPromotionDetailFormDialog)
);
