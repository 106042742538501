import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { MouseEventHandler } from "react";
import CustomTypography from "../CustomTypography.web";
import { ExpandMore, ScheduleRounded } from "@material-ui/icons";
import clsx from "clsx";
import {
  BRANCH_STATUS,
  Branch,
  Schedule,
} from "../../../blocks/location/src/LocationBranchStatusController.web";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomLocationScheduleEditDialog from "./CustomLocationScheduleEditDialog.web";
import { TFunction } from "i18next";

interface Props extends WithStyles, WithTranslation {
  restaurant: Branch;
  handleUpdate: MouseEventHandler<HTMLSpanElement>;
}

const styles = createStyles({
  row: {
    marginBlockStart: 10,
    borderRadius: 8,
    boxShadow: "initial",
    border: "1px solid #EEEEEE",
    "&::before": {
      content: "''",
      opacity: 0,
    },
    "& .summary__title, & .details__title": {
      fontSize: "0.875rem",
      lineHeight: 22 / 14,
    },
  },
  accordionSummary: {
    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&.Mui-expanded": {
        marginBlockEnd: 4,
      },
    },
    "& .summary__label": {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: 1.5,
      padding: "0.25em 0.625em",
      borderRadius: 8,
      textTransform: "capitalize",
      "&.summary__label--open": {
        color: "#FFFFFF",
        backgroundColor: "#FF6666",
      },
      "&.summary__label--busy": {
        color: "#333333",
        backgroundColor: "#FFCC00",
      },
      "&.summary__label--close": {
        color: "#FFFFFF",
        backgroundColor: "#333333",
      },
    },
  },
  accordionDetails: {
    paddingBlockStart: 0,
    "& .details__content": {
      flex: 1,
    },
    "& .details__title": {
      color: "#FF6666",
      fontWeight: 600,
      marginBlockStart: 8,
    },
    "& .details__description": {
      fontSize: "0.875rem",
      lineHeight: 24 / 14,
    },
    "& .details__schedules": {
      display: "flex",
      gap: 14,
      marginBlockStart: 15,
      "& .details__schedule": {
        flex: 1,
        display: "grid",
        gridTemplateColumns: "auto 50px",
        gridTemplateRows: "1fr 1fr 1fr",
        padding: "12px 14px",
        borderRadius: 8,
        alignItems: "center",
        "& .details__schedule__icon": {
          fontSize: "3.125rem",
          gridColumn: "-1/-2",
          gridRow: "-1/1",
        },
      },
      "& .MuiTypography-root": {
        color: "#FFFFFF",
        "&.font-bold": {
          fontWeight: 600,
        },
        "&.font-inter": {
          fontFamily: "Inter",
        },
      },
      "& .details__schedule__title": {
        lineHeight: 22 / 14,
        fontFamily: "Inter",
      },
      "& .details__schedule--regular": {
        backgroundColor: "#333333",
      },
      "& .details__schedule--special": {
        backgroundColor: "#FF6666",
      },
    },
  },
  btnUpdateInformation: {
    marginInlineStart: "auto",
  },
});

const checkSchedule = (schedule: Schedule | null) => {
  if (
    !schedule ||
    Array.isArray(schedule.timings) ||
    Object.keys(schedule).length === 0
  ) {
    return {
      from: "",
      to: "",
      days: [],
    };
  }

  return {
    from: schedule.timings?.from ?? "",
    to: schedule.timings?.to ?? "",
    days: schedule.days,
  };
};

const formatWorkingDays = (
  t: TFunction<"translation", undefined>,
  days: string[]
) => {
  if (days.length === 7) {
    return t("Mon to Sun");
  }

  return days.map((_day) => t(_day)).join(", ");
};

class CustomLocationBranchRow extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, restaurant, handleUpdate, t, i18n } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const specialSchedule = checkSchedule(restaurant.special_schedule);
    const regularSchedule = checkSchedule(restaurant.regular_schedue);

    return (
      <Accordion className={classes.row} dir={dir}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMore />}
        >
          {restaurant.status && (
            <CustomTypography
              className={clsx(
                "summary__label",
                restaurant.status === BRANCH_STATUS.OPEN &&
                  "summary__label--open",
                restaurant.status === BRANCH_STATUS.CLOSED &&
                  "summary__label--close",
                restaurant.status === BRANCH_STATUS.BUSY &&
                  "summary__label--busy"
              )}
            >
              {t(
                restaurant.status.charAt(0).toLocaleUpperCase() +
                  restaurant.status.slice(1)
              )}
            </CustomTypography>
          )}

          <Box style={{ marginInlineStart: 24 }}>
            <CustomTypography variant="h2" className={"summary__title"}>
              {restaurant.restaurant_name}, {restaurant.locations}
            </CustomTypography>
            <CustomTypography variant="body1" className={"summary__title"}>
              {restaurant.status_message}
            </CustomTypography>
          </Box>

          <CustomTypography
            variant="button"
            className={classes.btnUpdateInformation}
            onClick={handleUpdate}
            id={restaurant.id}
          >
            {t("Update information")}
          </CustomTypography>
        </AccordionSummary>

        <AccordionDetails className={classes.accordionDetails}>
          <div className="details__content" dir={dir}>
            <CustomTypography className="details__title">
              {t("About us")}:
            </CustomTypography>
            <CustomTypography className="details__description">
              {restaurant.about}
            </CustomTypography>
            <CustomTypography className="details__title">
              {t("Address")}:
            </CustomTypography>
            <CustomTypography className="details__description">
              {restaurant.street_name} - {restaurant.locations} -{" "}
              {restaurant.city}
            </CustomTypography>
            <div className="details__schedules">
              <div className="details__schedule details__schedule--regular">
                <CustomTypography className="details__schedule__title font-bold">
                  {t("Regular Schedule")}
                </CustomTypography>
                <CustomTypography>
                  {t("This is the schedule you follow on a day to day basis")}
                </CustomTypography>
                <CustomTypography className="font-bold font-inter">
                  {formatWorkingDays(t, regularSchedule.days)}
                  <br />
                  {regularSchedule.from} {t("to")} {regularSchedule.to}
                  <CustomLocationScheduleEditDialog
                    type="regular"
                    initValues={regularSchedule}
                    id={restaurant?.id ?? ""}
                  />
                </CustomTypography>

                <ScheduleRounded
                  htmlColor="#FFFFFF"
                  className="details__schedule__icon"
                />
              </div>
              <div className="details__schedule details__schedule--special">
                <CustomTypography className="details__schedule__title font-bold">
                  {t("Special Schedule")}
                </CustomTypography>
                <CustomTypography>
                  {t("This is the schedule your restaurant on this day")}
                </CustomTypography>
                <CustomTypography className="font-bold font-inter">
                  {formatWorkingDays(t, specialSchedule.days)}
                  <br />
                  {specialSchedule.from} {t("to")} {specialSchedule.to}
                  <CustomLocationScheduleEditDialog
                    type="special"
                    initValues={specialSchedule}
                    id={restaurant?.id ?? ""}
                  />
                </CustomTypography>

                <ScheduleRounded
                  htmlColor="#FFFFFF"
                  className="details__schedule__icon"
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomLocationBranchRow));
