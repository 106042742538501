import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../CustomButton.web";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomFormInputFieldWeb from "../CustomFormInputField.web";
import { CloseRounded } from "@material-ui/icons";
import CustomTypographyWeb from "../CustomTypography.web";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import {
  DiscountType,
  DiscountValue,
} from "../../../blocks/promocodes/src/PromocodesPromotionsListController.web";

interface Props extends WithTranslation {
  type: "discount_value" | "discount_type";
  onCreate(payload: any): void;
  existedOptions: DiscountValue[] | DiscountType[];
}

type State = {
  open: boolean;
};

const CustomButtonToggle = styled(CustomButton)({
  justifyContent: "flex-start",
  paddingInline: 0,
  fontWeight: 600,
});

export const StylesDialog = withStyles({
  paper: {
    width: "100%",
    maxWidth: 600,
    borderRadius: 8,
    borderBottomRightRadius: 32,
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
  },
})(Dialog);

export const StylesDialogTitle = withStyles(({ typography: { pxToRem } }) => ({
  root: {
    paddingInlineStart: 40,
    paddingInlineEnd: 16,
    paddingBlock: 24,
    "& h2.MuiTypography-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .StylesDialogTitle__title": {
        fontSize: pxToRem(20),
        lineHeight: 28 / 20,
        fontWeight: 700,
      },
    },
  },
}))(DialogTitle);

export const StylesDialogContent = withStyles({
  root: {
    padding: 0,
    paddingBlock: 24,
    paddingInlineStart: 40,
    paddingInlineEnd: 16,
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    "& label.MuiFormLabel-root.MuiInputLabel-root": {
      color: "#64748B",
      fontWeight: 600,
    },
    "& input.MuiOutlinedInput-input::placeholder": {
      opacity: 1,
    },
  },
})(DialogContent);

export const StylesDialogActions = withStyles({
  root: {
    paddingInlineStart: 40,
    paddingInlineEnd: 16,
    paddingBlock: 24,
    gap: 16,
    "& button": {
      maxWidth: 120,
      height: 56,
      boxShadow: "none",
    },
    "& button.StylesDialogActions__btnCancel": {
      color: "#F66",
      backgroundColor: "#FFEAEA",
    },
  },
})(DialogActions);

class CustomPromocodesCreateDiscountDialog extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render(): React.ReactNode {
    const { onCreate, i18n, t, type, existedOptions } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const isDiscountValue = type === "discount_value";

    const existedOptionsInString = (
      existedOptions as (DiscountType | DiscountValue)[]
    ).map((option) =>
      "discount_type" in option.attributes
        ? option.attributes.discount_type.toLocaleLowerCase()
        : option.attributes.discount_value.toLocaleLowerCase()
    );

    return (
      <>
        <CustomButtonToggle
          variant="text"
          color="primary"
          dir={dir}
          onClick={this.open}
        >
          {t(
            isDiscountValue ? "Create Discount Value" : "Create Discount Type"
          )}
        </CustomButtonToggle>
        <StylesDialog
          open={this.state.open}
          onClose={this.close}
          aria-labelledby="formDialogCreateDiscount"
          dir={dir}
        >
          <StylesDialogTitle id="formDialogCreateDiscount" dir={dir}>
            <CustomTypographyWeb className="StylesDialogTitle__title">
              {t(
                isDiscountValue
                  ? "Create Discount Value"
                  : "Create Discount Type"
              )}
            </CustomTypographyWeb>
            <IconButton
              aria-label="close"
              onClick={this.close}
              className="StylesDialogTitle__btnClose"
            >
              <CloseRounded htmlColor="#334155" />
            </IconButton>
          </StylesDialogTitle>
          <Formik
            initialValues={{
              value: undefined,
            }}
            validationSchema={object({
              value: string()
                .required(
                  isDiscountValue
                    ? t("Please enter discount value")
                    : t("Please enter discount type")
                )
                .transform((value) => value.toLocaleLowerCase())
                .notOneOf(
                  existedOptionsInString,
                  isDiscountValue
                    ? t(
                        "Discount value already exists. Please enter another value."
                      )
                    : t(
                        "Discount type already exists. Please enter another type."
                      )
                ),
            })}
            onSubmit={(value) => {
              const payload = isDiscountValue
                ? {
                    discount_value: {
                      discount_value: value.value,
                    },
                  }
                : {
                    discount_type: {
                      discount_type: value.value,
                    },
                  };

              onCreate(payload);
              this.close();
            }}
          >
            {(props) => {
              return (
                <Form>
                  <StylesDialogContent>
                    <CustomFormInputFieldWeb
                      name="value"
                      fullWidth
                      label={t(
                        isDiscountValue ? "Discount Value" : "Discount Type"
                      )}
                      variant="outlined"
                      placeholder={t(
                        isDiscountValue
                          ? "Add here discount value"
                          : "Add here discount type"
                      )}
                      value={props.values.value}
                      error={props.touched.value && !!props.errors.value}
                    />
                  </StylesDialogContent>
                  <StylesDialogActions dir={dir}>
                    <CustomButton
                      onClick={this.close}
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="StylesDialogActions__btnCancel"
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t("Create")}
                    </CustomButton>
                  </StylesDialogActions>
                </Form>
              );
            }}
          </Formik>
        </StylesDialog>
      </>
    );
  }
}

export default withTranslation()(CustomPromocodesCreateDiscountDialog);
