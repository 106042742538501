import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";

export interface Props extends WithStyles, WithTranslation {
  status: string;
}

const styles = (theme: Theme) =>
  createStyles({
    name: {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "&.green": {
        color: "#48CD49",

        "&::before": {
          border: "2px solid #48CD4933",
          backgroundColor: "#48CD49",
        },
      },

      "&.red": {
        color: "#FF6666",

        "&::before": {
          border: "2px solid #FF666633",
          backgroundColor: "#FF6666",
        },
      },

      "&.light-green": {
        color: "#333333",

        "&::before": {
          border: "2px solid #F59E0B33",
          backgroundColor: "#333333",
        },
      },

      "&::before": {
        width: 8,
        height: 8,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        boxSizing: "border-box",
      },
    },
  });

export class AdminOrderStatusTag extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, status, t } = this.props;

    return (
      <Box
        className={clsx(classes.name, {
          ["green"]: ["Approved", "Order is Preparing", "Completed"].includes(
            status
          ),
          ["red"]: status === "Declined",
          ["light-green"]: status === "Not Started",
        })}
      >
        {t(status)}
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(AdminOrderStatusTag));
