import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

type CustomAnalyticsAdminCustomersConversionChartProps = {
  menuView: { value: number }[];
  addToCart: { value: number }[];
  placeOrder: { value: number }[];
};

function CustomAnalyticsAdminCustomersConversionChart({
  menuView,
  addToCart,
  placeOrder,
}: CustomAnalyticsAdminCustomersConversionChartProps) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  const classes = useStyles();

  const domain = [0, 500];

  return (
    <Box dir={dir}>
      <h3 className={classes.title}>{t("Customer Conversion")}</h3>

      <Box
        display={"flex"}
        minHeight={120}
        height={10}
        border={"0.5px solid #B1B1B1"}
        borderLeft={"none"}
        borderRight={"none"}
        alignItems={"flex-end"}
        dir={dir}
        marginTop={"74px"}
      >
        <ResponsiveContainer maxHeight={80}>
          <AreaChart width={270} height={80} data={menuView}>
            <Area
              type="monotone"
              dataKey="value"
              stroke="none"
              fill="#ff6666"
            />
            <YAxis domain={domain} hide />
            <XAxis hide reversed={rtl} />
          </AreaChart>
        </ResponsiveContainer>

        <ResponsiveContainer maxHeight={80}>
          <AreaChart width={270} height={80} data={addToCart}>
            <Area
              type="monotone"
              dataKey="value"
              stroke="none"
              fill="#FF9999"
            />
            <YAxis domain={domain} hide />
            <XAxis hide reversed={rtl} />
          </AreaChart>
        </ResponsiveContainer>

        <ResponsiveContainer maxHeight={80}>
          <AreaChart width={270} height={80} data={placeOrder}>
            <Area
              type="monotone"
              dataKey="value"
              stroke="none"
              fill="#FFE0E0"
            />
            <YAxis domain={domain} hide />
            <XAxis hide reversed={rtl} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>

      <Box display="flex" marginTop={"32px"}>
        <h4 className={classes.chartTitle}>
          {t("Viewed Your Menu")} <span>100&rlm;%</span>
        </h4>
        <h4 className={classes.chartTitle}>
          {t("Added items to cart")} <span>17.94&rlm;%</span>
        </h4>
        <h4 className={classes.chartTitle}>
          {t("Placed An Order")} <span>10.25&rlm;%</span>
        </h4>
      </Box>
    </Box>
  );
}

export default CustomAnalyticsAdminCustomersConversionChart;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 700,
      lineHeight: 30 / 36.31,
      color: "#2E2E30",
      textAlign: "start",
      marginBlock: 0,
    },
    chartTitle: {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: 14 / 16.94,
      textAlign: "start",
      color: "#767676",
      flex: "1 1 270px",
      paddingInlineStart: 4,
      marginBlock: 0,
      "& span": {
        fontSize: theme.typography.pxToRem(10),
        fontWeight: 400,
        lineHeight: 10 / 12.1,
        display: "block",
        marginBlockStart: 4,
      },
    },
  })
);
