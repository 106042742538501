import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import {
  deFlag,
  ukFlag,
  promo,
  time,
  adminDashboard,
  adminReport,
  adminOrders,
  adminReview,
  adminAdvert,
  adminCompensations,
  adminUsers,
  adminNotifications,
  adminFinance,
  adminBranch,
} from "./assets";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import DashboardAdmin from "../../../blocks/dashboard/src/DashboardAdmin.web";
import DashboardAdminReports from "../../../blocks/analytics/src/AnalyticsAdmin.web";
import DashboardAdminProfile from "../../../blocks/customisableuserprofiles/src/UserProfile.web";
import DashboardAdminNotificationsAll from "../../../blocks/notifications/src/NotificationsAll.web";
import { matchPath } from "react-router-dom";
import AdminOrderManagement from "../../../blocks/ordermanagement/src/AdminOrderManagement.web";
import i18n from "i18next";
import { WithTranslation } from "react-i18next";
import AdminAccountManagement from "../../../blocks/social-media-account/src/AdminAccountManagement.web";
import AdminPromocodesPromotions from "../../../blocks/promocodes/src/AdminPromocodesPromotions.web";
import AdminLoyaltySystemCompensations from "../../../blocks/loyaltysystem/src/AdminLoyaltySystemCompensations.web";
import AdminOpeningTimes from "../../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2OpeningTimesAdmin.web";
import AdminPaymentAdminFinance from "../../../blocks/paymentadmin2/src/AdminPaymentAdminFinance.web";
import ReviewsRatingAndReviewsAdmin from "../../../blocks/reviews/src/ReviewsRatingAndReviewsAdmin.web";
import AdminLocationBranchStatus from "../../../blocks/location/src/AdminLocationBranchStatus.web";

type UserProfile = {
  id: string;
  type: string;
  attributes: {
    email: string;
    user_name: string | null;
    bio: string | null;
    add_location: string | null;
    twitter_handle: string | null;
    facebook_profile: string | null;
    linkedin_profile: string | null;
    image_url: string | null;
  };
};

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  adminLogoutDialog: boolean;
  search: string;
  currentAdminPage: {
    name: string;
    to: string;
  };
  mobileOpen: boolean;
  isClosing: boolean;
  selectedLanguage: string;
  languages: {
    name: string;
    value: string;
    flag: string;
  }[];
  userProfile: UserProfile | null;
  dashboardFilterData: {
    name: string;
    value: string | number;
  }[];
  dashboardselectedFilter: string | number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  hideNamePages = ["Dashboard", "Notifications All"];

  performanceMonitoringRoutes = [
    {
      to: "/kitchen/admin/dashboard",
      name: "Dashboard",
      component: DashboardAdmin,
      icon: adminDashboard,
      exact: true,
    },

    {
      to: "/kitchen/admin/reports",
      name: "Reports",
      component: DashboardAdminReports,
      icon: adminReport,
      exact: true,
    },

    {
      to: "/kitchen/admin/orders",
      name: "Orders",
      icon: adminOrders,
      exact: true,
      component: AdminOrderManagement,
    },

    {
      to: "/kitchen/admin/ratings-and-reviews",
      name: "Ratings and Review",
      icon: adminReview,
      exact: true,
      component: ReviewsRatingAndReviewsAdmin,
    },
  ];

  growYourBusinessRoutes = [
    {
      to: "/kitchen/admin/advertising",
      name: "Advertising",
      icon: adminAdvert,
      exact: true,
    },

    {
      to: "/kitchen/admin/promotions",
      name: "Promotions",
      icon: promo,
      exact: true,
      component: AdminPromocodesPromotions,
    },
  ];

  manageYourBusinessRoutes = [
    {
      to: "/kitchen/admin/compensations",
      name: "Compensation",
      icon: adminCompensations,
      exact: true,
      component: AdminLoyaltySystemCompensations,
    },

    {
      to: "/kitchen/admin/user-management",
      name: "User Management",
      icon: adminUsers,
      exact: true,
      component: AdminAccountManagement,
    },

    {
      to: "/kitchen/admin/notifications",
      name: "Notification Center",
      icon: adminNotifications,
      component: DashboardAdminNotificationsAll,
      exact: true,
    },

    {
      to: "/kitchen/admin/opening-times",
      name: "Opening Times",
      icon: time,
      component: AdminOpeningTimes,
      exact: true,
    },

    {
      to: "/kitchen/admin/finance",
      name: "Finance",
      icon: adminFinance,
      component: AdminPaymentAdminFinance,
      exact: true,
    },

    {
      to: "/kitchen/admin/branch-status",
      name: "Branch Status",
      icon: adminBranch,
      exact: true,
      component: AdminLocationBranchStatus,
    },
  ];

  userRoutes = [
    {
      to: "/kitchen/admin/profile",
      name: "Profile",
      icon: adminBranch,
      component: DashboardAdminProfile,
      exact: true,
    },
    {
      to: "/kitchen/admin/notifications-all",
      name: "Notifications All",
      icon: adminBranch,
      component: DashboardAdminNotificationsAll,
      exact: true,
    },
  ];

  token: string = "";
  accountType: string = "";

  allRoutes = [
    ...this.performanceMonitoringRoutes,
    ...this.growYourBusinessRoutes,
    ...this.manageYourBusinessRoutes,
    ...this.userRoutes,
  ];

  inputRef: React.RefObject<HTMLInputElement>;

  apiGetSuperadminProfileCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      adminLogoutDialog: false,
      search: "",
      currentAdminPage: {
        name: "",
        to: "",
      },
      mobileOpen: false,
      isClosing: false,
      selectedLanguage: "DE",
      languages: [
        { name: "UK", value: "en", flag: ukFlag },
        { name: "DE", value: "ar", flag: deFlag },
      ],
      userProfile: null,
      dashboardFilterData: [
        {
          name: "Total Restaurants",
          value: "totalRestaurants",
        },

        {
          name: "Active Users",
          value: "activeUsers",
        },

        {
          name: "Total Orders",
          value: "totalOrders",
        },

        {
          name: "Total Sales",
          value: "totalSales",
        },

        {
          name: "Total Cancellation",
          value: "totalCancellation",
        },

        {
          name: "Active Admins",
          value: "activeAdmins",
        },

        {
          name: "Registered Restaurants",
          value: "registeredRestaurants",
        },

        {
          name: "All",
          value: "all",
        },
      ],
      dashboardselectedFilter: "all",
    };

    this.inputRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.UserUpdateProfileMessage),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    await this.getSuperAdminProfile();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillMount() {
    const selectedLanguage = await StorageProvider.get("adminLanguage");

    if (!selectedLanguage) {
      await StorageProvider.set("adminLanguage", "en");
      i18n.changeLanguage("en");

      return;
    }

    i18n.changeLanguage(selectedLanguage);

    this.setState({
      selectedLanguage,
    });
  }

  initAdminPage = async () => {
    this.token = await StorageProvider.get("token");
    this.accountType = await StorageProvider.get("account_type");

    if (!this.token || this.accountType !== "SuperAdminAccount") {
      this.handleLogout();

      return;
    }

    if (this.state.currentAdminPage.to || this.state.currentAdminPage.name) {
      return;
    }

    const pathname = window.location.pathname;

    const currentAdminPage = this.allRoutes.find((route) =>
      matchPath(pathname, {
        path: route.to,
        exact: true,
        strict: false,
      })
    );

    if (!currentAdminPage) {
      return;
    }

    this.setCurrentAdminPage({
      to: currentAdminPage.to,
      name: currentAdminPage.name,
    });
  };

  openAdminLogoutDialog = () => {
    this.setState({
      adminLogoutDialog: true,
    });
  };

  closeAdminLogoutDialog = () => {
    this.setState({
      adminLogoutDialog: false,
    });
  };

  handleLogout = async () => {
    await StorageProvider.remove("token");
    await StorageProvider.remove("refresh_token");
    await StorageProvider.remove("account_id");
    await StorageProvider.remove("account_type");

    this.closeAdminLogoutDialog();

    this.props.navigation.navigate("EmailAccountLoginKitchen");
  };

  setIsClosing = (isClosing: boolean) => {
    this.setState({
      isClosing,
    });
  };

  handleDrawerClose = () => {
    this.setIsClosing(true);
    this.setMobileOpen(false);
  };

  handleDrawerTransitionEnd = () => {
    this.setIsClosing(false);
  };

  changeLanguage = (event: any) => {
    this.setState(
      {
        selectedLanguage: event.target.value,
      },
      async () => {
        i18n.changeLanguage(this.state.selectedLanguage);
        await StorageProvider.set("adminLanguage", this.state.selectedLanguage);
      }
    );
  };

  changeDashboardFilter = (event: any) => {
    this.setState({
      dashboardselectedFilter: event.target.value,
    });
  };

  setMobileOpen = (mobileOpen: boolean) => {
    this.setState({
      mobileOpen,
    });
  };

  handleDrawerToggle = () => {
    if (!this.state.isClosing) {
      this.setMobileOpen(!this.state.mobileOpen);
    }
  };

  setCurrentAdminPage = (currentAdminPage: { name: string; to: string }) => {
    this.setState({ currentAdminPage });
  };

  clearSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }
  };

  search = (e: any) => {
    this.setState({
      search: e.target.value,
    });
  };

  getSuperAdminProfile = async () => {
    const accountId = await StorageProvider.get("account_id");
    const token = await StorageProvider.get("token");

    const endPoint =
      "account_block/accounts/" + accountId + "/show_super_admin";
    const requestHeader = {
      "Content-Type": "application/json",
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetSuperadminProfileCallId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "get");

    runEngine.sendMessage(message.id, message);
  };

  handleGetSuperAdminProfileResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        userProfile: responseJson.data,
      });
    }
  };

  getAvatarName = () => {
    const name = this.state.userProfile?.attributes.user_name;

    if (!name) return "-";

    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join("");
  };

  checkIfNotificationPageActive = () => {
    return this.state.currentAdminPage.name === "Notifications All";
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson?.errors?.[0]?.token) {
        this.handleLogout();
      }

      if (this.apiGetSuperadminProfileCallId === apiRequestCallId) {
        this.handleGetSuperAdminProfileResponse(responseJson);
      }
    }

    if (getName(MessageEnum.UserUpdateProfileMessage) === message.id) {
      this.getSuperAdminProfile();
    }
    // Customizable Area End
  }
}
