// Customizable Area Start
import React, { ChangeEvent, KeyboardEvent } from "react";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { WithTranslation } from "react-i18next";

export const configJSON = require("./config");

export interface Props extends WithTranslation {
  navigation: any;
  id: string;
}

type Review = {
  reviewId: string;
  restaurantName: string;
  branch: string;
  dateReviewed: string;
  userRating: number;
  review: string;
};

interface S {
  reviews: Review[];

  dialogData: Review | null;

  loading: boolean;

  reviewIdSearch: string;

  restaurantFilter: string;

  restaurantFilterOptions: {
    id: string;
    name: string;
    value: string;
  }[];

  timeFilter: number;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {}

export default class ReviewsRatingAndReviewsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  timeOptions = [
    {
      id: "1",
      name: "Last 7 days",
      value: 7,
    },
    {
      id: "2",
      name: "Current day",
      value: 1,
    },
  ];

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      reviews: [],

      dialogData: null,

      loading: false,

      reviewIdSearch: "",

      restaurantFilterOptions: [
        { id: "0", name: "Restaurants", value: "0" },
        { id: "1", name: "Branch", value: "1" },
        { id: "2", name: "Rating", value: "2" },
        { id: "3", name: "Reviews", value: "3" },
      ],

      restaurantFilter: "",

      timeFilter: 7,

      pagination: {
        page: 1,
        limit: 12,
        totalPages: 1,
        totalCount: 1,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };

    this.inputRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.fetchReviews();
  }

  fetchReviews = () => {
    this.setState({
      reviews: [
        {
          userRating: 4.5,
          reviewId: "789",
          restaurantName: "Sushi Express",
          branch: "Buraydah",
          dateReviewed: "2022-04-15",
          review: "Amazing sushi! Will definitely come back.",
        },
        {
          restaurantName: "Sushi Express",
          branch: "Buraydah",
          reviewId: "789",
          dateReviewed: "2022-04-15",
          userRating: 4.5,
          review: "Amazing sushi! Will definitely come back.",
        },
        {
          restaurantName: "Burger Palace",
          branch: "Mecca",
          reviewId: "123",
          dateReviewed: "2022-04-15",
          review: "Great food and friendly service!",
          userRating: 4.1,
        },
        {
          restaurantName: "Pizza Hut",
          dateReviewed: "2022-04-15",
          branch: "Riyadh",
          reviewId: "456",
          userRating: 4.0,
          review: "Pizza was good but delivery took a while",
        },
        {
          branch: "Mecca",
          dateReviewed: "2022-04-15",
          reviewId: "123",
          restaurantName: "Burger Palace",
          review: "Great food and friendly service!",
          userRating: 4.1,
        },
        {
          dateReviewed: "2022-04-15",
          userRating: 4.0,
          review: "Pizza was good but delivery took a while",
          reviewId: "456",
          restaurantName: "Pizza Hut",
          branch: "Riyadh",
        },
        {
          restaurantName: "Burger Palace",
          userRating: 4.1,
          reviewId: "123",
          branch: "Mecca",
          dateReviewed: "2022-04-15",
          review: "Great food and friendly service!",
        },
        {
          restaurantName: "Taco Time",
          branch: "Tabuk",
          reviewId: "101",
          userRating: 5.0,
          review: "Tacos were delicious but portion size could be bigger.",
          dateReviewed: "2022-04-15",
        },
      ],
    });
  };

  changeTimeFilter = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    this.setState(
      {
        timeFilter: event.target.value as number,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchReviews();
      }
    );
  };

  changeRestaurantFilter = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    this.setState(
      {
        restaurantFilter: event.target.value as string,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchReviews();
      }
    );
  };

  handleSearch = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        reviewIdSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchReviews();
      }
    );
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchReviews();
      }
    );
  };

  clearSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        reviewIdSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchReviews();
      }
    );
  };

  downloadReport = () => {};

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  openDialog = (data: Review) => {
    this.setState({
      dialogData: data,
    });
  };

  closeDialog = () => {
    this.setState({
      dialogData: null,
    });
  };

  async receive(from: string, message: Message) {
    /* empty */
  }
}

// Customizable Area End
