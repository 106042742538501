import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

export enum KitchenOrderPaymentStatus {
  Cash = "cash",
  Online = "online",
  Refund = "refund",
}

export interface Props extends WithStyles, WithTranslation {
  status: KitchenOrderPaymentStatus;
}

const styles = (theme: Theme) =>
  createStyles({
    kitchenOrderPaymentStatusTag: {
      color: "#0F172A",
    },
  });

export class KitchenOrderPaymentStatusTag extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, status, t } = this.props;

    return (
      <>
        {(() => {
          switch (status) {
            case KitchenOrderPaymentStatus.Online:
              return (
                <Box className={classes.kitchenOrderPaymentStatusTag}>
                  {t("Online")}
                </Box>
              );

            case KitchenOrderPaymentStatus.Cash:
              return (
                <Box className={classes.kitchenOrderPaymentStatusTag}>
                  {t("Cash")}
                </Box>
              );

            case KitchenOrderPaymentStatus.Refund:
              return (
                <Box className={classes.kitchenOrderPaymentStatusTag}>
                  {t("Refund")}
                </Box>
              );

            default:
              return <></>;
          }
        })()}
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(KitchenOrderPaymentStatusTag)
);
