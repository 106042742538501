import React from "react";
// Customizable Area Start
import { Box, withStyles, createStyles } from "@material-ui/core";
import KitchenPrivacyPolicyController, {
  Props,
} from "./KitchenPrivacyPolicyController.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomNavigationMenuEmpty from "../../../components/src/CustomNavigationMenuEmpty.web";
import { ArrowBack } from "@material-ui/icons";
import { bg } from "./assets";

const styles = createStyles({
  content: {
    marginTop: 16,
  },

  banner: {
    width: "100%",
    height: "auto",
    marginBottom: 24,
  },

  text: {
    fontFamily: "Inter !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    whiteSpace: "pre-wrap",
  },

  backToRegister: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& svg": {
      marginRight: 8,
    },
  },

  buttons: {
    marginTop: 122,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 24,

    "& button": {
      width: 196,
    },
  },
});

// Customizable Area End

export class KitchenPrivacyPolicy extends KitchenPrivacyPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { loading, policyContent } = this.state;
    const { classes } = this.props;

    return (
      <CustomNavigationMenuEmpty
        title="Privacy Policy"
        ToolbarTop={
          <CustomTypography
            className={classes.backToRegister}
            onClick={this.backToRegister}
            data-test-id="back-to-register-btn"
          >
            <ArrowBack />
            Back to register
          </CustomTypography>
        }
        Content={
          <Box className={classes.container}>
            <CustomLoadingBackdrop open={loading} />

            <img src={bg} className={classes.banner} />

            <CustomTypography variant="h2">Privacy Policy</CustomTypography>

            <Box className={classes.content}>
              {policyContent.length > 0
                ? policyContent.map((data) => {
                    return (
                      <CustomTypography
                        key={data.id}
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                    );
                  })
                : null}
            </Box>

            <Box className={classes.buttons}>
              <CustomButton
                variant="contained"
                data-test-id="cancel-button"
                onClick={() => this.handleAgreePolicy(false)}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-test-id="agree-button"
                variant="contained"
                color="primary"
                onClick={() => this.handleAgreePolicy(true)}
              >
                Agree
              </CustomButton>
            </Box>
          </Box>
        }
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(KitchenPrivacyPolicy);
// Customizable Area End
