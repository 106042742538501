import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import CustomDialog from "./CustomDialog.web";
import CustomTable from "./CustomTable";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles, WithTranslation {
  dialog: AdminPaymentFinanceDetailFormDialogProps;

  onClose(): void;
  changeAdminFinanceDetailDialogMode(
    mode: AdminPaymentFinanceDetailFormDialogMode
  ): void;
}

export enum AdminPaymentFinanceDetailFormDialogMode {
  View = "view",
  Download = "download",
}

export interface AdminPaymentViewTable {
  date: string;
  orderNumber: string;
  orderDetails: string[];
  paymentMethod: string[];
  cash: number;
  points: number;
  total: number;
}

export interface AdminPaymentDownloadTable {
  startDate: string;
  endDate: string;
  duration: string;
  pricePaid: number;
  vat: number;
  total: number;
}

export interface AdminPaymentFinanceDetailFormDialogProps {
  mode: AdminPaymentFinanceDetailFormDialogMode;
  open: boolean;
  loading: boolean;
  viewData: AdminPaymentViewTable[];
  downloadData: AdminPaymentDownloadTable[];
  title: string;
}

const styles = (theme: Theme) =>
  createStyles({
    financeList: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 10,
    },

    financeTable: {
      "& td": {
        padding: 16,
        fontFamily: "Inter",
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    order: {
      paddingTop: 5,
      paddingBottom: 5,
      fontFamily: "Inter !important",
    },
  });

export class AdminPaymentFinanceDetailFormDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  statusOptions: {
    id: string;
    name: string;
    value: string | number;
  }[] = [];

  render(): React.ReactNode {
    const {
      classes,
      dialog,
      t,
      i18n,

      onClose
    } = this.props;

    const { mode, open, viewData, downloadData, title } = dialog;

    const downloadMode =
      mode === AdminPaymentFinanceDetailFormDialogMode.Download;

    const rtl = i18n.language === "ar";

    return (
      <CustomDialog
        dir={rtl ? "rtl" : ""}
        width={1000}
        open={open}
        title={title}
        onClose={onClose}
        content={
          <Box className={classes.financeList}>
            <TableContainer>
              {downloadMode ? (
                <CustomTable className={classes.financeTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Start Date")}</TableCell>
                      <TableCell>{t("End Date")}</TableCell>
                      <TableCell>{t("Duration")}</TableCell>
                      <TableCell>{t("Price Paid")}</TableCell>
                      <TableCell>{t("VAT ({{number}}%)", { number: 15 })}</TableCell>
                      <TableCell>{t("Total")}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {downloadData.length ? (
                      downloadData.map((data, index) => (
                        <TableRow
                          key={index}
                          data-test-id={`table-row-${index}`}
                        >
                          <TableCell>{data.startDate}</TableCell>

                          <TableCell>{data.endDate}</TableCell>

                          <TableCell>{data.duration}</TableCell>

                          <TableCell>
                            {t("{{number}} SAR", { number: data.pricePaid })}
                          </TableCell>

                          <TableCell>
                            {t("{{number}} SAR", { number: data.vat })}
                          </TableCell>

                          <TableCell>
                            {t("{{number}} SAR", { number: data.total })}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6}>
                          <Box style={{ textAlign: "center" }}>
                            {t("No data")}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </CustomTable>
              ) : (
                <CustomTable className={classes.financeTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Date")}</TableCell>
                      <TableCell>{t("Order Number")}</TableCell>
                      <TableCell>{t("Order Details")}</TableCell>
                      <TableCell>{t("Payment Method")}</TableCell>
                      <TableCell>{t("Cash")}</TableCell>
                      <TableCell>{t("Point")}</TableCell>
                      <TableCell>{t("Total")}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {viewData.length ? (
                      viewData.map((data, index) => (
                        <TableRow
                          key={index}
                          data-test-id={`table-row-${index}`}
                        >
                          <TableCell>{data.date}</TableCell>

                          <TableCell>{data.orderNumber}</TableCell>

                          <TableCell>
                            {data.orderDetails.length > 0
                              ? data.orderDetails.map((value, index) => {
                                  return (
                                    <CustomTypography
                                      key={index}
                                      className={classes.order}
                                    >
                                      {value}
                                    </CustomTypography>
                                  );
                                })
                              : ""}
                          </TableCell>

                          <TableCell>
                            {data.paymentMethod.length > 0
                              ? data.paymentMethod.map((value, index) => {
                                  return index === 0 ? value : " + " + value;
                                })
                              : ""}
                          </TableCell>

                          <TableCell>
                            {t("{{number}} SAR", { number: data.cash })}
                          </TableCell>

                          <TableCell>
                            {t("{{number}} SAR", { number: data.points })}
                          </TableCell>

                          <TableCell>
                            {t("{{number}} SAR", { number: data.total })}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <Box style={{ textAlign: "center" }}>
                            {t("No data")}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </CustomTable>
              )}
            </TableContainer>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminPaymentFinanceDetailFormDialog)
);
