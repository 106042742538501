import {
  IconButton,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import CustomTypography from "../CustomTypography.web";
import { CancelRounded } from "@material-ui/icons";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithStyles, WithTranslation {
  initImages?: { id: number; url: string }[];
  handleChange(images: ({ id: number; url: string } | File)[]): void;
}

interface State {
  images: ({ id: number; url: string } | File)[];
}

const styles = createStyles({
  container: {
    width: "100%",
    aspectRatio: 2,
    backgroundColor: "#FFFBF4",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
border-radius: 8px`,
    display: "grid",
    alignContent: "center",
    justifyItems: "center",
    position: "relative",
    "& input": {
      display: "block",
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      cursor: "pointer",
    },
  },
  caption: {
    fontSize: "0.875rem",
    lineHeight: 22 / 12,
    fontWeight: 400,
  },
  slider: {
    display: "flex",
    overflow: "auto",
    width: "calc(100% + 1.5rem)",
    paddingBlockStart: "1rem",
    paddingBlockEnd: "1.25rem",
    gap: "1rem",
    /* For Safari, Chrome*/
    "&::-webkit-scrollbar": {
      width: 70,
      height: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#ECECEC",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    /* For Firefox and offshoots */
    scrollbarColor: "#ECECEC transparent",

    "& .slider__image-preview": {
      position: "relative",
      "& img": {
        width: 70,
        height: 70,
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: 8,
      },
    },

    "& .slider__image-delete": {
      padding: 0,
      position: "absolute",
      top: 0,
      right: 0,
      transform: "translate(calc(50% - 2px), calc(-50% + 2px))",
      background: "#FFFFFF",
      "& .MuiSvgIcon-root": { fontSize: "1.375rem" },
    },
  },
});

class LocationBranchStatusImagesUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      images: this.props.initImages ?? [],
    };
  }

  handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newImages = Array.from(files);
      const updatedImages = [...this.state.images, ...newImages];
      this.setState({ images: updatedImages }, () => {
        this.props.handleChange(this.state.images);
      });
    }
  };

  handleImageRemove = (index: number) => {
    const { images } = this.state;
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    this.setState({ images: updatedImages }, () => {
      this.props.handleChange(this.state.images);
    });
  };

  render(): React.ReactNode {
    const { classes, t, i18n } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <div>
        <div className={classes.container}>
          <input
            type="file"
            multiple
            onChange={this.handleImageUpload}
            accept="image/png, image/jpeg"
            name="images"
          />
          <svg
            width="62"
            height="56"
            viewBox="0 0 62 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.0002 36.1921C30.2682 36.1921 29.6551 35.9721 29.1608 35.5321C28.6648 35.0936 28.4168 34.5497 28.4168 33.9004V17.515L23.5731 21.8118C23.0564 22.2702 22.4536 22.4993 21.7647 22.4993C21.0759 22.4993 20.4516 22.2511 19.8918 21.7546C19.3752 21.2962 19.128 20.7516 19.1504 20.1206C19.1711 19.4912 19.4182 18.9664 19.8918 18.5462L29.1918 10.2962C29.4502 10.0671 29.73 9.90435 30.0314 9.8081C30.3328 9.71338 30.6557 9.66602 31.0002 9.66602C31.3446 9.66602 31.6675 9.71338 31.9689 9.8081C32.2703 9.90435 32.5502 10.0671 32.8085 10.2962L42.1085 18.5462C42.6252 19.0046 42.8723 19.5484 42.8499 20.1779C42.8292 20.8089 42.5821 21.3344 42.1085 21.7546C41.5918 22.2129 40.9787 22.4512 40.2692 22.4696C39.5579 22.4894 38.9439 22.2702 38.4272 21.8118L33.5835 17.515V33.9004C33.5835 34.5497 33.3364 35.0936 32.8421 35.5321C32.3461 35.9721 31.7321 36.1921 31.0002 36.1921ZM15.5002 45.3587C14.0793 45.3587 12.8634 44.9103 11.8525 44.0135C10.8398 43.1152 10.3335 42.0358 10.3335 40.7754V36.1921C10.3335 35.5427 10.5806 34.9981 11.0749 34.5581C11.5709 34.1196 12.1849 33.9004 12.9168 33.9004C13.6488 33.9004 14.2627 34.1196 14.7587 34.5581C15.253 34.9981 15.5002 35.5427 15.5002 36.1921V40.7754H46.5002V36.1921C46.5002 35.5427 46.7482 34.9981 47.2442 34.5581C47.7384 34.1196 48.3516 33.9004 49.0835 33.9004C49.8154 33.9004 50.4286 34.1196 50.9228 34.5581C51.4188 34.9981 51.6668 35.5427 51.6668 36.1921V40.7754C51.6668 42.0358 51.1614 43.1152 50.1504 44.0135C49.1377 44.9103 47.921 45.3587 46.5002 45.3587H15.5002Z"
              fill="#333333"
            />
          </svg>
          <CustomTypography className={classes.caption} dir={dir}>
            {t("Only allow JPEG and PNG formats")}.
          </CustomTypography>
        </div>

        <div className={classes.slider}>
          {this.state.images.map((image, index) => (
            <div key={index} className="slider__image-preview">
              <img
                src={
                  image instanceof File ? URL.createObjectURL(image) : image.url
                }
                alt={`Preview ${index + 1}`}
              />
              <IconButton
                className="slider__image-delete"
                onClick={() => this.handleImageRemove(index)}
                type="button"
              >
                <CancelRounded htmlColor="#DC2626" />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(LocationBranchStatusImagesUpload)
);
