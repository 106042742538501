import {
  Box,
  CircularProgress,
  ListItemText,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import KitchenDrawer from "./KitchenDrawer.web";
import CustomFormCheckBox from "./CustomFormCheckBox.web";
import { CustomFilterMenu } from "./CustomFilter.web";
import { WithTranslation, withTranslation } from "react-i18next";

export interface Props extends WithStyles, WithTranslation {
  open: boolean;
  userRoles: CustomFilterMenu[];
  loading: boolean;

  onClose(): void;
  onSubmit(values: any): void;
}

const styles = () =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      gap: 16,

      "& [class*='CustomFormInputField-container']": {
        marginBlockStart: 0,
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export class KitchenAccountFormDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, userRoles, loading, t, i18n, onClose, onSubmit } =
      this.props;

    const validationSchema = Yup.object({
      user_name: Yup.string(),
      email: Yup.string()
        .email(t("Email Address is not valid"))
        .required(t("Email Address is not valid")),
      role_name: Yup.string().required(t("You must have to select user role")),
    });

    const rtl = i18n.language === "ar";

    return (
      <KitchenDrawer
        dir={rtl ? "rtl" : ""}
        anchor={rtl ? "left" : "right"}
        open={open}
        title={t("Add New User")}
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formAddUser"
            >
              {loading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                t("Send Invites")
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={validationSchema.default()}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            data-test-id="kitchen-item-formik"
          >
            {(props) => {
              return (
                <Form
                  data-test-id="kitchen-account-form"
                  id="formAddUser"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner}>
                    <CustomFormInputField
                      fullWidth
                      name="user_name"
                      label={t("Name")}
                      placeholder={t("Name")}
                      variant="outlined"
                      error={
                        props.touched.user_name && !!props.errors.user_name
                      }
                      data-test-id="name-input"
                    />
                    <CustomFormInputField
                      fullWidth
                      name="email"
                      label={t("Email address")}
                      placeholder={t("Email")}
                      variant="outlined"
                      error={props.touched.email && !!props.errors.email}
                      data-test-id="email-input"
                    />

                    <CustomFormSelectField
                      dir={rtl ? "rtl" : ""}
                      fullWidth
                      name="role_name"
                      label={t("Assign User Role")}
                      variant="outlined"
                      error={
                        props.touched.role_name && !!props.errors.role_name
                      }
                      data-test-id="role-select"
                      inputProps={{
                        placeholder: t("Select Role"),
                      }}
                    >
                      {userRoles
                        .filter((item) => item.value !== "")
                        .map((item) => (
                          <MenuItem key={item.id} value={item.value}>
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                    </CustomFormSelectField>

                    <CustomFormCheckBox
                      checked={true}
                      label={t("Invites team members to the platform")}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(KitchenAccountFormDrawer));
