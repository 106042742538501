import React from "react";

import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import CustomTable from "../../../components/src/CustomTable";
import { KitchenOrderAcceptStatusMenuOption } from "../../../components/src/KitchenOrderAcceptStatusMenu.web";
import KitchenOrderTableRowWeb, {
  KitchenOrder,
} from "../../../components/src/KitchenOrderTableRow.web";

type Props = {
  data: KitchenOrder[];
  orderStatusOptions: KitchenOrderAcceptStatusMenuOption[];
};

export default function CustomAnalyticsAdminSalesTable({
  data,
  orderStatusOptions,
}: Props) {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <CustomTable>
        <TableHead>
          <TableRow>
            <TableCell>{t("Status")}</TableCell>
            <TableCell>{t("Order ID")}</TableCell>
            <TableCell>{t("Payment status")}</TableCell>
            <TableCell>{t("Restaurant Name")}</TableCell>
            <TableCell>{t("Order Status")}</TableCell>
            <TableCell>{t("Order Type")}</TableCell>
            <TableCell>{t("Subtotal")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {data.length ? (
            data.map((order: any) => {
              return (
                <KitchenOrderTableRowWeb
                  data-test-id={`table-row-${order.id}`}
                  key={order.id}
                  order={order}
                  orderStatusOptions={orderStatusOptions}
                  onChangeAcceptStatus={() => {}}
                  openOrderSummaryDrawer={() => {}}
                  onAcceptOrder={() => {}}
                />
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <Box style={{ textAlign: "center" }}>
                  {t("No orders found")}
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </CustomTable>
    </TableContainer>
  );
}
