// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithStyles } from "@material-ui/core";
import React from "react";
import { WithTranslation } from "react-i18next";
import { AdminPromotionManagementStatus } from "../../../components/src/AdminPromotionManagementStatusTag.web";
import { AdminPromotionDetailFormDialogProps } from "../../../components/src/AdminPromotionDetailFormDialog.web";
import { AdminPromotionManagementAction } from "../../../components/src/AdminPromotionManagementMenu.web";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  adminPromotions: {
    id: string;
    promotionId: string;
    name: string;
    startDate: string;
    endDate: string;
    discountType: string;
    discount: string;
    status: AdminPromotionManagementStatus;
  }[];

  adminPromotionDetailDialog: AdminPromotionDetailFormDialogProps;

  loading: boolean;

  promotionIdSearch: string;

  statusFilter: string;

  statusFilterOptions: {
    id: string;
    name: string;
    value: string;
  }[];

  timeFilter: number;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {}

export default class AdminPromocodesPromotionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  inputRef: React.RefObject<HTMLInputElement>;

  timeOptions = [
    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },
    {
      id: uuidv4(),
      name: "Current day",
      value: 1,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      adminPromotions: [],

      adminPromotionDetailDialog: {
        mode: AdminPromotionManagementAction.View,
        open: false,
        loading: false,
        form: {
          id: "",
          promotionId: "",
          name: "",
          startDate: new Date(),
          endDate: new Date(),
          discountType: "",
          discount: "",
          status: "",
          amount: 0,
        },
        discountTypeOptions: [],
      },

      loading: false,

      promotionIdSearch: "",

      statusFilterOptions: [],

      statusFilter: "",

      timeFilter: 7,

      pagination: {
        page: 1,
        limit: 12,
        totalPages: 0,
        totalCount: 0,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };

    this.inputRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.fetchAdminPromotions();
  }

  fetchAdminPromotions = () => {
    this.setState({
      adminPromotions: [
        {
          id: "1",
          promotionId: "PROMO001",
          name: "Winter Sale",
          startDate: "2024-11-01",
          endDate: "2024-12-31",
          discountType: "Percentage",
          discount: "20%",
          status: AdminPromotionManagementStatus.Active,
        },
        {
          id: "3",
          promotionId: "PROMO003",
          name: "New Year Sale",
          startDate: "2025-01-01",
          endDate: "2025-01-15",
          discountType: "Percentage",
          discount: "15%",
          status: AdminPromotionManagementStatus.Pending,
        },
        {
          id: "4",
          promotionId: "PROMO004",
          name: "Flash Sale",
          startDate: "2024-10-30",
          endDate: "2024-11-02",
          discountType: "Percentage",
          discount: "25%",
          status: AdminPromotionManagementStatus.Expired,
        },
      ],

      pagination: {
        limit: 12,
        page: 1,
        totalCount: 3,
        totalPages: 1,
      },
    });
  };

  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminPromotions();
      }
    );
  };

  handleSearch = (event: any) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        promotionIdSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminPromotions();
      }
    );
  };

  clearPromotionIdSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        promotionIdSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminPromotions();
      }
    );
  };

  downloadReport = () => {};

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchAdminPromotions();
      }
    );
  };

  openAdminPromotionDetailDialog = (mode: AdminPromotionManagementAction) => {
    this.setState({
      adminPromotionDetailDialog: {
        ...this.state.adminPromotionDetailDialog,
        mode,
        open: true,
      },
    });
  };

  closeAdminPromotionDetailDialog = () => {
    this.setState({
      adminPromotionDetailDialog: {
        mode: AdminPromotionManagementAction.View,
        open: false,
        loading: false,
        form: {
          id: "",
          promotionId: "",
          name: "",
          startDate: new Date(),
          endDate: new Date(),
          discountType: "",
          discount: "",
          status: "",
          amount: 0,
        },
        discountTypeOptions: [],
      },
    });
  };

  changePromotionDetailDialogMode = (mode: AdminPromotionManagementAction) => {
    this.setState({
      adminPromotionDetailDialog: {
        ...this.state.adminPromotionDetailDialog,
        mode,
      },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  changeStatusFilter = (statusFilter: string) => {
    this.setState(
      {
        statusFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminPromotions();
      }
    );
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  async receive(from: string, message: Message) {
    /* empty */
  }
}

// Customizable Area End
