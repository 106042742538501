import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import { customLocaleMoment } from "../Utils.web";

type Props = {
  data: any[];
  dataKey?: string;
};

export default function CustomAnalyticsAdminSalesRevenueChart({
  data,
  dataKey = "revenue",
}: Props) {
  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";

  customLocaleMoment.locale(i18n.language);

  return (
    <ResponsiveContainer maxHeight={162}>
      <BarChart data={data} maxBarSize={55} reverseStackOrder={rtl}>
        <Bar dataKey={dataKey} fill="#FF6666" radius={5} />
        <XAxis
          dataKey="name"
          interval={0}
          tick={{
            fontFamily: "Inter",
            fontSize: 10,
            fontWeight: 700,
          }}
          tickLine={false}
          axisLine={false}
          tickMargin={14}
          tickFormatter={(value) => {
            const today = customLocaleMoment().format("dddd");

            let isToday = false;

            if (i18n.language === "ar") {
              isToday = today === t(value);
            } else {
              isToday = value === today;
            }

            const formattedValue = isToday ? t("Today") : t(value);

            if (rtl) {
              return formattedValue;
            }

            return isToday ? formattedValue : formattedValue[0];
          }}
          reversed={rtl}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
