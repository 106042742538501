// Customizable Area Start
import React from "react";

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";

import CustomTable from "../../../components/src/CustomTable";
import PromocodesPromotionsListController, {
  Props,
} from "./PromocodesPromotionsListController.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomSwitchWithText from "../../../components/src/CustomSwitchWithText.web";
import { promotionIcon } from "./assets";
import PromocodesPromotionsCreate from "./PromocodesPromotionsCreate.web";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },

    content: {
      marginBlockStart: 24,
    },

    table: {
      "& tbody tr th:first-child, & tbody tr td:first-child": {
        maxWidth: 276,
      },
      "& tbody tr th:nth-child(3), & tbody tr td:nth-child(3)": {
        minWidth: 160,
      },
      "& td": {
        padding: "0.75rem",
      },
      "& .table__cell__title": {
        fontWeight: 600,
        lineHeight: 22 / 14,
      },
      "& .table__cell__date": {
        whiteSpace: "pre",
        fontFamily: "Inter",
      },
      "& .table__cell__status": {
        textTransform: "capitalize",
        color: "#FFFFFF",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        width: 97,
        height: 30,
        "&.approve": {
          backgroundColor: "#059669",
        },
        "&.reject": {
          backgroundColor: "#DC2626",
        },
      },
      "& .table__cell_empty": {
        textAlign: "center",
        lineHeight: 4,

        [theme.breakpoints.down("md")]: {
          textAlign: "left",
        },
      },
    },

    pagination: {
      display: "flex",
      justifyContent: "end",
      marginTop: 24,
    },
  });

export class PromocodesPromotionsList extends PromocodesPromotionsListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;
    const {
      loading,
      pagination,
      promotions,
      snackbar,
      isAddPromotion,
      categories,
      discountTypes,
      discountValues,
      loadingCategories,
      loadingDiscountTypes,
      loadingDiscountValues,
      loadingCreate,
    } = this.state;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    if (isAddPromotion) {
      const isLoading =
        loadingCategories && loadingDiscountTypes && loadingDiscountValues;

      return isLoading ? (
        <CustomLoadingBackdrop open={true} />
      ) : (
        <>
          <PromocodesPromotionsCreate
            loading={loadingCreate}
            optionCategories={categories}
            optionDiscountTypes={discountTypes}
            optionDiscountValues={discountValues}
            handleCancel={this.backToListPromotionScreen}
            closeSnackbar={this.closeSnackbar}
            handleSubmit={this.handleSubmit}
            handleCreateDiscount={this.createDiscount}
          />
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
            dir={dir}
          />
        </>
      );
    }

    return (
      <>
        <CustomLoadingBackdrop open={loading} />
        <div className={classes.actions} dir={dir}>
          <CustomButton
            variant="contained"
            color="primary"
            startIcon={<img src={promotionIcon} />}
            onClick={this.goToAddPromotionScreen}
          >
            {t("Create Promotions")}
          </CustomButton>
        </div>

        <div className={classes.content}>
          <TableContainer>
            <CustomTable className={classes.table} dir={dir}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Promotions")}</TableCell>
                  <TableCell>{t("Promotions Status")}</TableCell>
                  <TableCell>{t("Running Status")}</TableCell>
                  <TableCell>{t("Start Date")}</TableCell>
                  <TableCell>{t("End Date")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {promotions.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} className="table__cell_empty">
                      {t("No promotions")}
                    </TableCell>
                  </TableRow>
                )}
                {promotions.map((promotion) => (
                  <TableRow key={promotion.id}>
                    <TableCell>
                      <span className="table__cell__title">
                        {promotion.name}
                      </span>
                      <br /> {promotion.description}
                    </TableCell>
                    <TableCell>
                      <strong
                        className={clsx(
                          "table__cell__status",
                          promotion.promotions_status ? "approve" : "reject"
                        )}
                      >
                        {promotion.promotions_status
                          ? t("Approved")
                          : t("Reject")}
                      </strong>
                    </TableCell>
                    <TableCell>
                      <CustomSwitchWithText
                        checkedText={t("Active")}
                        uncheckedText={t("Inactive")}
                        checked={promotion.running_status}
                        onChange={this.handleToggleSwitch(promotion.id)}
                      />
                    </TableCell>
                    <TableCell className="table__cell__date">
                      {this.formatDate(promotion.from)}
                    </TableCell>
                    <TableCell className="table__cell__date">
                      {this.formatDate(promotion.to)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <div className={classes.pagination} dir={dir}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </div>
        </div>

        <CustomSnackbar
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.open}
          type={snackbar.type}
          message={snackbar.message}
          onClose={this.closeSnackbar}
          dir={dir}
        />
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(PromocodesPromotionsList));
// Customizable Area End
