import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "../CustomButton.web";
import CustomFormInputField from "../CustomFormInputField.web";
import KitchenDrawer from "../KitchenDrawer.web";
import CustomReviewsContent from "./CustomReviewsContent";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";

const validationSchema = {
  fn: (t: TFunction<"translate", undefined>) =>
    Yup.object({
      reply: Yup.string()
        .required(t("Please enter reply."))
        .transform((reply) => reply.trim()),
    }),
};

interface Props extends WithStyles<any>, WithTranslation {
  open: boolean;
  userReply: { title: string; description: string; rating: number } | null;
  loading: boolean;
  onClose(): void;
  onSubmit(values: { reply: string }): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      paddingBlockStart: "2.5rem",

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export class CustomReviewsFormDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, userReply, loading, onClose, onSubmit, t, i18n } =
      this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <KitchenDrawer
        open={open}
        title={t("Reply Rating")}
        onClose={onClose}
        dir={dir}
        anchor={rtl ? "left" : "right"}
        bottom={
          <Box className={classes.formBottom} dir={dir}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formReplyReview"
            >
              {loading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                t("Reply")
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={validationSchema.fn(t).default()}
            validationSchema={validationSchema.fn(t)}
            onSubmit={onSubmit}
            data-test-id="reviews-item-formik"
          >
            {(props) => {
              return (
                <Form
                  id="formReplyReview"
                  data-test-id="reviews-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                  dir={dir}
                >
                  <div className={classes.formInner}>
                    <CustomReviewsContent
                      title={userReply?.title ?? ""}
                      description={userReply?.description ?? ""}
                      rating={userReply?.rating ?? 0}
                    />
                    <CustomFormInputField
                      fullWidth
                      name="reply"
                      multiline
                      maxRows={3}
                      minRows={3}
                      label={t("Reply to user")}
                      placeholder=""
                      variant="outlined"
                      error={props.touched.reply && !!props.errors.reply}
                      data-test-id="name-input"
                      dir={dir}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomReviewsFormDrawer));
