import {
  Box,
  Breadcrumbs,
  FormControlLabel,
  RadioGroup,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography.web";
import { Form, Formik, FormikProps } from "formik";
import CustomFormInputFieldWeb from "../CustomFormInputField.web";
import { InferType, date, object, string } from "yup";
import CustomRadio from "../CustomRadio.web";
import CustomDatePickerWeb from "../CustomDatePicker.web";
import CustomButton from "../CustomButton.web";
import { ChevronRightRounded, DateRangeRounded } from "@material-ui/icons";
import { toRem } from "../Utils.web";
import { MonthlyBudget } from "../../../blocks/promocodes/src/PromocodesAdvertisingController.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";

const validationSchema = {
  fn: (t: TFunction<"translation", undefined>) =>
    object({
      name: string().required(t("Please enter name")),
      description: string().required(t("Please enter description")),
      budget: string().default("2"),
      start: date().required(t("Please enter start date")),
      end: date().required(t("Please enter end date")),
    }),
};

type ValidationSchema = InferType<ReturnType<typeof validationSchema.fn>>;

interface Props extends WithStyles, WithTranslation {
  monthlyBudget: MonthlyBudget[];
  monthlyBudgetMap: Map<string, number>;
  onCancel(): void;
  onSubmit(value: ValidationSchema): void;
}

const styles = createStyles({
  breadcrumbs: {
    "& .MuiButtonBase-root": {
      padding: 0,
    },
    "& .MuiButton-label": {
      fontSize: "0.875rem",
      fontFamily: "Raleway",
      lineHeight: 22 / 14,
      fontWeight: 400,
    },
  },
  form: {
    marginBlockStart: 24,
    maxWidth: 650,
    "& .form__title, & .form__description": {
      fontSize: toRem(16),
      lineHeight: 24 / 16,
    },
    "& .form__title": {
      fontWeight: 700,
      marginBlockStart: 15,
    },
    "& [class*='CustomFormInputField-container-']": {
      marginBlockStart: 15,
    },
    "& .form__radioGroup": {
      marginBlockStart: 17,
      flexDirection: "row",
      alignItems: "flex-start",
      "& .MuiFormControlLabel-root": {
        position: "relative",
        paddingBottom: toRem(12),
      },
      "& .rcm": {
        fontSize: toRem(12),
        color: "#FF6666",
        fontWeight: 600,
        fontFamily: "Inter",
        lineHeight: 2,
        position: "absolute",
        left: 9,
        bottom: 0,
      },
      "& .formCreateCampaign__radio__text": {
        fontSize: toRem(14),
        lineHeight: 24 / 14,
        fontFamily: "Inter",
        fontWeight: 600,
      },
    },
    "& .form__budgetNote": {
      borderRadius: 8,
      backgroundColor: "#FFB946",
      padding: 10,
      "& > p + p": {
        marginBlockStart: 10,
      },
    },
    "& .form__dateInput": {
      display: "flex",
      alignItems: "flex-start",
      gap: "1rem",
      "& input::placeholder": {
        color: "#333",
        transition: "none",
        opacity: 1,
      },
    },

    "& .form__actions": {
      display: "flex",
      marginBlockStart: 15,
      gap: 12,
      alignItems: "center",
      "& .form__actions__cancel": {
        backgroundColor: "#333333",
      },
    },
  },
});

class CustomPromocodesAdvertisingCreate extends React.Component<Props> {
  formikRef: FormikProps<ValidationSchema> | null = null;

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.t !== this.props.t) {
      const touched = this.formikRef?.touched;

      if (touched) {
        this.formikRef?.setTouched(touched);
      }
    }
  }

  render(): React.ReactNode {
    const {
      classes,
      onCancel,
      onSubmit,
      monthlyBudget,
      monthlyBudgetMap,
      t,
      i18n,
    } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const defaultBudgetValue = monthlyBudget.filter(
      (budget) => budget.attributes.recommended
    )[0].id;

    const initialValues = {
      ...validationSchema.fn(t).default(),
      budget: defaultBudgetValue,
    };

    return (
      <>
        <Breadcrumbs
          className={classes.breadcrumbs}
          separator={<ChevronRightRounded />}
          dir={dir}
        >
          <CustomButton variant="text" color="primary" onClick={onCancel}>
            {t("Advertising")}
          </CustomButton>
          <CustomButton variant="text" onClick={() => {}}>
            {t("Create Campaign")}
          </CustomButton>
        </Breadcrumbs>

        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          validationSchema={validationSchema.fn(t)}
          innerRef={(instance) => {
            this.formikRef = instance;
          }}
        >
          {(props) => {
            return (
              <Box
                display={"flex"}
                alignItems={"flex-start"}
                justifyContent={"flex-start"}
                dir={dir}
              >
                <Form className={classes.form} dir={dir}>
                  <CustomTypography className="form__title">
                    {t("Create Campaign")}
                  </CustomTypography>
                  <CustomFormInputFieldWeb
                    name="name"
                    variant="outlined"
                    fullWidth
                    value={props.values.name}
                    error={props.touched.name && !!props.errors.name}
                    dir={dir}
                  />
                  <CustomFormInputFieldWeb
                    name="description"
                    variant="outlined"
                    multiline
                    maxRows={3}
                    minRows={3}
                    fullWidth
                    value={props.values.description}
                    error={
                      props.touched.description && !!props.errors.description
                    }
                    dir={dir}
                  />

                  <CustomTypography className="form__title">
                    {t("Campaign Monthly Budget")}
                  </CustomTypography>
                  <CustomTypography className="form__description">
                    {t("Auto-refreshes on the 1st of each month")}
                  </CustomTypography>

                  <RadioGroup
                    name="budget"
                    className="form__radioGroup"
                    value={props.values.budget}
                    onChange={(event, value) => {
                      props.setFieldValue("budget", value);
                    }}
                    dir={dir}
                  >
                    {monthlyBudget.map((budget) => {
                      return (
                        <FormControlLabel
                          key={budget.id}
                          value={budget.id}
                          checked={budget.id === props.values.budget}
                          control={<CustomRadio />}
                          label={
                            <span className="formCreateCampaign__radio__text">
                              {budget.attributes.budget_value} {t("SAR")}{" "}
                              {budget.attributes.recommended && (
                                <span className="rcm">{t("Recommended")}</span>
                              )}
                            </span>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                  <div className="form__budgetNote" dir={dir}>
                    <CustomTypography>
                      <strong>{t("Estimated Performance")}</strong>
                    </CustomTypography>
                    <CustomTypography>
                      {t("Up to")}{" "}
                      <strong>
                        <span style={{ fontFamily: "Inter" }}>
                          {monthlyBudgetMap.get(props.values.budget)}
                        </span>{" "}
                        {t("menu Views (Clicks)")}
                      </strong>{" "}
                      {t("per month")}
                    </CustomTypography>
                  </div>

                  <div>
                    <CustomTypography className="form__title">
                      {t("Schedule Your Campaign")}
                    </CustomTypography>
                    <CustomTypography className="form__description">
                      {t("Strategic Timing: Scheduling Your Campaign")}
                    </CustomTypography>

                    <div id="portalIdStarts" />
                    <div id="portalIdEnds" />

                    <div className="form__dateInput">
                      <CustomDatePickerWeb
                        onChange={(date) => {
                          props.setFieldValue("start", date);
                        }}
                        name="start"
                        portalId="portalIdStarts"
                        selected={
                          props.values.start
                            ? new Date(props.values.start)
                            : null
                        }
                        dateFormat={"dd/MM/yyyy"}
                        minDate={new Date()}
                        customInput={
                          <CustomFormInputFieldWeb
                            variant="outlined"
                            label={t("Start Date")}
                            fullWidth
                            error={props.touched.start && !!props.errors.start}
                            InputProps={{
                              placeholder: t("Start Date"),
                              endAdornment: <DateRangeRounded />,
                            }}
                            dir={dir}
                          />
                        }
                        locale={i18n.language}
                      />
                      <CustomDatePickerWeb
                        onChange={(date) => {
                          props.setFieldValue("end", date);
                        }}
                        name="end"
                        portalId="portalIdEnds"
                        selected={
                          props.values.end ? new Date(props.values.end) : null
                        }
                        dateFormat={"dd/MM/yyyy"}
                        minDate={props.values.start}
                        customInput={
                          <CustomFormInputFieldWeb
                            label={t("End Date")}
                            variant="outlined"
                            error={props.touched.end && !!props.errors.end}
                            fullWidth
                            InputProps={{
                              placeholder: t("End Date"),
                              endAdornment: <DateRangeRounded />,
                            }}
                            dir={dir}
                          />
                        }
                        locale={i18n.language}
                      />
                    </div>
                  </div>

                  <div className="form__actions" dir={dir}>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      type="button"
                      className="form__actions__cancel"
                      onClick={onCancel}
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {t("Submit")}
                    </CustomButton>
                  </div>
                </Form>
              </Box>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(CustomPromocodesAdvertisingCreate)
);
