import {
  Box,
  Drawer,
  DrawerProps,
  IconButton,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { ReactNode } from "react";
import CustomTypography from "./CustomTypography.web";
import { Close } from "@material-ui/icons";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";

export interface Props extends WithStyles, DrawerProps {
  classes: ClassNameMap<string>;
  title: string;
  open: boolean;
  width?: number;
  body: ReactNode;
  bottom: ReactNode;

  onClose(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& *": {
        boxSizing: "border-box",
      },

      "&.rtl": {
        direction: "rtl",
      },
    },

    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #E2E8F0",
      padding: 24,
    },

    body: {
      padding: 24,
      flex: 1,
      overflow: "auto",
    },

    bottom: {
      borderTop: "1px solid #E2E8F0",
      padding: 24,
    },
  });

export class KitchenDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, body, bottom, open, title, width, anchor, dir, onClose } =
      this.props;

    const rtl = dir === "rtl";

    return (
      <Drawer anchor={anchor} open={open} onClose={onClose}>
        <Box
          className={clsx(classes.container, {
            ["rtl"]: rtl,
          })}
          style={{ width: width || 378 }}
        >
          <Box className={classes.head}>
            <CustomTypography variant="h2">{title}</CustomTypography>

            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>

          <Box className={classes.body}>{body}</Box>

          <Box className={classes.bottom}>{bottom}</Box>
        </Box>
      </Drawer>
    );
  }
}

export default withStyles(styles)(KitchenDrawer);
