import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";

export interface Props extends WithStyles, WithTranslation {
  status: string;
}

const styles = (theme: Theme) =>
  createStyles({
    kitchenOrderStatusTag: {
      fontFamily: "Raleway",
      fontSize: 14,
      fontWeight: 700,
      lineHeight: "24px",
      padding: "3px 10px",
      borderRadius: 8,
      color: "#FFFFFF",
      textAlign: "center",
      maxWidth: 125,

      "&.green": {
        backgroundColor: "#059669",
      },

      "&.red": {
        backgroundColor: "#DC2626",
      },

      "&.light-green": {
        backgroundColor: "#D1FAE5",
        color: "#333333",
      },
    },
  });

export class KitchenOrderStatusTag extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, status, t } = this.props;

    return (
      <>
        <Box
          className={clsx(classes.kitchenOrderStatusTag, {
            ["green"]: ["Approved", "Order is Preparing", "Completed"].includes(
              status
            ),

            ["light-green"]: status === "Not Started",

            ["red"]: status === "Declined",
          })}
        >
          {t(status)}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(KitchenOrderStatusTag));
