import {
  Checkbox,
  IconButton,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  styled,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../CustomButton.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { CloseRounded } from "@material-ui/icons";
import CustomTypographyWeb from "../CustomTypography.web";
import { Form, Formik } from "formik";
import { array, object, string } from "yup";
import {
  StylesDialog,
  StylesDialogActions,
  StylesDialogContent,
  StylesDialogTitle,
} from "./CustomPromocodesCreateDiscountDialog.web";
import CustomFormSelectField from "../../../components/src/CustomFormSelectField.web";

import { iconCheck, iconUncheck } from "../../../blocks/promocodes/src/assets";

interface Props extends WithTranslation, WithStyles {
  onCreate(payload: any): void;
  onClose(): void;
  open: boolean;
}

type State = {};

const StyledListItemText = styled(ListItemText)({
  textAlign: "start",
});

const styles = (theme: Theme) =>
  createStyles({
    formMultiselect: {
      "& ul": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "2rem",
        maxHeight: 220,
        backgroundColor: "#F8F8F8",
        "& li, & li.MuiListItem-root.Mui-selected": {
          backgroundColor: "#F8F8F8",
        },
        "& li:first-child": {
          gridColumn: "1/-1",
        },

        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "1fr",
        },
      },
    },
    formMultiselectInput: {
      "&.MuiSelect-root": {
        display: "block",
      },
    },
  });

class CustomPromocodesAdvertisingEditTargetAreaDialog extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { onCreate, i18n, t, onClose, open, classes } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <>
        <StylesDialog
          open={open}
          onClose={onClose}
          aria-labelledby="formDialogEditTargetArea"
          dir={dir}
        >
          <StylesDialogTitle
            id="formDialogEditTargetArea"
            dir={dir}
            style={{ paddingBlockEnd: 8 }}
          >
            <CustomTypographyWeb className="StylesDialogTitle__title">
              {t("Target Areas")}
              <br />
              <CustomTypographyWeb>
                {t(
                  "Your ad will target customers in all the promotional areas."
                )}
              </CustomTypographyWeb>
            </CustomTypographyWeb>
            <IconButton
              aria-label="close"
              onClick={onClose}
              className="StylesDialogTitle__btnClose"
            >
              <CloseRounded htmlColor="#334155" />
            </IconButton>
          </StylesDialogTitle>
          <Formik
            initialValues={{
              value: [] as string[],
            }}
            validationSchema={object({
              value: array(string()),
            })}
            onSubmit={(value) => {
              const payload = { value };
              onCreate(payload);
              onClose();
            }}
          >
            {(props) => {
              const renderValue = (selected: string[]) => {
                const matchSelected = MOCK_OPTIONS.filter((category) =>
                  selected.includes(category.id)
                );

                return matchSelected.length === MOCK_OPTIONS.length
                  ? t("All areas")
                  : matchSelected
                      .map((category) => category.attributes.name)
                      .join(", ");
              };

              const onChange = (event: any) => {
                const value = event.target.value as string[];

                if (value[value.length - 1] === "All") {
                  props.setFieldValue(
                    "value",
                    props.values.value.length === MOCK_OPTIONS.length
                      ? []
                      : MOCK_OPTIONS.map((category) => category.id)
                  );
                  return;
                }
                props.setFieldValue("value", value);
              };

              return (
                <Form>
                  <StylesDialogContent>
                    <CustomFormSelectField
                      fullWidth
                      name="value"
                      label={t("Promotional areas (select one or more)")}
                      variant="outlined"
                      error={props.touched.value && !!props.errors.value}
                      multiple
                      inputProps={{
                        renderValue: renderValue,
                        className: classes.formMultiselectInput,
                        placeholder: t("Select areas"),
                      }}
                      MenuProps={{
                        className: classes.formMultiselect,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={onChange}
                      dir={dir}
                    >
                      <MenuItem value="All" dir={dir}>
                        <Checkbox
                          icon={<img src={iconUncheck} width={20} />}
                          checkedIcon={<img src={iconCheck} width={20} />}
                          checked={
                            MOCK_OPTIONS.length === props.values.value.length
                          }
                        />
                        <StyledListItemText
                          primary={t("All areas")}
                          dir={dir}
                        />
                      </MenuItem>
                      {MOCK_OPTIONS.map((category) => (
                        <MenuItem
                          key={category.id}
                          value={category.id}
                          dir={dir}
                        >
                          <Checkbox
                            icon={<img src={iconUncheck} width={20} />}
                            checkedIcon={<img src={iconCheck} width={20} />}
                            checked={
                              props.values.value.indexOf(category.id) > -1
                            }
                          />
                          <StyledListItemText
                            primary={category.attributes.name}
                            dir={dir}
                          />
                        </MenuItem>
                      ))}
                    </CustomFormSelectField>
                  </StylesDialogContent>
                  <StylesDialogActions dir={dir}>
                    <CustomButton
                      onClick={onClose}
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="StylesDialogActions__btnCancel"
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t("Save")}
                    </CustomButton>
                  </StylesDialogActions>
                </Form>
              );
            }}
          </Formik>
        </StylesDialog>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(CustomPromocodesAdvertisingEditTargetAreaDialog)
);

const MOCK_OPTIONS = [
  {
    id: "Southern Riyadh",
    type: "",
    attributes: {
      id: 0,
      name: "Southern Riyadh",
    },
  },
  {
    id: "Northern Riyadh",
    type: "",
    attributes: {
      id: 0,
      name: "Northern Riyadh",
    },
  },
  {
    id: "Medina",
    type: "",
    attributes: {
      id: 0,
      name: "Medina",
    },
  },
  {
    id: "Mecca",
    type: "",
    attributes: {
      id: 0,
      name: "Mecca",
    },
  },
  {
    id: "Dammam",
    type: "",
    attributes: {
      id: 0,
      name: "Dammam",
    },
  },
  {
    id: "Tabuk",
    type: "",
    attributes: {
      id: 0,
      name: "Tabuk",
    },
  },
  {
    id: "Jeddah",
    type: "",
    attributes: {
      id: 0,
      name: "Jeddah",
    },
  },
  {
    id: "Najran",
    type: "",
    attributes: {
      id: 0,
      name: "Najran",
    },
  },
  {
    id: "Al-Kharj",
    type: "",
    attributes: {
      id: 0,
      name: "Al-Kharj",
    },
  },
  {
    id: "Hail",
    type: "",
    attributes: {
      id: 0,
      name: "Hail",
    },
  },
];
