// Customizable Area Start
import React from "react";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import {
  Box,
  Portal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
import clsx from "clsx";
import { withTranslation } from "react-i18next";
import AdminBranchDetailFormDialog from "../../../components/src/AdminBranchDetailFormDialog.web";
import AdminBranchManagementMenu from "../../../components/src/AdminBranchManagementMenu.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTable from "../../../components/src/CustomTable";
import AdminLocationBranchStatusController, {
  Props,
} from "./AdminLocationBranchStatusController.web";
import AdminBranchManagementStatusTag from "../../../components/src/AdminBranchManagementStatusTag.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        gap: 10,
      },
    },

    filter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    filterSelect: {
      width: "100%",
    },

    statusFilterSelect: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 3,
        flex: 1,
      },
    },

    filterSelectTime: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 2,
        flex: 1,
      },
    },

    restaurantName: {
      fontFamily: "Inter !important",
    },

    address: {
      fontFamily: "Inter !important",
    },

    staffName: {
      fontFamily: "Inter !important",
    },

    branch: {
      fontFamily: "Inter !important",
    },

    role: {
      fontFamily: "Inter !important",
    },

    status: {
      fontFamily: "Inter !important",
    },

    pagination: {
      marginTop: 24,
    },

    downloadButton: {
      marginLeft: "auto",

      "&.rtl": {
        marginLeft: 0,
        marginRight: "auto",
      },
    },

    table: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 38,
    },

    branchTable: {
      "& td": {
        padding: 16,
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    action: {
      display: "flex",
      gap: 5,
      justifyContent: "end",
    },
  });

export class AdminLocationBranchStatus extends AdminLocationBranchStatusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const {
      adminBranches,
      timeFilter,
      statusFilter,
      statusFilterOptions,
      loading,
      snackbar,
      pagination,
      adminBranchDetailDialog,
    } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.actions}>
          <Box className={classes.filter}>
            <Box className={classes.filterSelectTime}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.filterSelect}
                data-test-id="time-select"
                variant="outlined"
                value={timeFilter}
                onChange={(e: any) => this.changeTimeFilter(e.target.value)}
              >
                {this.timeOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box className={classes.statusFilterSelect}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.filterSelect}
                data-test-id="status-select"
                variant="outlined"
                value={statusFilter}
                onChange={(e: any) => this.changeStatusFilter(e.target.value)}
                displayEmpty
                inputProps={{
                  renderValue: (selected: string) => {
                    if (!selected) {
                      return t("Status");
                    }

                    const selectedOption = statusFilterOptions.find(
                      (option: any) => option.value === selected
                    );

                    if (!selectedOption) {
                      return "";
                    }

                    return selectedOption.name;
                  },
                }}
              >
                {statusFilterOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>
          </Box>

          <CustomButton
            className={clsx(classes.downloadButton, { ["rtl"]: rtl })}
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="download-order-report-btn"
            onClick={this.downloadReport}
          >
            {t("Download Report")}
          </CustomButton>
        </Box>

        <Box className={classes.table}>
          <TableContainer>
            <CustomTable className={classes.branchTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Branch")}</TableCell>
                  <TableCell>{t("Address")}</TableCell>
                  <TableCell>{t("Staff Name")}</TableCell>
                  <TableCell>{t("Role")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {adminBranches.length ? (
                  adminBranches.map((branch) => (
                    <TableRow
                      key={branch.id}
                      data-test-id={`table-row-${branch.id}`}
                    >
                      <TableCell className={classes.restaurantName}>
                        {branch.restaurantName}
                      </TableCell>
                      <TableCell className={classes.branch}>
                        {branch.branch}
                      </TableCell>
                      <TableCell className={classes.address}>
                        {branch.address}
                      </TableCell>
                      <TableCell className={classes.staffName}>
                        {branch.staffName}
                      </TableCell>
                      <TableCell className={classes.role}>
                        {branch.role}
                      </TableCell>
                      <TableCell className={classes.status}>
                        <AdminBranchManagementStatusTag
                          status={branch.status}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className={classes.action}>
                          <AdminBranchManagementMenu
                            data-test-id="menu"
                            onSelectAction={this.handleAdminBranchSelectAction}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              data-test-id="pagination"
              fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        <AdminBranchDetailFormDialog
          data-test-id="admin-branch-detail-dialog"
          dialog={adminBranchDetailDialog}
          onClose={this.closeAdminBranchDetailDialog}
          editAdminBranch={this.closeAdminBranchDetailDialog}
        />

        <CustomLoadingBackdrop open={loading} />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(AdminLocationBranchStatus));
// Customizable Area End
