import {
  Box,
  TableCell,
  TableRow,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

export enum FeedbackOverviewStatus {
  UP = "up",
  DOWN = "down",
}

export interface FeedbackOverview {
  id: string;
  name: string;
  data: number;
  status: FeedbackOverviewStatus;
}

export interface Props extends WithStyles {
  feedbackOverview: FeedbackOverview;
}

const miniGreenMetric = require("./mini-green-metric.svg");
const miniRedMetric = require("./mini-red-metric.svg");

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    name: {
      fontFamily: "Inter !important",
      color: "#2E2E30 !important",
      minWidth: 140,
    },

    status: {
      width: 68,
      height: 22,
      objectFit: "cover",
    },

    data: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      justifyContent: "end",
    },

    dataNumber: {
      fontFamily: "Inter !important",
      color: "#2E2E30 !important",
    },

    arrowUpward: {
      color: "#5DB48A",
    },

    arrowDownward: {
      color: "#EB7487",
    },
  });

export class KitchenFeedbackOverviewRow extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, feedbackOverview } = this.props;

    return (
      <TableRow className={classes.container}>
        <TableCell
          style={{
            width: "100%",
          }}
        />

        <TableCell>
          <CustomTypography variant="h5" className={classes.name}>
            {feedbackOverview.name}
          </CustomTypography>
        </TableCell>

        <TableCell>
          {(() => {
            switch (feedbackOverview.status) {
              case FeedbackOverviewStatus.UP:
                return (
                  <img
                    className={classes.status}
                    src={miniGreenMetric}
                    alt={feedbackOverview.name}
                  />
                );

              case FeedbackOverviewStatus.DOWN:
                return (
                  <img
                    className={classes.status}
                    src={miniRedMetric}
                    alt={feedbackOverview.name}
                  />
                );

              default:
                return <></>;
            }
          })()}
        </TableCell>

        <TableCell>
          <Box className={classes.data}>
            <CustomTypography className={classes.dataNumber} variant="h5">
              {feedbackOverview.data}
            </CustomTypography>

            {(() => {
              switch (feedbackOverview.status) {
                case FeedbackOverviewStatus.UP:
                  return <ArrowUpward className={classes.arrowUpward} />;

                case FeedbackOverviewStatus.DOWN:
                  return <ArrowDownward className={classes.arrowDownward} />;

                default:
                  return <></>;
              }
            })()}
          </Box>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(KitchenFeedbackOverviewRow);
