import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
} from "recharts";

type Props = {
  lostSalesNumber: number;
  rejectionsNumber: number;
  data: { name: string; cancelation: number }[];
};

export default function CustomAnalyticsAdminAvoidableCancellationChart({
  lostSalesNumber,
  rejectionsNumber,
  data,
}: Props) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  const classes = useStyles();

  return (
    <Box dir={dir}>
      <h3 className={classes.title}>{t("Avoidable Cancellations")}</h3>

      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        dir={dir}
        marginTop={"32px"}
      >
        <Box
          boxShadow="0px 0px 2px 0px #00000040"
          dir={dir}
          color={"#767676"}
          fontFamily={"Inter"}
          paddingX={"10px"}
          paddingY={"4px"}
          width={"100%"}
          maxWidth={140}
          borderRadius={5}
        >
          <span className={classes.lostSalesNumber}>
            {t("SAR")} {lostSalesNumber}
          </span>
          <br />
          <span className={classes.lostSalesText}>{t("Lost Sales")}</span>
        </Box>
      </Box>

      <ResponsiveContainer maxHeight={150} className={classes.chartContainer}>
        <BarChart data={data} maxBarSize={18} barGap={32}>
          <CartesianGrid vertical={false} stroke="#B1B1B1" strokeWidth={0.5} />
          <Bar
            dataKey={"cancelation"}
            fill="#E9ECF1"
            radius={3}
            activeBar={{ fill: "#FF6666" }}
          />
          <XAxis dataKey="name" interval={0} reversed={rtl} hide />
          <YAxis
            width={10}
            interval={1}
            fontFamily="Inter"
            fontSize={10}
            fontWeight={700}
            color="#767676"
            axisLine={false}
            tickLine={false}
            orientation={rtl ? "right" : "left"}
          />
          <Tooltip content={<></>} cursor={false} />
        </BarChart>
      </ResponsiveContainer>

      <Box marginTop={"25px"} fontFamily={"Inter"} color={"#767676"}>
        <span className={classes.unavailableItemText}>
          {t("Items Unavailable")}
        </span>
        <br />
        <span className={classes.unavailableItemNumber}>
          {t("{{rejections}} rejections", { rejections: rejectionsNumber })}
        </span>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    title: {
      textAlign: "center",
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      lineHeight: 16.94 / 14,
      color: "#2E2E30",
    },
    chartContainer: {
      marginBlockStart: 11,
    },
    unavailableItemText: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 16.94 / 14,
      fontWeight: 500,
    },
    unavailableItemNumber: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 12.1 / 10,
    },
    lostSalesNumber: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 14.52 / 12,
    },
    lostSalesText: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 12.1 / 10,
    },
  };
});
