import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    WithStyles,
    createStyles,
    withStyles,
  } from "@material-ui/core";
  import {
    MoreVert,
    RemoveRedEyeOutlined,
    SaveAlt
  } from "@material-ui/icons";
  import clsx from "clsx";
  import React from "react";
  import { v4 as uuidv4 } from "uuid";
  import CustomTypography from "./CustomTypography.web";
  import { AdminPaymentFinanceDetailFormDialogMode } from "./AdminPaymentFinanceDetailFormDialog.web";
  import { WithTranslation, withTranslation } from "react-i18next";
  
  export interface Props extends WithStyles, WithTranslation {
    onSelectAction(value: AdminPaymentFinanceMenuAction): void;
  }
  
  const styles = createStyles({
    statusExpand: {
      marginLeft: "auto",
    },
  
    menu: {
      "& .MuiMenu-list": {
        padding: "0 !important",
        width: 170,
      },
  
      "&.rtl": {
        direction: "rtl",
      },
    },
  
    actionLink: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      padding: "8px 16px",
      width: "100%",
      gap: 8,
  
      "&:hover": {
        backgroundColor: "rgba(255, 102, 102, 0.4)",
        borderLeft: "3px solid #FF6666",
        color: "#FFFFFF",
      },
  
      "& svg": {
        fill: "#333333",
      },
    },
  
    actionItemIcon: {
      width: 18,
      height: 18,
    },
  
    actionItemName: {
      color: "#333333",
    },
  
    actionItem: {
      padding: 0,
    },
  });
  
  export enum AdminPaymentFinanceAction {
    Delete = "delete",
  }
  
  export type AdminPaymentFinanceMenuAction =
    | AdminPaymentFinanceAction
    | AdminPaymentFinanceDetailFormDialogMode;
  
  export class AdminPaymentFinanceMenu extends React.Component<
    Props,
    {
      statusMenu: HTMLElement | null;
    }
  > {
    constructor(props: Props) {
      super(props);
  
      this.state = {
        statusMenu: null,
      };
    }
  
    menu = [
      {
        id: uuidv4(),
        name: "View Details",
        value: AdminPaymentFinanceDetailFormDialogMode.View,
        icon: RemoveRedEyeOutlined,
      },
      {
        id: uuidv4(),
        name: "Download",
        value: AdminPaymentFinanceDetailFormDialogMode.Download,
        icon: SaveAlt,
      },
    ];
  
    openFilter = (event: any) => {
      this.setState({
        statusMenu: event.currentTarget,
      });
    };
  
    closeFilter = () => {
      this.setState({
        statusMenu: null,
      });
    };
  
    onChangeStatus = (value: AdminPaymentFinanceMenuAction) => {
      this.props.onSelectAction(value);
  
      this.closeFilter();
    };
  
    render(): React.ReactNode {
      const { classes, t, i18n } = this.props;
  
      const { statusMenu } = this.state;
  
      const id = uuidv4();
  
      const options = this.menu;
  
      const rtl = i18n.language === "ar";
  
      return (
        <Box className={classes.detailStatus}>
          <Box
            id={`filter-button-${id}`}
            aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
            aria-haspopup="true"
            aria-expanded={statusMenu ? "true" : undefined}
            onClick={this.openFilter}
          >
            <IconButton className={classes.catalogueMenuButton} size="small">
              <MoreVert />
            </IconButton>
          </Box>
  
          <Menu
            className={clsx(classes.menu, {
              ["rtl"]: rtl,
            })}
            id={`filter-menu-${id}`}
            anchorEl={statusMenu}
            open={Boolean(statusMenu)}
            onClose={this.closeFilter}
            MenuListProps={{
              className: classes.detailStatus,
              "aria-labelledby": `filter-button-${id}`,
            }}
          >
            {options.map((option) => {
              return (
                <MenuItem
                  className={clsx(classes.actionItem)}
                  key={option.id}
                  data-test-id={option.name.toLowerCase()}
                  onClick={() => this.onChangeStatus(option.value)}
                >
                  <Box className={clsx(classes.actionLink)}>
                    <option.icon className={classes.actionItemIcon} />
                    <CustomTypography className={classes.actionItemName}>
                      {t(option.name)}
                    </CustomTypography>
                  </Box>
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      );
    }
  }
  
  export default withStyles(styles)(withTranslation()(AdminPaymentFinanceMenu));
  