import { Box, InputLabel, SelectProps, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ErrorMessage, Field } from "formik";
import React from "react";
import CustomSelect from "./CustomSelect";
import CustomTypography from "./CustomTypography.web";
import clsx from "clsx";

export interface Props extends WithStyles {
  subLabel?: string;
}

export type CustomFormSelectFieldProps = SelectProps & Props;

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    "& + &": {
      marginTop: 24,
    },

    "& .MuiFormHelperText-root": {
      margin: 0,
      color: "#DC2626",
      fontWeight: 400,
      lineHeight: "18px",
      fontSize: 12,
      fontFamily: "Inter",
      textAlign: "initial",
    },
  },

  label: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#333333",
    fontFamily: "Raleway",
    position: "relative",

    "&.is-required": {
      "&::after": {
        content: "'*'",
        position: "absolute",
        top: 0,
        right: -8,
        color: "#DC2626",
      },
    },
  },

  subLabel: {
    display: "inline-block",
    fontSize: "12px !important",
    marginLeft: 8,
  },

  input: {
    width: "100%",
  },
});

export class CustomFormSelectField extends React.Component<CustomFormSelectFieldProps> {
  constructor(props: CustomFormSelectFieldProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { name, label, classes, error, subLabel, required, ...rest } =
      this.props;

    return (
      <Box className={classes?.container}>
        <InputLabel
          className={clsx(classes.label, {
            ["is-required"]: required,
          })}
          htmlFor={name}
        >
          {label}

          {subLabel && (
            <CustomTypography className={classes.subLabel}>
              {subLabel}
            </CustomTypography>
          )}
        </InputLabel>

        <Box className={classes.input}>
          <Field
            as={CustomSelect}
            name={name}
            error={error}
            helperText={error && <ErrorMessage name={name} />}
            {...rest}
          />
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFormSelectField);
