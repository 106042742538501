import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { WithStyles } from "@material-ui/core";
import {
  deFlag,
  ukFlag,
  dashboard,
  report,
  orders,
  rating,
  adv,
  promo,
  compensations,
  users,
  menu,
  time,
  finance,
  branch,
} from "./assets";
import DashboardKitchen from "../../../blocks/dashboard/src/DashboardKitchen.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import KitchenAccountManagement from "../../../blocks/social-media-account/src/KitchenAccountManagement.web";
import KitchenMenuManagement from "../../../blocks/catalogue/src/KitchenMenuManagement.web";
import KitchenOrderManagement from "../../../blocks/ordermanagement/src/KitchenOrderManagement.web";
import KitchenBranchStatus from "../../location/src/LocationBranchStatus.web";
import KitchenPromotions from "../../promocodes/src/PromocodesPromotionsList.web";
import KitchenRatingsAndReviews from "../../../blocks/reviews/src/ReviewsRatingAndReviews.web";
import KitchenCompensations from "../../../blocks/loyaltysystem/src/LoyaltySystemCompensations.web";
import KitchenAdvertising from "../../../blocks/promocodes/src/PromocodesAdvertising.web";
import KitchenFinance from "../../../blocks/paymentadmin2/src/Paymentadmin2FinanceView.web";
import { AnalyticsWithTranslation as KitchenReports } from "../../../blocks/analytics/src/Analytics.web";
import KitchenUserProfile from "../../../blocks/customisableuserprofiles/src/UserProfile.web";
import KitchenOpeningTimes from "../../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2OpeningTimes.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import KitchenAccountManagementDetail from "../../../blocks/social-media-account/src/KitchenAccountManagementDetail.web";

import DashboardKitchenNotificationsAll from "../../../blocks/notifications/src/NotificationsAll.web";
import { matchPath } from "react-router-dom";
import { WithTranslation } from "react-i18next";
import i18n from "i18next";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  currentPage: {
    name: string;
    to: string;
  };
  mobileOpen: boolean;
  isClosing: boolean;
  selectedLanguage: string;
  languages: {
    name: string;
    value: string;
    flag: string;
  }[];

  userProfile: {
    id: string;
    type: string;
    attributes: {
      email: string;
      user_name: string | null;
      bio: string | null;
      add_location: string | null;
      twitter_handle: string | null;
      facebook_profile: string | null;
      linkedin_profile: string | null;
      image_url: string | null;
    };
  } | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  performanceMonitoringRoutes = [
    {
      to: "/kitchen/owner/dashboard",
      name: "Dashboard",
      component: DashboardKitchen,
      icon: dashboard,
      exact: true,
    },

    {
      to: "/kitchen/owner/reports",
      name: "Reports",
      icon: report,
      component: KitchenReports,
      exact: true,
    },

    {
      to: "/kitchen/owner/orders",
      name: "Orders",
      icon: orders,
      component: KitchenOrderManagement,
      exact: true,
    },

    {
      to: "/kitchen/owner/ratings-and-reviews",
      name: "Ratings and Reviews",
      icon: rating,
      component: KitchenRatingsAndReviews,
      exact: true,
    },
  ];

  growYourBusinessRoutes = [
    {
      to: "/kitchen/owner/advertising",
      name: "Advertising",
      icon: adv,
      component: KitchenAdvertising,
      exact: true,
    },

    {
      to: "/kitchen/owner/promotions",
      name: "Promotions",
      icon: promo,
      component: KitchenPromotions,
      exact: true,
    },
  ];

  manageYourBusinessRoutes = [
    {
      to: "/kitchen/owner/compensations",
      name: "Compensations",
      icon: compensations,
      component: KitchenCompensations,
      exact: true,
    },

    {
      to: "/kitchen/owner/user-management",
      name: "User Management",
      icon: users,
      exact: true,
      component: KitchenAccountManagement,
    },

    {
      to: `/kitchen/owner/user-management/user/:id`,
      name: "Edit details",
      exact: false,
      child: true,
      component: KitchenAccountManagementDetail,
    },

    {
      to: "/kitchen/owner/menu-management",
      name: "Menu Management",
      icon: menu,
      component: KitchenMenuManagement,
      exact: true,
    },

    {
      to: "/kitchen/owner/opening-times",
      name: "Opening Times",
      component: KitchenOpeningTimes,
      icon: time,
    },

    {
      to: "/kitchen/owner/finance",
      name: "Finance",
      icon: finance,
      component: KitchenFinance,
      exact: true,
    },

    {
      to: "/kitchen/owner/branch-status",
      name: "Branch Status",
      icon: branch,
      component: KitchenBranchStatus,
      exact: true,
    },
  ];

  userRoutes = [
    {
      to: "/kitchen/owner/profile",
      name: "Profile",
      icon: branch,
      component: KitchenUserProfile,
      exact: true,
    },
    {
      to: "/kitchen/owner/notifications-all",
      name: "Notifications All",
      icon: branch,
      component: DashboardKitchenNotificationsAll,
      exact: true,
    },
  ];

  token: string = "";
  accountType: string = "";

  apiGetKitchenAdminProfileCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      currentPage: {
        name: "",
        to: "",
      },
      mobileOpen: false,
      isClosing: false,
      selectedLanguage: i18n.language,
      languages: [
        { name: "UK", value: "en", flag: ukFlag },
        { name: "DE", value: "ar", flag: deFlag },
      ],
      userProfile: null,
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.UserUpdateProfileMessage),
      getName(MessageEnum.AdvertisitingChangeTitle),
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    await this.getKitchenUserProfile();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillMount() {
    const selectedLanguage = await StorageProvider.get("ownerLanguage");

    if (!selectedLanguage) {
      await StorageProvider.set("ownerLanguage", "en");
      i18n.changeLanguage("en");

      return;
    }

    i18n.changeLanguage(selectedLanguage);

    this.setState({
      selectedLanguage,
    });
  }

  allRoutes = [
    ...this.performanceMonitoringRoutes,
    ...this.growYourBusinessRoutes,
    ...this.manageYourBusinessRoutes,
    ...this.userRoutes,
  ];

  initKitchenPage = async () => {
    this.token = await StorageProvider.get("token");
    this.accountType = await StorageProvider.get("account_type");

    if (!this.token || this.accountType !== "KitchenAccount") {
      this.handleLogout();

      return;
    }

    if (this.state.currentPage.to || this.state.currentPage.name) {
      return;
    }

    const pathname = window.location.pathname;

    const currentPage = this.allRoutes.find((route) =>
      matchPath(pathname, {
        path: route.to,
        exact: true,
        strict: false,
      })
    );

    if (!currentPage) {
      return;
    }

    this.setCurrentPage({
      to: currentPage.to,
      name: currentPage.name,
    });
  };

  changeLanguage = (event: any) => {
    this.setState(
      {
        selectedLanguage: event.target.value,
      },
      async () => {
        i18n.changeLanguage(this.state.selectedLanguage);
        await StorageProvider.set("ownerLanguage", this.state.selectedLanguage);
      }
    );
  };

  setMobileOpen = (mobileOpen: boolean) => {
    this.setState({
      mobileOpen,
    });
  };

  setIsClosing = (isClosing: boolean) => {
    this.setState({
      isClosing,
    });
  };

  handleDrawerClose = () => {
    this.setIsClosing(true);
    this.setMobileOpen(false);
  };

  handleDrawerTransitionEnd = () => {
    this.setIsClosing(false);
  };

  handleDrawerToggle = () => {
    if (!this.state.isClosing) {
      this.setMobileOpen(!this.state.mobileOpen);
    }
  };

  setCurrentPage = (currentPage: { name: string; to: string }) => {
    this.setState({ currentPage });
  };

  changePage = (currentPage: { name: string; to: string }) => {
    this.setCurrentPage(currentPage);

    if (this.state.mobileOpen) {
      this.handleDrawerClose();
    }
  };

  handleLogout = async () => {
    await StorageProvider.remove("token");
    await StorageProvider.remove("refresh_token");
    await StorageProvider.remove("account_id");
    await StorageProvider.remove("account_type");

    this.props.navigation.navigate("EmailAccountLoginKitchen");
  };

  checkIfNotificationPageActive = () => {
    return this.state.currentPage.name === "Notifications All";
  };

  getUserNameAvatar = () => {
    const name = this.state.userProfile?.attributes.user_name;

    if (!name) return "-";

    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join("");
  };

  getKitchenUserProfile = async () => {
    const accountId = await StorageProvider.get("account_id");
    const token = await StorageProvider.get("token");

    const endPoint =
      "account_block/accounts/" + accountId + "/show_kitchen_account";

    const requestHeader = {
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetKitchenAdminProfileCallId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(requestHeader)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "get");

    runEngine.sendMessage(message.id, message);
  };

  handleGetKitchenUserProfileResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        userProfile: responseJson.data,
      });
    }
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson?.errors?.[0]?.token) {
        this.handleLogout();
      }

      if (this.apiGetKitchenAdminProfileCallId === apiRequestCallId) {
        this.handleGetKitchenUserProfileResponse(responseJson);
      }
    }

    if (getName(MessageEnum.UserUpdateProfileMessage) === message.id) {
      this.getKitchenUserProfile();
    }

    if (getName(MessageEnum.AdvertisitingChangeTitle) === message.id) {
      const advertisingPageTitle = message.getData(
        getName(MessageEnum.AdvertisitingChangeTitleBodyMessage)
      );

      this.setCurrentPage({
        to: this.state.currentPage.to,
        name: advertisingPageTitle,
      });
    }
    // Customizable Area End
  }
}
