import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomButton from "./CustomButton.web";
import CustomSnackbar, { CustomSnackbarType } from "./CustomSnackbar.web";
import CustomTypography from "./CustomTypography.web";
import KitchenDrawer from "./KitchenDrawer.web";
import KitchenOrderSummaryDish from "./KitchenOrderSummaryDish.web";
import { KitchenOrder, KitchenOrderDish } from "./KitchenOrderTableRow.web";

export interface Props extends WithStyles, WithTranslation {
  open: boolean;
  selectedOrder: KitchenOrder;
  drawerSnackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  onClose(): void;
  updateOrderItemStatus(
    id: string,
    status: string,
    declineReason: string
  ): void;
  openOrderChangeDrawer(dish: KitchenOrderDish): void;
  notifyUser(): void;
  closeDrawerSnackbar(): void;
}

export enum KitchenOrderSummaryStatus {
  Accept = "accepted",
  Decline = "declined",
  Replace = "replace",
  ReadyForPickup = "ready_for_pickup",
}

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    title: {
      fontFamily: "Inter !important",
      lineHeight: "22px",
    },

    content: {
      marginTop: 13,
    },

    group: {
      "& + &": {
        marginTop: 27,
      },
    },

    declinedTitle: {
      color: "#DC2626",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    acceptedTitle: {
      color: "#059669",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    list: {
      marginTop: 10,
    },

    drawerBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: "1 0 auto",
      },
    },

    snackbar: {
      position: "absolute",
      bottom: 90,
      width: 258,
    },
  });

export class KitchenOrderSummaryDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      open,
      selectedOrder,
      t,
      i18n,
      drawerSnackbar,

      onClose,
      openOrderChangeDrawer,
      updateOrderItemStatus,
      notifyUser,
      closeDrawerSnackbar,
    } = this.props;

    const { orderDetailData } = selectedOrder;

    const acceptedDishes = orderDetailData.filter((dish) =>
      [
        KitchenOrderSummaryStatus.Accept,
        KitchenOrderSummaryStatus.ReadyForPickup,
      ].includes(dish.orderSummaryStatus)
    );

    const replacedDishes = orderDetailData.filter(
      (dish) => dish.orderSummaryStatus === KitchenOrderSummaryStatus.Replace
    );

    const declinedDishes = orderDetailData.filter(
      (dish) => dish.orderSummaryStatus === KitchenOrderSummaryStatus.Decline
    );

    const rtl = i18n.language === "ar";

    return (
      <KitchenDrawer
        open={open}
        dir={rtl ? "rtl" : ""}
        anchor={rtl ? "left" : "right"}
        title={t("Order Summary")}
        onClose={onClose}
        body={
          <Box className={classes.container}>
            <CustomTypography variant="body1" className={classes.title}>
              {t("Order id {{orderId}} on date of {{date}} at {{time}}", {
                orderId: selectedOrder.orderId,
                date: moment(selectedOrder.time).format("DD/MM/yyyy"),
                time: moment(selectedOrder.time).format("h:mmA"),
              })}
            </CustomTypography>

            <Box className={classes.content}>
              {!!declinedDishes.length && (
                <Box className={classes.group}>
                  <CustomTypography
                    variant="body2"
                    className={classes.declinedTitle}
                  >
                    {t(
                      "Items are unavailable and it will be not ready for pickup"
                    )}
                  </CustomTypography>

                  <Box className={classes.list}>
                    {declinedDishes.map((dish) => (
                      <KitchenOrderSummaryDish
                        data-test-id={`dish-${dish.id}`}
                        key={dish.id}
                        dish={dish}
                        sendMessage={(declineReason: string) =>
                          updateOrderItemStatus(
                            dish.id,
                            dish.status,
                            declineReason
                          )
                        }
                        openOrderChangeDrawer={() =>
                          openOrderChangeDrawer(dish)
                        }
                      />
                    ))}
                  </Box>
                </Box>
              )}

              {!!replacedDishes.length && (
                <Box className={classes.group}>
                  <CustomTypography
                    variant="body2"
                    className={classes.declinedTitle}
                  >
                    {t("Item replace with actual item order")}
                  </CustomTypography>

                  <Box className={classes.list}>
                    {replacedDishes.map((dish) => {
                      return (
                        <KitchenOrderSummaryDish
                          data-test-id={`dish-${dish.id}`}
                          key={dish.id}
                          dish={dish}
                          sendMessage={() => {}}
                          openOrderChangeDrawer={() =>
                            openOrderChangeDrawer(dish)
                          }
                        />
                      );
                    })}
                  </Box>
                </Box>
              )}

              <Box className={classes.group}>
                <CustomTypography
                  variant="body2"
                  className={classes.acceptedTitle}
                >
                  {t("Items are available and it will be ready for pickup")}
                </CustomTypography>

                <Box className={classes.list}>
                  {acceptedDishes.map((dish) => {
                    return (
                      <KitchenOrderSummaryDish
                        key={dish.id}
                        dish={dish}
                        sendMessage={() => {}}
                        openOrderChangeDrawer={() =>
                          openOrderChangeDrawer(dish)
                        }
                      />
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        }
        bottom={
          <Box className={classes.drawerBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="back-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              data-test-id="notify-user-btn"
              color="primary"
              variant="contained"
              onClick={notifyUser}
            >
              {t("Notify User")}
            </CustomButton>

            <CustomSnackbar
              className={classes.snackbar}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={drawerSnackbar.open}
              type={drawerSnackbar.type}
              message={drawerSnackbar.message}
              onClose={closeDrawerSnackbar}
            />
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(KitchenOrderSummaryDrawer));
