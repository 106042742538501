import { IconButton, MenuItem, styled } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { InferType, number, object, string } from "yup";
import CustomButton from "../CustomButton.web";
import CustomTypographyWeb from "../CustomTypography.web";
import {
  StylesDialog,
  StylesDialogActions,
  StylesDialogContent,
  StylesDialogTitle,
} from "../promocodes/CustomPromocodesCreateDiscountDialog.web";

import CustomFormInputFieldWeb from "../CustomFormInputField.web";
import CustomFormSelectFieldWeb from "../CustomFormSelectField.web";
import { StyledListItemText } from "../../../blocks/promocodes/src/PromocodesPromotionsCreate.web";
import { TFunction } from "i18next";
import CustomFormDatePickerFieldWeb from "../CustomFormDatePickerField.web";

const schema = (t: TFunction<"translation", undefined>) =>
  object({
    reviewId: string(),
    restaurantName: string(),
    branch: string(),
    dateReviewed: string(),
    userRating: number()
      .transform((value) => Number(value))
      .typeError(t("Invalid user rating")),
  });

type Schema = InferType<ReturnType<typeof schema>>;

interface Props extends WithTranslation {
  open: boolean;
  onClose: () => void;
  initValue: Schema | null;
}

type State = {
  timeOfDayList: string[];
  weekdays: string[];
};

const StyledForm = styled(Form)({
  "& [class*='CustomFormInputField-container'], & [class*='CustomFormSelectField-container']":
    {
      marginBlockStart: 0,
    },
  "& > div.MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    overflowY: "auto",
  },
  "& div:has(.form__input__times)": {
    marginBlockStart: 0,
  },

  "& input[name='reviewId'], & input[name='userRating'], & input[name='dateSubmitted']":
    {
      fontFamily: "Inter",
    },
});

class CustomLocationScheduleEditDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { i18n, t, open, onClose, initValue } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const initialValues = initValue ?? schema(t).default();

    return (
      <>
        <StylesDialog
          aria-labelledby="formDialogViewRatingAndReviews"
          dir={dir}
          open={open}
          onClose={onClose}
        >
          <StylesDialogTitle id="formDialogViewRatingAndReviews" dir={dir}>
            <CustomTypographyWeb className="StylesDialogTitle__title">
              {t("View Rating & Reviews")}
            </CustomTypographyWeb>
            <IconButton
              aria-label="close"
              className="StylesDialogTitle__btnClose"
              onClick={onClose}
            >
              <CloseRounded htmlColor="#334155" />
            </IconButton>
          </StylesDialogTitle>
          <Formik<Schema>
            initialValues={initialValues}
            validationSchema={schema(t)}
            onSubmit={() => {}}
          >
            {(props) => {
              return (
                <StyledForm>
                  <StylesDialogContent>
                    <CustomFormInputFieldWeb
                      fullWidth
                      name="reviewId"
                      label={t("Review Id")}
                      variant="outlined"
                      error={props.touched.reviewId && !!props.errors.reviewId}
                      disabled
                    />
                    <CustomFormInputFieldWeb
                      fullWidth
                      name="restaurantName"
                      label={t("Restaurant Name")}
                      placeholder={t("Enter Restaurant Name")}
                      variant="outlined"
                      error={
                        props.touched.restaurantName &&
                        !!props.errors.restaurantName
                      }
                    />
                    <CustomFormSelectFieldWeb
                      fullWidth
                      name="branch"
                      label={t("Branch")}
                      placeholder={t("Enter Branch")}
                      id="formSelectBranch"
                      data-test-id="formSelectBranch"
                      variant="outlined"
                      error={props.touched.branch && !!props.errors.branch}
                      dir={dir}
                      displayEmpty
                    >
                      {[initValue?.branch ?? ""].map((branch) => {
                        return (
                          <MenuItem value={branch} dir={dir}>
                            <StyledListItemText primary={branch} dir={dir} />
                          </MenuItem>
                        );
                      })}
                    </CustomFormSelectFieldWeb>

                    <CustomFormDatePickerFieldWeb
                      locale={i18n.language}
                      dateFormat="yyyy-MM-dd"
                      name="dateSubmitted"
                      label={t("Review Date")}
                      placeholder={t("Enter Date")}
                      variant="outlined"
                      error={
                        props.touched.dateReviewed &&
                        !!props.errors.dateReviewed
                      }
                      data-test-id="inputDateReviewed"
                      required={false}
                      fullWidth
                      value={new Date(props.values.dateReviewed)}
                    />
                    <CustomFormInputFieldWeb
                      fullWidth
                      name="userRating"
                      label={t("User Rating")}
                      placeholder={t("Enter User Rating")}
                      variant="outlined"
                      error={
                        props.touched.userRating && !!props.errors.userRating
                      }
                    />
                  </StylesDialogContent>
                  <StylesDialogActions dir={dir}>
                    <CustomButton
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="StylesDialogActions__btnCancel"
                      onClick={onClose}
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t("Save")}
                    </CustomButton>
                  </StylesDialogActions>
                </StyledForm>
              );
            }}
          </Formik>
        </StylesDialog>
      </>
    );
  }
}

export default withTranslation()(CustomLocationScheduleEditDialog);
