// Customizable Area Start
import React from "react";

import {
  Box,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Theme,
  createStyles,
  withStyles,
  Tabs,
  Tab,
	Chip
} from "@material-ui/core";

import { Close, Search } from "@material-ui/icons";

import AdminPaymentAdminFinanceController, {
  Props,
  StatisticsDataProps,
} from "./AdminPaymentAdminFinanceController.web";

import AdminFinanceStatisticsCard, {
  AdminFinanceStatistics,
} from "../../../components/src/AdminFinanceStatisticsCard.web";

import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomPagination from "../../../components/src/CustomPagination";
import AdminFinanceStatusTag from "../../../components/src/AdminFinanceStatusTag.web";
import AdminPaymentFinanceMenu from "../../../components/src/AdminPaymentFinanceMenu.web";
import AdminPaymentFinanceDetailFormDialog from "../../../components/src/AdminPaymentFinanceDetailFormDialog.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";

import { withTranslation } from "react-i18next";
import clsx from "clsx";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "& *": {
        boxSizing: "border-box",
      },

      "&.rtl": {
        direction: "rtl",
      },
    },

    statistics: {
      display: "flex",
      gap: 24,
      flexWrap: "wrap",
    },

    statisticsItem: {
      flex: 1,
    },

    filterSelect: {
      minWidth: 154,
    },

    filterSession: {
      marginTop: 24,
      display: "flex",
      justifyContent: "space-between",
    },

    filterActions: {
      display: "flex",
      gap: 10,
    },

    button: {
      minWidth: 97,
    },

		tabs: {
			marginTop: 24,
			'& .MuiTabs-indicator': {
				backgroundColor: '#B8A4FA', // Custom indicator color
			},
			borderBottom: '1px solid #00000014'
		},

		tab: {
			textTransform: 'none',
			'&.MuiTab-root': {
				opacity: 1,
			},
		},

		chip: {
			height: 23,
			borderRadius: 18
		},

		tabName: {
			fontFamily: 'Raleway',
			fontWeight: 600,
			fontSize: '16px !important',
			lineHeight: "22px !important"
		},

		tabLabel: {
			display: 'flex',
			gap: 8
		},

		financeList: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 10,
    },

		financeTable: {
      "& td": {
        padding: 16,
				fontFamily: "Inter",
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

		pagination: {
      marginTop: 24
    },
  });

export class AdminPaymentAdminFinance extends AdminPaymentAdminFinanceController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const {
      loading,
      statisticsData,
      timeFilter,
      restaurantFilter,
      transactionSearchValue,
      statusFilter,
			financeData,
			pagination,
			adminPaymentFinanceDialog
    } = this.state;

    const rtl = i18n.language === "ar";

    const statisticsCardData: AdminFinanceStatistics[] = Object.keys(
      statisticsData
    ).map((key) => {
      return {
        name: t(statisticsData[key as keyof StatisticsDataProps].name),
        value: statisticsData[key as keyof StatisticsDataProps].value,
        icon: statisticsData[key as keyof StatisticsDataProps].icon,
        subValue: statisticsData[key as keyof StatisticsDataProps].subValue,
      };
    });

		const dir = rtl ? "rtl" : "";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.statistics}>
          {statisticsCardData.map((data, index) => (
            <Box className={classes.statisticsItem} key={index}>
              <AdminFinanceStatisticsCard statistics={data} isMoney={index === 0}/>
            </Box>
          ))}
        </Box>

        <Box className={classes.filterSession}>
          <Box className={classes.filterActions}>
            <CustomSelect
              dir={dir}
              className={classes.filterSelect}
              data-test-id="time-select"
              variant="outlined"
              value={timeFilter}
              onChange={(e: any) => this.changeTimeFilter(e.target.value)}
            >
              {this.timeOptions.map((option) => (
                <CustomMenuItem value={option.value} key={option.id} dir={dir}>
                  {t(option.name)}
                </CustomMenuItem>
              ))}
            </CustomSelect>

            <CustomSelect
              dir={dir}
              className={classes.filterSelect}
              data-test-id="restaurant-select"
              variant="outlined"
              value={restaurantFilter}
              onChange={() => {}}
            >
              {this.restaurantOptions.map((option) => (
                <CustomMenuItem dir={dir} value={option.value} key={option.id}>
                  {t(option.name)}
                </CustomMenuItem>
              ))}
            </CustomSelect>

            <CustomInputField
              data-test-id="search-transaction-id-input"
              className={classes.searchOrderId}
              inputRef={this.inputRef}
              variant="outlined"
              placeholder={t("Transaction ID")}
              InputProps={{
                endAdornment: (
                  <>
                    {transactionSearchValue && (
                      <IconButton
                        data-test-id="clear-search-order-id-btn"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.clearSearchTransactionId}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyDown={this.handleSearch}
            />
          </Box>

          <Box className={classes.filterActions}>
            <CustomButton
              variant="outlined"
              color="primary"
              className={classes.button}
							onClick={this.clearFilter}
							data-test-id="clear-button"
            >
              {t("Clear")}
            </CustomButton>

            <CustomButton
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t("Apply")}
            </CustomButton>
          </Box>
        </Box>
        
        <Tabs
          value={statusFilter}
					className={classes.tabs}
					data-test-id="tabs-test-id"
        >
          {this.tabList.map((tab, index) => {
            return (
							<Tab 
								value={tab.value} 
								label={
									<Box className={classes.tabLabel}>
										<CustomTypography className={classes.tabName}>
											{t(tab.name)}
										</CustomTypography>
										
										<Chip 
											className={clsx(classes.chip)}
											style={{backgroundColor: tab.color}}
											label={tab.number} 
										/>
									</Box>
								}
								data-test-id="tab-test-id"
								key={index}
								className={classes.tab}
								onClick={() => this.changeActiveTab(tab.value)}
							/>	
						);
          })}
        </Tabs>

				<Box className={classes.financeList}>
          <TableContainer>
            <CustomTable className={classes.financeTable}>
              <TableHead>
                <TableRow>
									<TableCell>{t("Transaction ID")}</TableCell>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Branch")}</TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Start Date")}</TableCell>
									<TableCell>{t("Due Date")}</TableCell>
									<TableCell>{t("Amount")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {financeData.length ? (
                  financeData.map((data, index) => (
                    <TableRow
                      key={index}
                      data-test-id={`table-row-${index}`}
                    >
                      <TableCell>{data.transactionId}</TableCell>
                      <TableCell>{data.restaurantName}</TableCell>
											<TableCell>{data.branch}</TableCell>
											<TableCell>{data.email}</TableCell>
											<TableCell>{data.startDate}</TableCell>
											<TableCell>{data.dueDate}</TableCell>
											<TableCell>{t("{{number}} SAR", { number: data.amount })}</TableCell>
											<TableCell>
												<AdminFinanceStatusTag
													status={data.status} 
												/>
											</TableCell>
											<TableCell>
                        <AdminPaymentFinanceMenu
                          data-test-id="menu"
                          onSelectAction={(mode: any) => this.openAdmiPaymentFinanceDialog(mode, data)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
							fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(_, page) => this.changePage(page)}
              showOffset
							data-test-id="pagination"
            />
          </Box>
        </Box>

				<AdminPaymentFinanceDetailFormDialog
          data-test-id="admin-finance-detail-dialog"
          dialog={adminPaymentFinanceDialog}
          onClose={this.closeAdmiPaymentFinanceDialog}
          changeAdminFinanceDetailDialogMode={
            this.changeCompensationDetailDialogMode
          }
        />

        <CustomLoadingBackdrop open={loading} />
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(AdminPaymentAdminFinance));
// Customizable Area End
