import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import CustomTypography from "./CustomTypography.web";
import { WithTranslation, withTranslation } from "react-i18next";

export enum KitchenMenuItemStatus {
  UnavailableTillNextOperations = "Unavailable_till_next_operations",
  Available = "Available",
  Unavailable = "Unavailable",
}

export interface KitchenItemStatusOption {
  id: string;
  name: string;
  value: KitchenMenuItemStatus;
}

export interface Props extends WithStyles, WithTranslation {
  value: string;

  onChange(value: KitchenMenuItemStatus): void;
}

const styles = createStyles({
  statusExpand: {
    marginLeft: "auto",
  },

  menu: {
    "& .MuiMenu-list": {
      padding: "0 !important",
    },
  },

  detailStatusItem: {
    padding: "8px 16px",
    textAlign: "initial",

    "&.rtl": {
      direction: "rtl",

      "&.is-selected": {
        borderRight: "2px solid #FF6666",
        borderLeft: 0,
      },
    },

    "&.is-selected": {
      backgroundColor: "rgba(255, 102, 102, 0.4)",
      borderLeft: "2px solid #FF6666",
      color: "#FFFFFF",
    },
  },

  detailStatusName: {},
});

export class KitchenMenuItemStatusMenu extends React.Component<
  Props,
  {
    statusMenu: HTMLElement | null;
    value: string;
  }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      statusMenu: null,
      value: this.props.value,
    };
  }

  menu = [
    {
      id: uuidv4(),
      name: "Available",
      value: KitchenMenuItemStatus.Available,
    },
    {
      id: uuidv4(),
      name: "Unavailable",
      value: KitchenMenuItemStatus.Unavailable,
    },

    {
      id: uuidv4(),
      name: "Unavailable till next operations",
      value: KitchenMenuItemStatus.UnavailableTillNextOperations,
    },
  ];

  openFilter = (event: any) => {
    this.setState({
      statusMenu: event.currentTarget,
    });
  };

  closeFilter = () => {
    this.setState({
      statusMenu: null,
    });
  };

  onChangeStatus = (value: KitchenMenuItemStatus) => {
    this.props.onChange(value);

    this.setState({
      value,
    });

    this.closeFilter();
  };

  render(): React.ReactNode {
    const { classes, t, i18n } = this.props;
    const { statusMenu, value } = this.state;
    const id = uuidv4();
    const rtl = i18n.language === "ar";

    return (
      <Box className={classes.detailStatus}>
        <Box
          id={`filter-button-${id}`}
          aria-controls={statusMenu ? `filter-menu-${id}` : undefined}
          aria-haspopup="true"
          aria-expanded={statusMenu ? "true" : undefined}
          onClick={this.openFilter}
        >
          <IconButton className={classes.catalogueMenuButton}>
            <MoreVert />
          </IconButton>
        </Box>

        <Menu
          className={classes.menu}
          id={`filter-menu-${id}`}
          anchorEl={statusMenu}
          open={Boolean(statusMenu)}
          onClose={this.closeFilter}
          MenuListProps={{
            className: classes.detailStatus,
            "aria-labelledby": `filter-button-${id}`,
          }}
        >
          {this.menu.map((filter) => {
            return (
              <MenuItem
                key={filter.id}
                className={clsx(classes.detailStatusItem, {
                  rtl,
                  ["is-selected"]: value === filter.value,
                })}
                onClick={() => this.onChangeStatus(filter.value)}
              >
                <CustomTypography className={classes.detailStatusName}>
                  {t(filter.name)}
                </CustomTypography>
              </MenuItem>
            );
          })}
        </Menu>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(KitchenMenuItemStatusMenu));
