import React from "react";
import { useTranslation } from "react-i18next";
import CustomAnalyticsAdminSalesRevenueChart from "./CustomAnalyticsAdminSalesRevenueChart.web";
import { Box, makeStyles } from "@material-ui/core";
import { icArrowDown } from "../../../blocks/analytics/src/assets";

type Props = {
  data: any[];
};

export default function CustomAnalyticsAdminOrdersComplainsChart({
  data,
}: Props) {
  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  const classes = useStyles();

  return (
    <>
      <Box dir={dir}>
        <h3 className={classes.title}>
          5 {t("Orders")} <img src={icArrowDown} width={13} height={13} />
        </h3>

        <p className={classes.subTitle}>
          {t("Orders With Customer Complains")}
        </p>
      </Box>
      <CustomAnalyticsAdminSalesRevenueChart
        data={data}
        dataKey="complainCount"
      />
    </>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: "#2E2E30",
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(30),
      fontWeight: 700,
      lineHeight: 36.31 / 30,
      marginBlock: 0,
    },
    subTitle: {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 700,
      lineHeight: 12.1 / 10,
      color: "#939393",
      marginBlock: 0,
      marginBlockEnd: 44,
    },
  };
});
