import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { ExpandMore, InfoOutlined } from "@material-ui/icons";
import CustomButton from "../CustomButton.web";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles {
  title: string;
  description: string;
  onDownload(): void;
}

const styles = (theme: Theme) => {
  return createStyles({
    row: {
      marginBlockStart: 10,
      borderRadius: 8,
      boxShadow: "initial",
      backgroundColor: "rgba(255, 251, 244, 1)",

      "&::before": {
        content: "''",
        opacity: 0,
      },
    },
    accordionSummary: {
      padding: "10px 12px",
      "& .title": {
        color: "rgba(0, 0, 0, 1)",
        fontFamily: "Inter",
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 400,
        lineHeight: 24 / 16,
        marginBlock: 0,
      },
    },
    accordionDetails: {
      "& .content": {
        backgroundColor: "#FFFFFF",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 12,
        gap: 10,
        "& > button": {
          whiteSpace: "nowrap",
          marginInlineStart: "auto",
          padding: 0,
        },
      },
      "& p": {
        margin: 0,
        fontSize: theme.typography.pxToRem(14),
        color: "rgba(51, 51, 51, 1)",
        lineHeight: 20 / 14,
        fontFamily: "Raleway",
      },
    },
  });
};

const CustomPaymentadmin2FinaceInvoiceItem = ({
  classes,
  title,
  description,
  onDownload,
}: Props) => {
  const { t, i18n } = useTranslation();

  const dir = i18n.language === "ar" ? "rtl" : "ltr";
  return (
    <Accordion className={classes.row} dir={dir}>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <h3 className={"title"}>{title}</h3>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <div className="content">
          <InfoOutlined />
          <p>{description}</p>
          <CustomButton variant="text" color="primary" onClick={onDownload}>
            {t("Download Invoice")}
          </CustomButton>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(CustomPaymentadmin2FinaceInvoiceItem);
