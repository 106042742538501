import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  AreaChart,
  Area,
  XAxis,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

type Props = {
  data: { time: string; value: number }[];
  unavailableTime: number;
};

export default function CustomAnalyticsAdminUnavailableTimeChart({
  data,
  unavailableTime,
}: Props) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  const classes = useStyles();

  if (rtl) {
    data.reverse();
  }

  return (
    <Box>
      <Box dir={dir}>
        <h3 className={classes.title}>
          {t("Unavailable Time")}
          <br />
          <span className={classes.hourText}>
            {t("{{hours}} hours", { hours: unavailableTime })}
          </span>
        </h3>
      </Box>
      <ResponsiveContainer maxHeight={200} className={classes.chartContainer}>
        <AreaChart
          width={600}
          height={200}
          data={data}
          margin={{ left: 10, right: 10 }}
        >
          <ReferenceLine x={"1h"} stroke="#B1B1B1" strokeWidth={0.5} />
          <ReferenceLine x={"5h"} stroke="#B1B1B1" strokeWidth={0.5} />
          <ReferenceLine x={"10h"} stroke="#B1B1B1" strokeWidth={0.5} />
          <ReferenceLine x={"15h"} stroke="#B1B1B1" strokeWidth={0.5} />
          <ReferenceLine x={"20h"} stroke="#B1B1B1" strokeWidth={0.5} />
          <ReferenceLine x={"24h"} stroke="#B1B1B1" strokeWidth={0.5} />
          <defs>
            <linearGradient
              id="bgGrad"
              x1="269.642"
              y1="25.8622"
              x2="268.57"
              y2="181.83"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F87171" />
              <stop offset="1" stop-color="#FFC8C8" />
            </linearGradient>
          </defs>
          <XAxis
            padding={"no-gap"}
            dataKey="time"
            interval={0}
            fontSize={0}
            axisLine={false}
          />
          <Area
            type="monotone"
            dataKey="value"
            stroke="#FF6666"
            fill="url(#bgGrad)"
            fillOpacity={"0.1"}
            activeDot={{ fill: "#FFFFFF", stroke: "#FF6666" }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    chartContainer: { marginBlockStart: 50 },
    title: {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      lineHeight: 16.94 / 14,
      textAlign: "center",
      color: "#2E2E30",
      marginBlock: 0,
    },
    hourText: {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 400,
      lineHeight: 12.1 / 10,
      color: "#2E2E30",
    },
  };
});
