export const customerReview = require("../assets/customer-review.svg");
export const cancelation = require("../assets/ic_cancelation.svg");
export const order = require("../assets/ic_order.svg");
export const restaurant = require("../assets/ic_restaurant.svg");
export const revenue = require("../assets/ic_revenue.svg");
export const user = require("../assets/ic_user.svg");
export const emptyData = require("../assets/ic_no_data.svg");
export const report = require("../assets/ic_report.svg");
export const home = require("../assets/ic_home.svg");
export const sortIncrease = require("../assets/ic_sort_increase.svg");
