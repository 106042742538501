import React from "react";
import { AccessTimeRounded } from "@material-ui/icons";
import CustomTypographyWeb from "./CustomTypography.web";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { styled } from "@material-ui/core";

const getTimeOfDayList = () => {
  let list: string[] = [];

  for (let i = 0; i <= 12; i++) {
    const date1 = `${i}:00`.padStart(5, "0");
    const date2 = `${i}:30`.padStart(5, "0");

    list.push(date1, date2);
  }

  return list;
};

type FormInputTimeListProps = {
  timeList: string[];
  onSelect: (time: string) => void;
};

function FormInputTimeList({ timeList, onSelect }: FormInputTimeListProps) {
  const { t } = useTranslation();

  return (
    <ul className="form__input__timeList">
      {timeList.map((time, index) => {
        return (
          <li key={index}>
            <span>{time}</span>
            <div>
              <span
                role="button"
                className="selected"
                onClick={onSelect.bind(undefined, time + " AM")}
              >
                {t("AM")}
              </span>{" "}
              <span
                role="button"
                onClick={onSelect.bind(undefined, time + " PM")}
              >
                {t("PM")}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

type Props = {
  labelFrom: string;
  labelFromOutside?: string;
  labelTo: string;
  labelToOutside?: string;
  formikKeyFrom: any;
  formikKeyTo: any;
};

export default function CustomSelectTime({
  labelFrom,
  labelTo,
  formikKeyFrom,
  formikKeyTo,
  labelFromOutside,
  labelToOutside,
}: Props) {
  const { errors, touched, setFieldValue } = useFormikContext<any>();

  return (
    <Container>
      <div>
        {labelFromOutside && (
          <CustomTypographyWeb className="form__inputLabel">
            {labelFromOutside}
          </CustomTypographyWeb>
        )}
        <div className="form__input__time">
          <CustomTypographyWeb>{labelFrom}</CustomTypographyWeb>
          <AccessTimeRounded />
          <FormInputTimeList
            timeList={getTimeOfDayList()}
            onSelect={(time) => {
              setFieldValue(formikKeyFrom, time);
            }}
          />
        </div>
        {touched[formikKeyFrom] && errors[formikKeyFrom] && (
          <CustomTypographyWeb className="form__input__error">
            {errors[formikKeyFrom]}
          </CustomTypographyWeb>
        )}
      </div>

      <div>
        {labelToOutside && (
          <CustomTypographyWeb className="form__inputLabel">
            {labelToOutside}
          </CustomTypographyWeb>
        )}
        <div className="form__input__time">
          <CustomTypographyWeb>{labelTo}</CustomTypographyWeb>
          <AccessTimeRounded />
          <FormInputTimeList
            timeList={getTimeOfDayList()}
            onSelect={(time) => {
              setFieldValue(formikKeyTo, time);
            }}
          />
        </div>
        {touched[formikKeyTo] && errors[formikKeyTo] && (
          <CustomTypographyWeb className="form__input__error">
            {errors[formikKeyTo]}
          </CustomTypographyWeb>
        )}
      </div>
    </Container>
  );
}

const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: 10,
    "& .form__inputLabel": {
      fontWeight: 700,
      lineHeight: 22 / 14,
    },
    "& div:has(.form__input__time)": {
      flex: 1,
    },
    "& div:has(.form__input__error) .form__input__time": {
      borderColor: "#DC2626",
    },
    "& .form__input__time": {
      padding: "10px 8px",
      border: "1px solid #CBD5E1",
      borderRadius: 8,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "relative",
      "&:hover .form__input__timeList": {
        display: "block",
      },
      "& p": {
        fontFamily: "Inter",
      },
    },
    "& .form__input__timeList": {
      display: "none",
      position: "absolute",
      backgroundColor: "#fff",
      top: "100%",
      left: 0,
      right: 0,
      height: 135,
      overflow: "auto",
      zIndex: 1,
      borderRadius: "0px 0px 8px 8px",
      border: "inherit",
      boderTopWidth: 0,
      marginBlock: 0,
      listStyle: "none",
      padding: 12,
      "& li": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "&:hover > span": {
          color: "#F66",
          fontWeight: 700,
        },
        "&  span": {
          fontFamily: "Inter",
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 22 / 16,
          transition: "all 200ms ease",
        },
        "& > div": {
          display: "none",
          "& > [role='button']": {
            cursor: "pointer",
            transition: "all 200ms ease",
            "&.selected": {
              color: "#F66",
              fontWeight: 700,
            },
            "&:hover": {
              color: "#FF6666d8",
              fontWeight: 700,
            },
          },
        },
        "&:hover > div": {
          display: "block",
        },
      },
    },
    "& .form__input__error": {
      marginBlockStart: 5,
      color: "#DC2626",
      fontSize: theme.typography.pxToRem(12),
      fontFamily: "Inter",
      lineHeight: 18 / 12,
    },
  };
});
