import {
  FormHelperText,
  Select,
  SelectProps,
  withStyles,
  WithStyles,
  createStyles,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { ExpandMoreRounded } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";

export interface Props extends WithStyles<any> {
  helperText?: string;
  className?: string;
  title: string;
}

export type CustomSelectProps = Props & SelectProps;

const styles = createStyles({
  customInputField: {
    "& .MuiInputBase-input": {
      minHeight: 25,
      height: 'auto'
    },

    "& .MuiOutlinedInput-input": {
      paddingBlock: "12px",
      paddingInlineStart: "8px",
      paddingInlineEnd: "32px",
      fontSize: 14,
      fontFamily: "Raleway",
      display: "flex",
      alignItems: "center",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 8,
      borderColor: "#CBD5E1",
    },

    "& .Mui-error": {
      borderColor: "#F87171",
    },

    "& .MuiInputAdornment-positionEnd": {
      "& .MuiIconButton-root": {
        "& .MuiSvgIcon-root": {
          color: "#FF6666",
        },
      },
    },

    "&[dir='rtl'] .MuiSvgIcon-root.MuiSelect-icon": {
      left: 7,
      right: "unset",
    },

    "& .MuiListItemText-root": {
      marginTop: 1,
      marginBottom: 1,
      "& .MuiTypography-body1": { lineHeight: 1 },
    },

    "& .MuiTypography-root": {
      paddingTop: 10,
      paddingLeft: 4,
      fontWeight: 700
    },
  },

  formControl: {
    "& .MuiInputLabel-filled": {
      fontSize: 13,
      fontFamily: 'Raleway',
    },
  },
});

export class CustomSelectWithLabel extends React.Component<CustomSelectProps> {
  constructor(props: CustomSelectProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { title, children, helperText, classes, className, dir, ...rest } = this.props;

    return (
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel>{title}</InputLabel>

        <Select
          className={clsx(classes.customInputField, className)}
          {...rest}
          IconComponent={ExpandMoreRounded}
        >
          {children}
        </Select>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

export default withStyles(styles)(CustomSelectWithLabel);
