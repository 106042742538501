import {
  WithStyles,
  createStyles,
  withStyles,
  Box,
  Theme,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import { WithTranslation, withTranslation } from "react-i18next";

export interface AdminFinanceStatistics {
  name: string;
  value: number | string;
  icon: string;
  subValue: number | string;
}

export interface Props extends WithStyles, WithTranslation {
  statistics: AdminFinanceStatistics;
  isMoney: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid #0000000A",
      borderRadius: 4,
      padding: 16,
      background: "#F8F8F8",
      width: "100%",
      minWidth: 196,
      height: 90,
      position: "relative",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
    },

    icon: {
      padding: 5,
      borderRadius: "50%",
      width: 44,
      height: 44,
    },

    iconInner: {
      display: "flex",
    },

    image: {
      width: 25,
      height: 25,
      objectFit: "cover",
    },

    inner: {
      marginTop: "auto",
      marginBottom: 20,
    },

    name: {
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 10,
    },

    number: {
      fontSize: "24px !important",
      marginTop: 10,
      fontWeight: 700,
      fontFamily: "Inter !important",
    },

    avenue: {
      marginTop: 6,
      marginLeft: 10,
      marginRight: 10,
      display: "flex",
    },

    numberAvenue: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      fontWeight: 500,
      lineHeight: "24px !important",
      color: "#059669 !important",
      marginLeft: 5,
    },
  });

export class AdminFinanceStatisticsCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, statistics, isMoney, t } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.inner}>
          <CustomTypography className={classes.name}>
            {statistics.name}
          </CustomTypography>

          <Box className={classes.iconInner}>
            <CustomTypography className={classes.number}>
              {isMoney
                ? t("SAR") + " " + statistics.value.toLocaleString()
                : statistics.value.toLocaleString()}
            </CustomTypography>

            <Box className={classes.avenue}>
              <img
                className={classes.image}
                src={statistics.icon}
                alt={statistics.name}
              />

              <CustomTypography className={classes.numberAvenue}>
                {statistics.subValue}
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminFinanceStatisticsCard)
);
