import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";

export enum AdminBranchManagementStatus {
  Open = "open",
  Closed = "closed",
}

export interface Props extends WithStyles, WithTranslation {
  status: AdminBranchManagementStatus;
}

const styles = (theme: Theme) =>
  createStyles({
    name: {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "&.rtl": {
        direction: "rtl",
      },

      "&.open": {
        color: "#48CD49",

        "&::before": {
          border: "2px solid #48CD4933",
          backgroundColor: "#48CD49",
        },
      },

      "&.closed": {
        color: "#FF6666",

        "&::before": {
          border: "2px solid #FF666633",
          backgroundColor: "#FF6666",
        },
      },

      "&::before": {
        width: 8,
        height: 8,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        boxSizing: "border-box",
      },
    },
  });

export class AdminBranchManagementStatusTag extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, status, t, i18n } = this.props;
    const rtl = i18n.language === "ar";

    const statusData = {
      [AdminBranchManagementStatus.Open]: "Open",
      [AdminBranchManagementStatus.Closed]: "Closed",
    };

    return (
      <Box
        className={clsx(classes.name, status, {
          ["rtl"]: rtl,
        })}
      >
        {t(statusData[status])}
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminBranchManagementStatusTag)
);
