import React from "react";

import { styled } from "@material-ui/core/styles";

import CustomTypography from "../CustomTypography.web";

import { DeleteOutline, MoreVert } from "@material-ui/icons";

import { IconButton, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { StyledMenu } from "../servicesspecificsettingsadmin2/RestaurantsTableActionMenu.web";

type Props = {
  onView: () => void;
  onDelete: () => void;
};

export default function CustomReviewsTableMenu({ onView, onDelete }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  return (
    <>
      <IconButton
        aria-controls="ReviewsTableActionMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="ReviewsTableActionMenu"
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={() => {
            onView();
            handleClose();
          }}
          dir={dir}
        >
          <IconEye />

          <CustomTypography>{t("View Detail")}</CustomTypography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete();
            handleClose();
          }}
          dir={dir}
        >
          <DeleteOutline htmlColor="#DC2626" fontSize="small" />
          <CustomTypography style={{ color: "#DC2626" }}>
            {t("Delete")}
          </CustomTypography>
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const IconEye = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 6.75C7.75733 6.75 6.75 7.75733 6.75 9C6.75 10.2427 7.75733 11.25 9 11.25C10.2427 11.25 11.25 10.2427 11.25 9C11.25 7.75733 10.2427 6.75 9 6.75ZM8.25 9C8.25 8.58577 8.58577 8.25 9 8.25C9.41423 8.25 9.75 8.58577 9.75 9C9.75 9.41423 9.41423 9.75 9 9.75C8.58577 9.75 8.25 9.41423 8.25 9Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3725 8.46052C14.6565 5.36389 11.8591 3.75 9 3.75C6.14083 3.75 3.34347 5.36389 1.62752 8.46052C1.45978 8.7633 1.45771 9.13657 1.62022 9.4407C3.30887 12.601 6.1221 14.25 9 14.25C11.8779 14.25 14.6911 12.601 16.3798 9.4407C16.5423 9.13657 16.5402 8.7633 16.3725 8.46052ZM9 12.75C6.79573 12.75 4.53039 11.5293 3.0688 8.96152C4.55147 6.4471 6.80489 5.25 9 5.25C11.1951 5.25 13.4485 6.4471 14.9312 8.96152C13.4696 11.5293 11.2042 12.75 9 12.75Z"
      fill="black"
    />
  </svg>
);
