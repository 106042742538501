import React from "react";

import { styled } from "@material-ui/core/styles";

import CustomTypography from "../CustomTypography.web";

import { DeleteOutline, EditOutlined, MoreVert } from "@material-ui/icons";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
  onEdit: () => void;
};

export default function RestaurantsTableActionMenu({ onEdit }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  return (
    <>
      <IconButton
        aria-controls="RestaurantsTableActionMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="RestaurantsTableActionMenu"
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            handleClose();
          }}
          dir={dir}
        >
          <EditOutlined />
          <CustomTypography>{t("Edit")}</CustomTypography>
        </MenuItem>
        <MenuItem onClick={handleClose} dir={dir}>
          <DeleteOutline htmlColor="#DC2626" />
          <CustomTypography style={{ color: "#DC2626" }}>
            {t("Delete")}
          </CustomTypography>
        </MenuItem>
      </StyledMenu>
    </>
  );
}

export const StyledMenu = styled(Menu)({
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded": {
    paddingBlock: 0,
    width: 184,
    borderRadius: 8,
  },
  "& .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiListItem-button":
    {
      gap: 8,
      padding: "9px 16px",
    },
});
