import React, { Component } from "react";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Box, createStyles } from "@material-ui/core";
import CustomTypography from "./CustomTypography.web";
import clsx from "clsx";
import { Add, Remove } from "@material-ui/icons";

export interface CustomFilterTagProps extends WithStyles {
  name: string;
  active: boolean;
  value: string;
  hideIcon?: boolean;

  onSelect(value: string): void;
}

const styles = createStyles({
  container: {
    padding: "8px 10px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFDD99",
    borderRadius: 26,
    cursor: "pointer",
    direction: "initial",

    "&.active": {
      backgroundColor: "#FFB946",
    },
  },

  name: {
    fontSize: "16px !important",
    fontWeight: 600,
  },
});

class CustomFilterTag extends Component<CustomFilterTagProps> {
  constructor(props: CustomFilterTagProps) {
    super(props);
  }

  render() {
    const { classes, name, active, value, hideIcon, onSelect } = this.props;

    return (
      <Box
        className={clsx(classes.container, { active })}
        onClick={() => onSelect(value)}
      >
        <CustomTypography className={classes.name}>{name}</CustomTypography>

        {!hideIcon && (active ? <Remove /> : <Add />)}
      </Box>
    );
  }
}

export default withStyles(styles)(CustomFilterTag);
