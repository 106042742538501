import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomCheckboxInput from "./CustomCheckboxInput.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import CustomTypography from "./CustomTypography.web";
import CustomButton from "./CustomButton.web";
import { WithTranslation, withTranslation } from "react-i18next";
import clsx from "clsx";

export enum KitchenAccountManagementDetailFormMode {
  Add = "add",
  Edit = "edit",
  View = "view",
}

export interface KitchenAccountManagementDetailFormProps {
  mode: KitchenAccountManagementDetailFormMode;
  loading: boolean;

  options: {
    id: string | number;
    name: string;
    value: string | number;
  }[];

  values: {
    name: string;
    email: string;
    role: string;
    accessibleBranches: (string | number)[];
  };

  accordionName: string;

  accordionData: {
    id: string | number;
    name: string;
    branchName: string;
  }[];
}

export interface Props extends WithStyles, WithTranslation {
  form: KitchenAccountManagementDetailFormProps;

  onCancel(): void;
  onSwitchMode(mode: KitchenAccountManagementDetailFormMode): void;
  editDetail(values: {
    name: string;
    email: string;
    role: string;
    accessibleBranches: (string | number)[];
  }): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "&.rtl": {
        direction: "rtl",
      },
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },

    formNameAndEmail: {
      display: "flex",
      width: "100%",
      gap: 24,
      marginTop: 12,

      "& > div": {
        margin: "0 !important",
        flex: 1,
      },
    },

    formRole: {
      marginTop: 24,
    },

    formDescription: {
      marginTop: 4,
      lineHeight: "24px !important",
    },

    formRoleSelect: {
      marginTop: 12,
    },

    formAccess: {
      marginTop: 24,
    },

    formAccessAccordion: {
      marginTop: 12,
    },

    formAccessAccordionItem: {
      border: "1px solid #EDEDED",
      borderRadius: "8px !important",

      "&.MuiAccordion-root": {
        boxShadow: "none !important",
      },
    },

    formAccessAccordionItemSummary: {
      padding: 16,
      paddingRight: 8,
      minHeight: "auto !important",

      "& .MuiAccordionSummary-content": {
        margin: "0 !important",
      },

      "& .MuiAccordionSummary-expandIcon": {
        padding: 4,
        marginRight: "0 !important",
      },
    },

    formAccessAccordionItemDetail: {
      padding: 16,
      paddingRight: 8,
      paddingTop: 0,
    },

    formAccessAccordionItemContent: {
      padding: 14,
      backgroundColor: "#FFFBF4",
      borderRadius: 8,
      maxHeight: 278,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },

    formAccessCheckboxLabel: {
      display: "flex",
      flexDirection: "column",
    },

    formAccessCheckboxLabelNumber: {
      display: "inline-block !important",
      fontFamily: "Inter !important",
    },

    formAccessCheckboxLabelSub: {
      display: "inline-block !important",
      fontSize: "12px !important",
    },

    formButton: {
      display: "flex",
      gap: 12,
      marginTop: 20,
      justifyContent: "flex-end",
    },

    formAccessCheckbox: {
      "& + &": {
        marginTop: 24,
      },
    },

    formAccessCheckboxLabelMain: {
      fontSize: "16px !important",
    },
  });

export class KitchenAccountManagementDetailForm extends React.Component<Props> {
  private formProps: FormikProps<{
    name: string;
    email: string;
    role: string;
    accessibleBranches: (string | number)[];
  }> | null = null;

  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      form,
      t,
      i18n,

      editDetail,
      onSwitchMode,
      onCancel,
    } = this.props;

    const { loading, options, mode, values, accordionData, accordionName } =
      form;

    const disabledEdit =
      mode === KitchenAccountManagementDetailFormMode.View || loading;

    const handleChangeAccessibleBranches = (id: string) => {
      if (!this.formProps) {
        return;
      }

      const accessibleBranches = [...this.formProps.values.accessibleBranches];

      const index = accessibleBranches?.findIndex((branch) => branch === id);

      if (index < 0) {
        accessibleBranches.push(id);
      } else {
        accessibleBranches.splice(index, 1);
      }

      this.formProps.setFieldValue("accessibleBranches", accessibleBranches);
    };

    const validationSchema = Yup.object({
      name: Yup.string().nullable().required(t("Please enter name")),
      email: Yup.string()
        .nullable()
        .required(t("Please enter email"))
        .email(t("Please enter valid email")),
      role: Yup.string().nullable().required(t("Please select role")),
    });

    const rtl = i18n.language === "ar";

    return (
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={editDetail}
        data-test-id="kitchen-management-detail-formik"
      >
        {(props) => {
          this.formProps = props;

          return (
            <Form
              data-test-id="kitchen-management-detail-form"
              className={clsx(classes.form, { ["rtl"]: rtl })}
              onSubmit={props.handleSubmit}
            >
              <Box className={classes.formInner}>
                <CustomTypography>
                  {t("Please enter the basic details for this user")}
                </CustomTypography>

                <Box className={classes.formNameAndEmail}>
                  <CustomFormInputField
                    disabled={disabledEdit}
                    fullWidth
                    name="name"
                    placeholder={t("Enter name")}
                    variant="outlined"
                    error={props.touched.name && !!props.errors.name}
                    data-test-id="name-input"
                  />

                  <CustomFormInputField
                    disabled={disabledEdit}
                    fullWidth
                    name="email"
                    placeholder={t("Enter email")}
                    variant="outlined"
                    error={props.touched.email && !!props.errors.email}
                    data-test-id="email-input"
                  />
                </Box>

                <Box className={classes.formRole}>
                  <CustomTypography variant="subtitle2">
                    {t("User’s Role")}
                  </CustomTypography>

                  <CustomTypography className={classes.formDescription}>
                    {t("Please assign the role for this user")}
                  </CustomTypography>

                  <Box className={classes.formRoleSelect}>
                    <CustomFormSelectField
                      dir={rtl ? "rtl" : ""}
                      disabled={disabledEdit}
                      fullWidth
                      name="role"
                      label={t("Select a role")}
                      variant="outlined"
                      error={props.touched.role && !!props.errors.role}
                      data-testt-id="role-select"
                      displayEmpty
                      inputProps={{
                        renderValue: (selected: string) => {
                          if (!selected) {
                            return t("Select a role");
                          }

                          const selectedOption = options.find(
                            (option) => option.value === selected
                          );

                          if (!selectedOption) {
                            return "";
                          }

                          return selectedOption.name;
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </CustomFormSelectField>
                  </Box>
                </Box>

                <Box className={classes.formAccess}>
                  <CustomTypography variant="subtitle2">
                    {t("Assigning user’s access")}
                  </CustomTypography>

                  <CustomTypography className={classes.formDescription}>
                    {t(
                      "Please select where would you like to give the users the access."
                    )}
                  </CustomTypography>

                  {accordionName && (
                    <Box className={classes.formAccessAccordion}>
                      <Accordion
                        className={classes.formAccessAccordionItem}
                        expanded
                      >
                        <AccordionSummary
                          className={classes.formAccessAccordionItemSummary}
                          expandIcon={<ExpandMore />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <CustomTypography variant="subtitle2">
                            {accordionName}
                          </CustomTypography>
                        </AccordionSummary>

                        <AccordionDetails
                          className={classes.formAccessAccordionItemDetail}
                        >
                          <Box
                            className={classes.formAccessAccordionItemContent}
                          >
                            {accordionData.map((data) => (
                              <Box
                                key={data.id}
                                className={classes.formAccessCheckbox}
                              >
                                <CustomCheckboxInput
                                  color="primary"
                                  checked={props.values.accessibleBranches.includes(
                                    data.id.toString()
                                  )}
                                  disabled={disabledEdit}
                                  value={data.id}
                                  onChange={(e) => {
                                    handleChangeAccessibleBranches(
                                      e.target.value
                                    );
                                  }}
                                  checkboxLabel={
                                    <span
                                      className={
                                        classes.formAccessCheckboxLabel
                                      }
                                    >
                                      <CustomTypography
                                        component="span"
                                        className={
                                          classes.formAccessCheckboxLabelMain
                                        }
                                      >
                                        {data.name}
                                      </CustomTypography>
                                      <CustomTypography
                                        component="span"
                                        className={
                                          classes.formAccessCheckboxLabelSub
                                        }
                                      >
                                        {data.branchName}
                                      </CustomTypography>
                                    </span>
                                  }
                                />
                              </Box>
                            ))}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box className={classes.formButton}>
                <CustomButton
                  variant="contained"
                  color="default"
                  onClick={onCancel}
                >
                  {t("Cancel")}
                </CustomButton>

                {(() => {
                  switch (mode) {
                    case KitchenAccountManagementDetailFormMode.Edit:
                      return (
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {t("Save changes")}
                        </CustomButton>
                      );

                    case KitchenAccountManagementDetailFormMode.View:
                      return (
                        <CustomButton
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            onSwitchMode(
                              KitchenAccountManagementDetailFormMode.Edit
                            );
                          }}
                        >
                          {t("Edit")}
                        </CustomButton>
                      );

                    default:
                      break;
                  }
                })()}
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(KitchenAccountManagementDetailForm)
);
