// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import LoyaltySystemCompensationsController, {
  Props,
} from "./LoyaltySystemCompensationsController.web";
import {
  Portal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { AddCircleOutlineRounded } from "@material-ui/icons";

import CustomPagination from "../../../components/src/CustomPagination";
import CustomTable from "../../../components/src/CustomTable";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomDatePickerWeb from "../../../components/src/CustomDatePicker.web";
import CustomInputFieldWeb from "../../../components/src/CustomInputField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { iconCalendar, iconSend } from "./assets";

import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItemWeb from "../../../components/src/CustomMenuItem.web";
import CustomLoyaltySystemFormDrawer from "../../../components/src/loyaltysystem/CustomLoyaltySystemFormDrawer.web";
import CustomLoyaltySystemAddDrawerWeb from "../../../components/src/loyaltysystem/CustomLoyaltySystemAddDrawer.web";
import CustomLoyaltySystemTableDropdownWeb from "../../../components/src/loyaltysystem/CustomLoyaltySystemTableDropdown.web";
import { withTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      display: "flex",
      gap: 15,
      alignItems: "center",

      "& .react-datepicker-wrapper": {
        maxWidth: 150,
      },

      [theme.breakpoints.down("xs")]: {
        flexWrap: "wrap",
      },

      "& .MuiBox-root:has(#selectCategory)": {
        maxWidth: 233,
        width: "100%",
      },

      "& .actions__clear": {
        backgroundColor: "rgba(51, 51, 51, 1)",
      },

      "& .actions__add": {
        marginInlineStart: "auto",
        minWidth: 136,
      },

      "& .actions__emailReport[dir='rtl']": {
        maxWidth: 150,

        "& .actions__emailReport__text": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },

      "& .actions__dateInput": {
        "& input::placeholder": {
          color: "#333",
          transition: "none",
          opacity: 1,
        },
      },
    },

    ordersData: {
      marginBlockStart: 24,
    },

    orderTable: {
      "& .MuiTableCell-root": {
        verticalAlign: "baseline",
      },
      "& .font-inter": {
        fontFamily: "Inter",
      },
      "& .orderTable__posId": {
        whiteSpace: "nowrap",
      },
      "& .orderTable__compensationReason": {
        width: 140,
      },
      "& .table__cell_empty": {
        textAlign: "center",
        lineHeight: 4,

        [theme.breakpoints.down("md")]: {
          textAlign: "left",
        },
      },
      "& .orderTable__createdAt": {
        whiteSpace: "pre",
      },
    },

    pagination: {
      display: "flex",
      justifyContent: "end",
      marginTop: 24,
    },
  });

export class LoyaltySystemCompensations extends LoyaltySystemCompensationsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const {
      orders,
      filterCategory,
      filterEndDate,
      filterStartDate,
      pagination,
      snackbar,
      loadingUpdate,
      filterCategoryList,
      openForm,
      openFormAdd,
      formType,
      listBranch,
      listOrderNumber,
      listCompensationReason,
      loadingCreate,
    } = this.state;

    return (
      <>
        <CustomLoadingBackdropWeb open={this.shouldLoadingBackdropShow()} />
        <div id="portalStartDate"></div>
        <div id="portalEndDate"></div>

        <div className={classes.actions} dir={dir}>
          <CustomDatePickerWeb
            onChange={this.changeStartDate}
            name="from"
            selected={this.getSelectedDate(filterStartDate)}
            dateFormat={"dd/MM/yyyy"}
            portalId="portalStartDate"
            locale={i18n.language}
            customInput={
              <CustomInputFieldWeb
                variant="outlined"
                fullWidth
                InputProps={{
                  placeholder: t("Start Date"),
                  endAdornment: <img src={iconCalendar} />,
                  className: "actions__dateInput",
                  dir: dir,
                }}
              />
            }
          />

          <CustomDatePickerWeb
            onChange={this.changeEndDate}
            name="to"
            selected={this.getSelectedDate(filterEndDate)}
            dateFormat={"dd/MM/yyyy"}
            portalId="portalEndDate"
            minDate={this.getSelectedDate(filterStartDate)}
            locale={i18n.language}
            customInput={
              <CustomInputFieldWeb
                variant="outlined"
                fullWidth
                InputProps={{
                  className: "actions__dateInput",
                  placeholder: t("End Date"),
                  endAdornment: <img src={iconCalendar} />,
                  dir: dir,
                }}
              />
            }
          />
          <CustomSelect
            id="selectCategory"
            data-test-id="select-category"
            variant="outlined"
            fullWidth
            value={filterCategory}
            onChange={(event) =>
              this.changeFilterCategory(event.target.value as string)
            }
            dir={dir}
            inputProps={{ dir: dir }}
          >
            <CustomMenuItemWeb value={"-1"} dir={dir}>
              {t("All Categories")}
            </CustomMenuItemWeb>
            {filterCategoryList.map((option) => (
              <CustomMenuItemWeb
                className={classes.languageMenuItem}
                value={option.value}
                key={option.id}
                dir={dir}
              >
                {option.name}
              </CustomMenuItemWeb>
            ))}
          </CustomSelect>

          {rtl ? (
            <>
              <CustomButton
                variant="contained"
                color="primary"
                className="actions__clear"
                data-testid="btnClear"
                onClick={this.resetFilter}
                dir={dir}
              >
                {t("Clear")}
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                data-testid="btnApply"
                onClick={this.applyFilter}
                dir={dir}
              >
                {t("Apply")}
              </CustomButton>
            </>
          ) : (
            <>
              <CustomButton
                variant="contained"
                color="primary"
                data-testid="btnApply"
                onClick={this.applyFilter}
                dir={dir}
              >
                {t("Apply")}
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                className="actions__clear"
                data-testid="btnClear"
                onClick={this.resetFilter}
                dir={dir}
              >
                {t("Clear")}
              </CustomButton>
            </>
          )}
          <CustomButton
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineRounded />}
            onClick={this.openFormAdd}
            className="actions__add"
            dir={dir}
          >
            {t("Add")}
          </CustomButton>
          <CustomButton
            variant="contained"
            color="primary"
            startIcon={<img src={iconSend} />}
            onClick={this.createEmailReport}
            data-testid="btnEmailReport"
            className="actions__emailReport"
            dir={dir}
          >
            <span className="actions__emailReport__text">
              {t("Email Report")}
            </span>
          </CustomButton>
        </div>

        <div className={classes.ordersData}>
          <TableContainer>
            <CustomTable className={classes.orderTable} dir={dir}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Order ID")}</TableCell>
                  <TableCell className="orderTable__compensationReason">
                    {t("Compensation Reason")}
                  </TableCell>
                  <TableCell>{t("Item Details")}</TableCell>
                  <TableCell>
                    {t("Amount")}
                    <br />
                    <span>({t("SAR")})</span>
                  </TableCell>
                  <TableCell>{t("Category")}</TableCell>
                  <TableCell>{t("Notes")}</TableCell>
                  <TableCell>{t("Created At")}</TableCell>
                  <TableCell className="orderTable__posId">
                    {t("POS ID")}
                  </TableCell>
                  <TableCell>{t("Branch")}</TableCell>
                  <TableCell>{t("Points")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {orders.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={11} className="table__cell_empty">
                      {t("No compensation")}
                    </TableCell>
                  </TableRow>
                )}
                {orders.map((order) => {
                  return (
                    <TableRow key={order.id}>
                      <TableCell className="font-inter">
                        {order.attributes.order_id}
                      </TableCell>
                      <TableCell className="orderTable__compensationReason">
                        {order.attributes.compensation_reason}
                      </TableCell>
                      <TableCell>
                        <ul>
                          {order.attributes.order.data.attributes.order_items.map(
                            (item) => {
                              return (
                                <li key={item.id}>
                                  {item.attributes.catalogue.attributes.name} (
                                  {item.attributes.quantity})
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </TableCell>
                      <TableCell className="font-inter">
                        {order.attributes.compensation_amount}
                      </TableCell>
                      <TableCell>
                        {order.attributes.compensation_category}
                      </TableCell>
                      <TableCell>{order.attributes.notes}</TableCell>
                      <TableCell className="font-inter orderTable__createdAt">
                        {this.formatCreatedAt(order.attributes.created_at)}
                      </TableCell>
                      <TableCell className="orderTable__posId">
                        {order.attributes.pos_id}
                      </TableCell>
                      <TableCell>{order.attributes.store_name}</TableCell>
                      <TableCell className="font-inter">
                        {order.attributes.points}
                      </TableCell>
                      <TableCell>
                        <CustomLoyaltySystemTableDropdownWeb
                          data-testid="tableAction"
                          order={order}
                          onDropDownClick={this.handleDropdownClick}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <div className={classes.pagination} dir={dir}>
            <CustomPagination
              data-testid="pagination"
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </div>
        </div>

        {/* Portal helps snackbar won't be overlaid by backdrop */}

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>

        <CustomLoyaltySystemFormDrawer
          data-testid="formDrawer"
          loading={loadingUpdate}
          onClose={this.closeForm}
          onSubmit={this.handleUpdateSubmit}
          open={openForm}
          type={formType}
        />

        <CustomLoyaltySystemAddDrawerWeb
          data-testid="formDrawerAdd"
          loading={loadingCreate}
          listBranch={listBranch}
          listCompensationReason={listCompensationReason}
          listOrderNumber={listOrderNumber}
          onClose={this.closeFormAdd}
          onSubmit={this.createOrder}
          open={openFormAdd}
        />
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(LoyaltySystemCompensations)
);
// Customizable Area End
