import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

type CustomAnalyticsAdminCustomersCardProps = {
  headText: string;
  headNumber: string;
  headPercent: string;
  actionText: string;
  actionButtonText: string;
};

function CustomAnalyticsAdminCustomersCard({
  headText,
  headNumber,
  headPercent,
  actionText,
  actionButtonText,
}: CustomAnalyticsAdminCustomersCardProps) {
  const { i18n } = useTranslation();

  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  return (
    <div>
      <div className="reports__card__head" dir={dir}>
        <Box width={15} height={15} bgcolor={"#FF6666"} borderRadius={"50%"} />
        <div>
          <span className="reports__card__headText">{headText}</span>
          <Box display={"flex"} dir={dir} gridColumnGap={"1ch"}>
            <span className="reports__card__headNumber">{headNumber}</span>
            <span className="reports__card__headPercent">
              {headPercent}&rlm;%
            </span>
          </Box>
        </div>
      </div>

      <Box className="reports__card__action" dir={dir}>
        <span className="reports__card__actionText">{actionText}</span>
        <ButtonBase>{actionButtonText}</ButtonBase>
      </Box>
    </div>
  );
}

export default CustomAnalyticsAdminCustomersCard;
