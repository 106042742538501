import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomDialog from "./CustomDialog.web";
import CustomTypography from "./CustomTypography.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import CustomFilterTag from "./CustomFilterTag.web";

export interface Props extends WithStyles, WithTranslation {
  dialog: KitchenMenuAddFilterDialogProps;
  preferences: string[];
  sortByPrice: string;

  onClose(): void;
  addFilter(preferences: string[], sortByPrice: string): void;
}

export interface KitchenMenuAddFilterDialogProps {
  open: boolean;
}

interface S {
  preferences: string[];
  sortByPrice: string;
}

const styles = (theme: Theme) =>
  createStyles({
    title: {
      fontSize: "18px !important",
      lineHeight: "26px !important",
    },

    inner: {
      display: "flex",
      flexWrap: "wrap",
      gap: 16,
      marginTop: 8,
    },

    actions: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },

    field: {
      "& + &": {
        marginTop: 40,
      },
    },
  });

export class KitchenMenuAddFilterDialog extends React.Component<Props, S> {
  constructor(props: Props) {
    super(props);

    this.state = {
      preferences: this.props.preferences,
      sortByPrice: this.props.sortByPrice,
    };
  }

  selectSortByPrice = (value: string) => {
    if (this.state.sortByPrice === value) {
      this.setState({
        sortByPrice: "",
      });
    } else {
      this.setState({
        sortByPrice: value,
      });
    }
  };

  selectPreferenceFilters = (value: string) => {
    const preferences = [...this.state.preferences];

    const index = preferences.findIndex((filter) => filter === value);

    if (index < 0) {
      preferences.push(value);
    } else {
      preferences.splice(index, 1);
    }

    this.setState({
      preferences,
    });
  };

  submitFilter = () => {
    this.props.addFilter(this.state.preferences, this.state.sortByPrice);
  };

  render(): React.ReactNode {
    const { classes, dialog, t, i18n, onClose } = this.props;

    const { open } = dialog;

    const { preferences, sortByPrice } = this.state;

    const rtl = i18n.language === "ar";

    const preferenceFilters = [
      {
        id: uuidv4(),
        name: t("VEG"),
        value: "veg",
      },

      {
        id: uuidv4(),
        name: t("NON-VEG"),
        value: "non_veg",
      },

      {
        id: uuidv4(),
        name: t("EGG"),
        value: "egg",
      },
    ];

    const priceVariantFilters = [
      {
        id: uuidv4(),
        name: t("Price: High to Low"),
        value: "desc",
      },

      {
        id: uuidv4(),
        name: t("Price: Low to High"),
        value: "asc",
      },
    ];

    return (
      <CustomDialog
        dir={rtl ? "rtl" : ""}
        width={600}
        open={open}
        title={t("Add Filter")}
        onClose={onClose}
        showContentDivider
        content={
          <Box className={classes.content}>
            <Box className={classes.field}>
              <CustomTypography className={classes.title}>
                {t("Preference")}
              </CustomTypography>

              <Box className={classes.inner}>
                {preferenceFilters.map((filter) => (
                  <CustomFilterTag
                    hideIcon
                    key={filter.id}
                    name={filter.name}
                    value={filter.value}
                    active={preferences.includes(filter.value)}
                    onSelect={this.selectPreferenceFilters}
                  />
                ))}
              </Box>
            </Box>

            <Box className={classes.field}>
              <CustomTypography className={classes.title}>
                {t("Price Variant")}
              </CustomTypography>

              <Box className={classes.inner}>
                {priceVariantFilters.map((filter) => (
                  <CustomFilterTag
                    hideIcon
                    key={filter.id}
                    name={filter.name}
                    value={filter.value}
                    active={sortByPrice === filter.value}
                    onSelect={this.selectSortByPrice}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        }
        actions={
          <Box className={classes.actions}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              data-test-id="done-btn"
              color="primary"
              variant="contained"
              onClick={this.submitFilter}
            >
              {t("Done")}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(KitchenMenuAddFilterDialog)
);
