// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithStyles } from "@material-ui/core";
import React from "react";
import { WithTranslation } from "react-i18next";
import { AdminBranchManagementStatus } from "../../../components/src/AdminBranchManagementStatusTag.web";
import { AdminBranchDetailFormDialogProps } from "../../../components/src/AdminBranchDetailFormDialog.web";
import { AdminBranchManagementAction } from "../../../components/src/AdminBranchManagementMenu.web";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  adminBranches: {
    id: string;
    restaurantName: string;
    branch: string;
    address: string;
    staffName: string;
    role: string;
    status: AdminBranchManagementStatus;
  }[];

  adminBranchDetailDialog: AdminBranchDetailFormDialogProps;

  loading: boolean;

  statusFilter: string;

  statusFilterOptions: {
    id: string;
    name: string;
    value: string;
  }[];

  timeFilter: number;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {}

export default class AdminLocationBranchStatusController extends BlockComponent<
  Props,
  S,
  SS
> {
  timeOptions = [
    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },
    {
      id: uuidv4(),
      name: "Current day",
      value: 1,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      adminBranches: [],

      adminBranchDetailDialog: {
        open: false,
        loading: false,
        form: {
          id: "",
          restaurantName: "",
          branch: "",
          address: "",
          staffName: "",
          role: "",
          status: AdminBranchManagementStatus.Open,
        },
        roleOptions: [],
      },

      loading: false,

      statusFilterOptions: [],

      statusFilter: "",

      timeFilter: 7,

      pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalCount: 0,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };
  }

  async componentDidMount(): Promise<void> {
    this.fetchAdminBranches();
  }

  fetchAdminBranches = () => {
    this.setState({
      adminBranches: [
        {
          id: "1",
          restaurantName: "Sunset Grill",
          branch: "Downtown",
          address: "123 Main St, City A",
          staffName: "Alice Johnson",
          role: "Manager",
          status: AdminBranchManagementStatus.Open,
        },
        {
          id: "2",
          restaurantName: "Sunset Grill",
          branch: "Uptown",
          address: "456 Elm St, City B",
          staffName: "Bob Smith",
          role: "Chef",
          status: AdminBranchManagementStatus.Closed,
        },
      ],

      pagination: {
        limit: 10,
        page: 1,
        totalCount: 2,
        totalPages: 1,
      },
    });
  };

  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminBranches();
      }
    );
  };

  downloadReport = () => {};

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchAdminBranches();
      }
    );
  };

  handleAdminBranchSelectAction = (action: AdminBranchManagementAction) => {
    switch (action) {
      case AdminBranchManagementAction.Edit:
        this.openAdminBranchDetailDialog();

        break;

      case AdminBranchManagementAction.Block:
        return null;

      default:
        break;
    }
  };

  openAdminBranchDetailDialog = () => {
    this.setState({
      adminBranchDetailDialog: {
        ...this.state.adminBranchDetailDialog,
        open: true,
      },
    });
  };

  closeAdminBranchDetailDialog = () => {
    this.setState({
      adminBranchDetailDialog: {
        open: false,
        loading: false,
        form: {
          id: "",
          restaurantName: "",
          branch: "",
          address: "",
          staffName: "",
          role: "",
          status: AdminBranchManagementStatus.Open,
        },
        roleOptions: [],
      },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  changeStatusFilter = (statusFilter: string) => {
    this.setState(
      {
        statusFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminBranches();
      }
    );
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };
}

// Customizable Area End
