import {
  Checkbox,
  IconButton,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  styled,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../CustomButton.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { CloseRounded } from "@material-ui/icons";
import CustomTypographyWeb from "../CustomTypography.web";
import { Form, Formik } from "formik";
import { array, ArraySchema, date, object, string, StringSchema } from "yup";
import {
  StylesDialog,
  StylesDialogActions,
  StylesDialogContent,
  StylesDialogTitle,
} from "../promocodes/CustomPromocodesCreateDiscountDialog.web";

import { iconCheck, iconUncheck } from "../../../blocks/promocodes/src/assets";
import CustomFormSelectFieldWeb from "../CustomFormSelectField.web";
import { StyledListItemText } from "../../../blocks/promocodes/src/PromocodesPromotionsCreate.web";
import CustomSelectTime from "../CustomSelectTime.web";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

interface Props extends WithTranslation, WithStyles {
  type: "special" | "regular";
  initValues: { days: string[]; from: string; to: string };
  id: string;
}

type State = {
  open: boolean;
  timeOfDayList: string[];
  weekdays: string[];
};

const CustomButtonToggle = styled("button")(({ theme }) => {
  return {
    textDecoration: "underline",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "#F66",
    fontFamily: "Inter",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 16.94 / 14,
    fontWeight: 600,
    "&[data-type='special']": {
      color: "#333",
    },
  };
});

export const ContainerTimesInput = styled("div")(({ theme }) => {
  return {
    marginBlockStart: 24,
  };
});

const StyledForm = styled(Form)({
  "& .form__inputLabel": {
    fontWeight: 700,
    lineHeight: 22 / 14,
  },
});

const styles = (theme: Theme) =>
  createStyles({
    formMultiselect: {
      "& ul": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "2rem",
        maxHeight: 220,
        backgroundColor: "#F8F8F8",
        "& li, & li.MuiListItem-root.Mui-selected": {
          backgroundColor: "#F8F8F8",
        },
        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "1fr",
        },
      },
    },
    formMultiselectInput: {
      "&.MuiSelect-root": {
        display: "block",
      },
    },
  });

class CustomLocationScheduleEditDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let timeOfDayList: string[] = [];

    for (let i = 0; i <= 12; i++) {
      const date2 = `${i}:30`.padStart(4, "0");
      const date1 = `${i}:00`.padStart(4, "0");

      timeOfDayList.push(date1, date2);
    }

    this.state = {
      open: false,
      timeOfDayList,
      weekdays: [
        "Tuesday",
        "Saturday",
        "Wednesday",
        "Sunday",
        "Thursday",
        "Monday",
        "Friday",
      ],
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render(): React.ReactNode {
    const { i18n, t, type, classes, initValues, id } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const isSpecial = type === "special";

    return (
      <>
        <CustomButtonToggle dir={dir} onClick={this.open} data-type={type}>
          {t("Edit")}
        </CustomButtonToggle>
        <StylesDialog
          open={this.state.open}
          onClose={this.close}
          aria-labelledby="formDialogEditSchedule"
          dir={dir}
        >
          <StylesDialogTitle id="formDialogEditSchedule" dir={dir}>
            <CustomTypographyWeb className="StylesDialogTitle__title">
              {t(isSpecial ? "Special Schedule" : "Regular Schedule")}
            </CustomTypographyWeb>
            <IconButton
              aria-label="close"
              onClick={this.close}
              className="StylesDialogTitle__btnClose"
            >
              <CloseRounded htmlColor="#334155" />
            </IconButton>
          </StylesDialogTitle>
          <Formik
            initialValues={{
              working_days: initValues.days,
              start_time: initValues.from.trim() || "00:00 AM",
              end_time: initValues.to.trim() || "00:00 AM",
            }}
            validationSchema={object().shape({
              working_days: array()
                .of(string())
                .default([])
                .min(1, t("Please select at least 1 day")),
              start_time: string()
                .when("days", {
                  is: (days) => days?.length > 0,
                  then: (_schema: StringSchema) =>
                    _schema.required(t("Start time is required")),
                  otherwise: (_schema: StringSchema) => _schema.notRequired(),
                })
                .default(""),
              end_time: string()
                .when("days", {
                  is: (days) => days?.length > 0,
                  then: (_schema: StringSchema) =>
                    _schema.required(t("End time is required")),
                  otherwise: (_schema: StringSchema) => _schema.notRequired(),
                })
                .default(""),
            })}
            onSubmit={(values) => {
              const body = {
                days: values.working_days,
                timings: {
                  from: values.start_time.replace(/ /g, ""),
                  to: values.end_time.replace(/ /g, ""),
                },
              };
              const message = new Message(
                getName(MessageEnum.LocationBranchStatusUpdateScheduleMessage)
              );
              message.addData(
                getName(MessageEnum.LocationBranchStatusUpdateScheduleBody),
                {
                  id,
                  body:
                    type === "regular"
                      ? {
                          regular_schedule: body,
                        }
                      : {
                          special_schedule: body,
                        },
                }
              );
              runEngine.sendMessage(message.id, message);
            }}
          >
            {(props) => {
              return (
                <StyledForm>
                  <StylesDialogContent style={{ overflowY: "unset" }}>
                    <CustomTypographyWeb className="form__inputLabel">
                      {t("Picking Working days")}
                    </CustomTypographyWeb>
                    <CustomFormSelectFieldWeb
                      fullWidth
                      name="working_days"
                      variant="outlined"
                      error={
                        props.touched.working_days &&
                        !!props.errors.working_days
                      }
                      multiple
                      inputProps={{
                        renderValue: (selected: string[]) =>
                          this.state.weekdays
                            .filter((day) => selected.includes(day))
                            .map((day) => t(day))
                            .join(", "),
                        placeholder: t("Select day"),
                        className: classes.formMultiselectInput,
                      }}
                      MenuProps={{
                        className: classes.formMultiselect,
                        PaperProps: {
                          className: classes.formMultiselectContainer,
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={(event) => {
                        const value = event.target.value as string[];
                        if (value[value.length - 1] === "All") {
                          props.setFieldValue(
                            "working_days",
                            props.values.working_days.length ===
                              this.state.weekdays.length
                              ? []
                              : this.state.weekdays
                          );
                          return;
                        }
                        props.setFieldValue("working_days", value);
                      }}
                      dir={dir}
                    >
                      <MenuItem value="All" dir={dir}>
                        <Checkbox
                          icon={<img src={iconUncheck} width={20} />}
                          checkedIcon={<img src={iconCheck} width={20} />}
                          checked={
                            this.state.weekdays.length ===
                            props.values.working_days.length
                          }
                        />
                        <StyledListItemText primary={t("All days")} dir={dir} />
                      </MenuItem>
                      {this.state.weekdays.map((day) => (
                        <MenuItem key={day} value={day} dir={dir}>
                          <Checkbox
                            icon={<img src={iconUncheck} width={20} />}
                            checkedIcon={<img src={iconCheck} width={20} />}
                            checked={props.values.working_days.includes(day)}
                          />
                          <StyledListItemText primary={t(day)} dir={dir} />
                        </MenuItem>
                      ))}
                    </CustomFormSelectFieldWeb>

                    <ContainerTimesInput>
                      <CustomTypographyWeb className="form__inputLabel">
                        {t("Picking Working hours")}
                      </CustomTypographyWeb>
                      <CustomSelectTime
                        labelFromOutside={t("From")}
                        labelFrom={`${
                          props.values.start_time.split(" ")[0]
                        } ${t(props.values.start_time.split(" ")[1])}`}
                        labelTo={`${props.values.end_time.split(" ")[0]} ${t(
                          props.values.end_time.split(" ")[1]
                        )}`}
                        labelToOutside={t("To")}
                        formikKeyFrom={"start_time"}
                        formikKeyTo={"end_time"}
                      />
                    </ContainerTimesInput>
                  </StylesDialogContent>
                  <StylesDialogActions dir={dir}>
                    <CustomButton
                      onClick={this.close}
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="StylesDialogActions__btnCancel"
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t("Save")}
                    </CustomButton>
                  </StylesDialogActions>
                </StyledForm>
              );
            }}
          </Formik>
        </StylesDialog>
      </>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(CustomLocationScheduleEditDialog)
);
