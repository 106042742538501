import {
  WithStyles,
  createStyles,
  withStyles,
  Box,
  Theme,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";
import clsx from "clsx";

export interface DashboardAdminStatistics {
  name: string;
  value: number | string;
  icon: string;
  theme: "orange" | "blue" | "red" | "green" | "yellow";
  display: boolean;
}

export interface Props extends WithStyles {
  statistics: DashboardAdminStatistics;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      border: "1px solid",
      borderRadius: 16,
      padding: 16,
      backgroundImage: `url(${require("./bg_dashboard_admin_statistics.svg")})`,
      backgroundPosition: "50%",
      width: "100%",
      minWidth: 196,
      height: 196,
      position: "relative",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",

      "& *": {
        boxSizing: "border-box",
      },

      "&::before": {
        content: "' '",
        position: "absolute",
        top: 0,
        right: 0,
        width: 82,
        height: 82,
        borderRadius: "50%",
        opacity: 0.3,
        transform: "translate(24%,-20%)",
        border: "10px solid",
        boxSizing: "border-box",
      },

      "&::after": {
        content: "' '",
        position: "absolute",
        bottom: 0,
        width: 82,
        height: 82,
        borderRadius: "50%",
        opacity: 0.3,
        left: "50%",
        transform: "translate(-50%, 65%)",
        boxSizing: "border-box",
      },

      "&.orange": {
        backgroundColor: "#FFF2E5",
        borderColor: "#FEDEC0",

        "& .icon": {
          backgroundColor: "#FEDEC0",

          "& .icon-inner": {
            backgroundColor: "#FC9B43",
          },
        },

        "&::before": {
          borderColor: "#FC9B43",
        },

        "&::after": {
          backgroundColor: "#FC9B43",
        },
      },

      "&.blue": {
        backgroundColor: "#E7F6FF",
        borderColor: "#B2DEF6",

        "& .icon": {
          backgroundColor: "#B2DEF6",

          "& .icon-inner": {
            backgroundColor: "#27A6EC",
          },
        },

        "&::before": {
          borderColor: "#B2DEF6",
        },

        "&::after": {
          backgroundColor: "#B2DEF6",
        },
      },

      "&.yellow": {
        backgroundColor: "#FFF5DB",
        borderColor: "#FFE49C",

        "& .icon": {
          backgroundColor: "#FFE49C",

          "& .icon-inner": {
            backgroundColor: "#FFC11A",
          },
        },

        "&::before": {
          borderColor: "#FFC11A",
        },

        "&::after": {
          backgroundColor: "#FFC11A",
        },
      },

      "&.red": {
        backgroundColor: "#FFE3E3",
        borderColor: "#FFBEBD",

        "& .icon": {
          backgroundColor: "#FFBEBD",

          "& .icon-inner": {
            backgroundColor: "#FF6F6E",
          },
        },

        "&::before": {
          borderColor: "#FF6F6E",
        },

        "&::after": {
          backgroundColor: "#FF6F6E",
        },
      },

      "&.green": {
        backgroundColor: "#E3FFE3",
        borderColor: "#B7F2B8",

        "& .icon": {
          backgroundColor: "#B7F2B8",

          "& .icon-inner": {
            backgroundColor: "#48CD49",
          },
        },

        "&::before": {
          borderColor: "#48CD49",
        },

        "&::after": {
          backgroundColor: "#48CD49",
        },
      },
    },

    icon: {
      padding: 5,
      borderRadius: "50%",
      width: 44,
      height: 44,
    },

    iconInner: {
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },

    image: {
      width: 24,
      height: 24,
      objectFit: "cover",
    },

    inner: {
      marginTop: "auto",
      marginBottom: 20,
    },

    name: {},

    number: {
      fontSize: "24px !important",
      marginTop: 8,
    },
  });

export class KitchenDashboardStatisticsCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, statistics } = this.props;

    return (
      <Box
        className={clsx(classes.container, {
          ["blue"]: statistics.theme === "blue",
          ["red"]: statistics.theme === "red",
          ["green"]: statistics.theme === "green",
          ["orange"]: statistics.theme === "orange",
          ["yellow"]: statistics.theme === "yellow",
        })}
      >
        <Box className={clsx(classes.icon, "icon")}>
          <Box className={clsx(classes.iconInner, "icon-inner")}>
            <img
              className={classes.image}
              src={statistics.icon}
              alt={statistics.name}
            />
          </Box>
        </Box>

        <Box className={classes.inner}>
          <CustomTypography className={classes.name} variant="h5">
            {statistics.name}
          </CustomTypography>
          <CustomTypography className={classes.number} variant="subtitle1">
            {statistics.value}
          </CustomTypography>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(KitchenDashboardStatisticsCard);
