// Customizable Area Start
import React from "react";

import ReviewsRatingAndReviewsAdminController, {
  Props,
} from "./ReviewsRatingAndReviewsAdminController.web";
import {
  Box,
  IconButton,
  InputAdornment,
  Portal,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CustomTable from "../../../components/src/CustomTable";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomInputFieldWeb from "../../../components/src/CustomInputField.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItemWeb from "../../../components/src/CustomMenuItem.web";
import { Close, SaveAlt, Search } from "@material-ui/icons";
import CustomReviewsTableMenu from "../../../components/src/reviews/CustomReviewsTableMenu.web";
import { withTranslation } from "react-i18next";
import CustomReviewsDialogWeb from "../../../components/src/reviews/CustomReviewsDialog.web";

const Container = styled("div")({
  "& td.table__tr__tc__numberValue": {
    fontFamily: "Inter",
  },
  "& .actions": {
    display: "flex",
    gap: 8,
    "& > button": {
      marginInlineStart: "auto",
    },
    "& >:nth-child(1), & >:nth-child(2)": {
      maxWidth: 180,
      width: "100%",
    },
    "& input::placeholder": { opacity: 1 },
  },
  "& .content": {
    border: "1px solid #00000014",
    padding: 16,
    marginBlockStart: 24,
  },
  "& .pagination": {
    marginBlockStart: 20,
  },
});

export class ReviewsRatingAndReviewsAdmin extends ReviewsRatingAndReviewsAdminController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;

    const {
      reviews,
      timeFilter,
      restaurantFilter,
      restaurantFilterOptions,
      reviewIdSearch,
      loading,
      snackbar,
      pagination,
      dialogData,
    } = this.state;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <Container>
        <Box className="actions" dir={dir}>
          <CustomSelect
            dir={dir}
            data-test-id="selectTime"
            variant="outlined"
            value={timeFilter}
            onChange={this.changeTimeFilter}
            fullWidth
          >
            {this.timeOptions.map((option) => (
              <CustomMenuItemWeb value={option.value} key={option.id} dir={dir}>
                {t(option.name)}
              </CustomMenuItemWeb>
            ))}
          </CustomSelect>
          <CustomSelect
            fullWidth
            dir={dir}
            data-test-id="selectRestaurant"
            variant="outlined"
            value={restaurantFilter}
            onChange={this.changeRestaurantFilter}
            displayEmpty
            inputProps={{
              renderValue: (selected: string) => {
                if (!selected) {
                  return t("Restaurants");
                }

                const selectedOption = restaurantFilterOptions.find(
                  (option: any) => option.value === selected
                );

                if (!selectedOption) {
                  return "";
                }

                return t(selectedOption.name);
              },
            }}
          >
            {restaurantFilterOptions.map((option) => (
              <CustomMenuItemWeb value={option.value} key={option.id} dir={dir}>
                {t(option.name)}
              </CustomMenuItemWeb>
            ))}
          </CustomSelect>

          <CustomInputFieldWeb
            dir={dir}
            data-test-id="inputSearchReviewID"
            inputRef={this.inputRef}
            variant="outlined"
            placeholder={t("Review ID")}
            InputProps={{
              endAdornment: (
                <>
                  {reviewIdSearch && (
                    <IconButton
                      data-test-id="btnClearSearchReview"
                      size="small"
                      aria-label="clear search Review"
                      onClick={this.clearSearch}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )}
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                </>
              ),
            }}
            onKeyDown={this.handleSearch}
          />

          <CustomButton
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="downloadOrderReportBtn"
            onClick={this.downloadReport}
            dir={dir}
          >
            {t("Download Report")}
          </CustomButton>
        </Box>

        <Box className="content">
          <TableContainer>
            <CustomTable dir={dir}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Review ID")}</TableCell>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Branch")}</TableCell>
                  <TableCell>{t("Date Reviewed")}</TableCell>
                  <TableCell>{t("User Rating")}</TableCell>
                  <TableCell>{t("Review")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {reviews.length ? (
                  reviews.map((review) => (
                    <TableRow key={review.reviewId}>
                      <TableCell className="table__tr__tc__numberValue">
                        {review.reviewId}
                      </TableCell>
                      <TableCell>{review.restaurantName}</TableCell>
                      <TableCell>{review.branch}</TableCell>
                      <TableCell className="table__tr__tc__numberValue">
                        {review.dateReviewed}
                      </TableCell>
                      <TableCell className="table__tr__tc__numberValue">
                        {review.userRating}
                      </TableCell>
                      <TableCell>{review.review}</TableCell>
                      <TableCell>
                        <CustomReviewsTableMenu
                          onDelete={console.log}
                          onView={this.openDialog.bind(this, review)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className="pagination" dir={dir}>
            <CustomPagination
              data-test-id="pagination"
              fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
              dir={dir}
            />
          </Box>
        </Box>

        <CustomReviewsDialogWeb
          initValue={dialogData}
          onClose={this.closeDialog}
          open={Boolean(dialogData)}
        />

        <CustomLoadingBackdropWeb open={loading} />

        <Portal>
          <CustomSnackbarWeb
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Container>
    );
  }
}

export default withTranslation()(ReviewsRatingAndReviewsAdmin);
// Customizable Area End
