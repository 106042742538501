import React from "react";
import { Box } from "@material-ui/core";

import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";

const COLORS = ["#F4A79D", "#059669", "#FF6666"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <>
      <g filter="url(#solid)">
        <circle cx={x} cy={y} r="16.6739" fill="#ECEAF8" />
      </g>
      <text
        x={x}
        y={y}
        fill="#000"
        fontSize={10}
        height={"12.1px"}
        fontFamily="Inter"
        textAnchor={"middle"}
        dominantBaseline="central"
        fontWeight={700}
      >
        {`${(percent * 100).toFixed(0)}`}&rlm;%
      </text>
    </>
  );
};

type Props = {
  data: any[];
};

export default function CustomAnalyticsAdminSalesVisitsChart({ data }: Props) {
  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `<defs>
        <filter
          id="solid"
          x="0"
          y="0"
          width="38px"
          height="38px"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15820_5527"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_15820_5527"
            result="shape"
          />
        </filter>
      </defs>`,
        }}
      />
      <ResponsiveContainer maxHeight={240}>
        <PieChart width={400} height={180}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={renderCustomizedLabel}
            fill="#8884d8"
            dataKey="value"
            paddingAngle={0}
            labelLine={false}
            innerRadius={"40%"}
            legendType="circle"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            align={rtl ? "left" : "right"}
            layout="vertical"
            verticalAlign="middle"
            wrapperStyle={{
              fontFamily: "Inter",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "14.52px",
              textAlign: rtl ? "right" : "left",
              color: "#767676",
            }}
            content={(props) => {
              const { payload = [] } = props;

              return (
                <Box
                  component={"ul"}
                  maxWidth={150}
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gridRowGap={16}
                >
                  {payload.map((entry: any, index) => {
                    const percent = `${(entry?.payload?.percent ?? 0) * 100}`;

                    return (
                      <Box
                        component={"li"}
                        key={`item-${index}`}
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight={700}
                        lineHeight={14.52 / 12}
                        display="flex"
                        justifyContent="space-between"
                        dir={dir}
                      >
                        <span>
                          <Box
                            borderRadius={"50%"}
                            width={10}
                            height={10}
                            bgcolor={entry?.color ?? "#000"}
                            display={"inline-block"}
                            style={{ marginInlineEnd: 5 }}
                          />
                          {t(entry.value)}
                        </span>
                        <span>{percent}&rlm;%</span>
                      </Box>
                    );
                  })}
                </Box>
              );
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
