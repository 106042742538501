import { Box, createStyles, WithStyles, withStyles } from "@material-ui/core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomTypography from "../CustomTypography.web";
import clsx from "clsx";

export interface OperationStatistics {
  name: string;
  number: number;
  percent: number;
  status: OperationStatisticsStatus;
}

export enum OperationStatisticsStatus {
  Up = "up",
  Down = "down",
  Normal = "normal",
}

export enum OperationStatisticsTheme {
  Blue = "blue",
  Red = "red",
  Green = "green",
}

export interface S {}

export interface OperationStatisticsProps {
  statistics: OperationStatistics;
  theme: OperationStatisticsTheme;
}

export interface Props
  extends OperationStatisticsProps,
    WithStyles,
    WithTranslation {}

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 23,

      "& + &": {
        marginTop: 14,
      },

      [`&.${OperationStatisticsTheme.Blue}`]: {
        borderLeft: "3px solid #2563EB",
      },

      [`&.${OperationStatisticsTheme.Red}`]: {
        borderLeft: "3px solid #FF6666",
      },

      [`&.${OperationStatisticsTheme.Green}`]: {
        borderLeft: "3px solid #059669",
      },
    },

    number: {
      fontSize: "12px !important",
      lineHeight: "14.52px",
    },

    name: {
      fontSize: "12px !important",
      lineHeight: "14.52px",
      color: "#767676",
    },

    info: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 5,
    },

    status: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
  });

const up = require("../ic_blue_up.svg");
const down = require("../ic_blue_down.svg");

class CustomAnalyticsOperationStatistics extends React.Component<Props, S> {
  render() {
    const { classes, theme, statistics } = this.props;

    const { name, number, percent, status } = statistics;

    return (
      <Box className={clsx(classes.container, theme)}>
        <Box className={classes.info}>
          <CustomTypography variant="subtitle1" className={classes.number}>
            {number}
          </CustomTypography>

          <CustomTypography variant="subtitle1" className={classes.name}>
            {name}
          </CustomTypography>
        </Box>

        <Box className={classes.status}>
          <CustomTypography variant="subtitle1" className={classes.number}>
            {`${percent}%`}
          </CustomTypography>

          {(() => {
            switch (status) {
              case OperationStatisticsStatus.Up:
                return (
                  <img
                    className={classes.iconStatus}
                    src={up}
                    alt={OperationStatisticsStatus.Up}
                  />
                );

              case OperationStatisticsStatus.Down:
                return (
                  <img
                    className={classes.iconStatus}
                    src={down}
                    alt={OperationStatisticsStatus.Down}
                  />
                );

              default:
                return <></>;
            }
          })()}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(CustomAnalyticsOperationStatistics)
);
