// Customizable Area Start
import { WithTranslation } from "react-i18next";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { v4 as uuidv4 } from "uuid";
import { WithStyles } from "@material-ui/core";
import React from "react";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { AdminCompensationDetailFormDialogProps } from "../../../components/src/AdminCompensationDetailFormDialog.web";
import { AdminCompensationManagementStatus } from "../../../components/src/AdminCompensationManagementStatusTag.web";
import { AdminCompensationManagementAction } from "../../../components/src/AdminCompensationManagementMenu.web";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  adminCompensations: {
    id: string;
    requestId: string;
    restaurantName: string;
    branch: string;
    dateSubmitted: string;
    amount: number;
    status: AdminCompensationManagementStatus;
  }[];

  adminCompensationDetailDialog: AdminCompensationDetailFormDialogProps;

  loading: boolean;

  requestIdSearch: string;

  restaurantFilter: string;

  restaurantFilterOptions: {
    id: string;
    name: string;
    value: string;
  }[];

  timeFilter: number;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {}

export default class AdminLoyaltySystemCompensationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  timeOptions = [
    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },
    {
      id: uuidv4(),
      name: "Current day",
      value: 1,
    },
  ];

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      adminCompensations: [],

      adminCompensationDetailDialog: {
        mode: AdminCompensationManagementAction.View,
        open: false,
        loading: false,
        form: {
          id: "",
          requestId: "",
          restaurantName: "",
          branch: "",
          dateSubmitted: new Date(),
          amount: 0,
          status: "",
        },
        branchOptions: [],
      },

      loading: false,

      requestIdSearch: "",

      restaurantFilterOptions: [],

      restaurantFilter: "",

      timeFilter: 7,

      pagination: {
        page: 1,
        limit: 12,
        totalPages: 0,
        totalCount: 0,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };

    this.inputRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.fetchAdminCompensations();
  }

  fetchAdminCompensations = () => {
    this.setState({
      adminCompensations: [
        {
          id: "1",
          requestId: "REQ1001",
          restaurantName: "Pizza Palace",
          branch: "Downtown",
          dateSubmitted: "2023-12-01",
          amount: 120,
          status: AdminCompensationManagementStatus.Pending,
        },
        {
          id: "2",
          requestId: "REQ1002",
          restaurantName: "Burger World",
          branch: "Uptown",
          dateSubmitted: "2023-12-02",
          amount: 89,
          status: AdminCompensationManagementStatus.Approved,
        },
        {
          id: "3",
          requestId: "REQ1003",
          restaurantName: "Sushi Place",
          branch: "East Side",
          dateSubmitted: "2023-12-03",
          amount: 200,
          status: AdminCompensationManagementStatus.Pending,
        },
        {
          id: "4",
          requestId: "REQ1004",
          restaurantName: "Taco Town",
          branch: "West End",
          dateSubmitted: "2023-12-04",
          amount: 50,
          status: AdminCompensationManagementStatus.Approved,
        },
        {
          id: "5",
          requestId: "REQ1005",
          restaurantName: "Pasta House",
          branch: "Central",
          dateSubmitted: "2023-12-05",
          amount: 150,
          status: AdminCompensationManagementStatus.Pending,
        },
        {
          id: "6",
          requestId: "REQ1006",
          restaurantName: "Steak Station",
          branch: "North",
          dateSubmitted: "2023-12-06",
          amount: 300,
          status: AdminCompensationManagementStatus.Approved,
        },
        {
          id: "7",
          requestId: "REQ1007",
          restaurantName: "Noodle Nook",
          branch: "Downtown",
          dateSubmitted: "2023-12-07",
          amount: 75,
          status: AdminCompensationManagementStatus.Pending,
        },
        {
          id: "8",
          requestId: "REQ1008",
          restaurantName: "Salad Stop",
          branch: "South",
          dateSubmitted: "2023-12-08",
          amount: 45,
          status: AdminCompensationManagementStatus.Approved,
        },
        {
          id: "9",
          requestId: "REQ1009",
          restaurantName: "BBQ Barn",
          branch: "West End",
          dateSubmitted: "2023-12-09",
          amount: 180,
          status: AdminCompensationManagementStatus.Pending,
        },
        {
          id: "10",
          requestId: "REQ1010",
          restaurantName: "Pancake House",
          branch: "East Side",
          dateSubmitted: "2023-12-10",
          amount: 60,
          status: AdminCompensationManagementStatus.Approved,
        },
        {
          id: "11",
          requestId: "REQ1011",
          restaurantName: "Grill & Chill",
          branch: "Uptown",
          dateSubmitted: "2023-12-11",
          amount: 220,
          status: AdminCompensationManagementStatus.Pending,
        },
        {
          id: "12",
          requestId: "REQ1012",
          restaurantName: "Cafe Delight",
          branch: "Central",
          dateSubmitted: "2023-12-12",
          amount: 100,
          status: AdminCompensationManagementStatus.Approved,
        },
      ],

      pagination: {
        limit: 12,
        page: 1,
        totalCount: 12,
        totalPages: 1,
      },
    });
  };

  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminCompensations();
      }
    );
  };

  changeRestaurantFilter = (restaurantFilter: string) => {
    this.setState(
      {
        restaurantFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminCompensations();
      }
    );
  };

  handleSearch = (event: any) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        requestIdSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminCompensations();
      }
    );
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchAdminCompensations();
      }
    );
  };

  clearRequestIdSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        requestIdSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdminCompensations();
      }
    );
  };

  downloadReport = () => {};

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  openAdminCompensationDetailDialog = (
    mode: AdminCompensationManagementAction
  ) => {
    this.setState({
      adminCompensationDetailDialog: {
        ...this.state.adminCompensationDetailDialog,
        mode,
        open: true,
      },
    });
  };

  closeAdminCompensationDetailDialog = () => {
    this.setState({
      adminCompensationDetailDialog: {
        mode: AdminCompensationManagementAction.View,
        open: false,
        loading: false,
        form: {
          id: "",
          requestId: "",
          restaurantName: "",
          branch: "",
          dateSubmitted: new Date(),
          amount: 0,
          status: "",
        },
        branchOptions: [],
      },
    });
  };

  changeCompensationDetailDialogMode = (
    mode: AdminCompensationManagementAction
  ) => {
    this.setState({
      adminCompensationDetailDialog: {
        ...this.state.adminCompensationDetailDialog,
        mode,
      },
    });
  };

  async receive(from: string, message: Message) {
    /* empty */
  }
}

// Customizable Area End
