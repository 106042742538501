import {
  Box,
  Drawer,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  WithStyles,
  capitalize,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography.web";
import { Close } from "@material-ui/icons";
import { InvoiceDetails } from "../../../blocks/paymentadmin2/src/Paymentadmin2FinanceViewController.web";
import CustomButton from "../CustomButton.web";
import CustomTable from "../CustomTable";
import { WithTranslation, withTranslation } from "react-i18next";
import { customLocaleMoment } from "../Utils.web";
import { TFunction } from "i18next";

export interface Props extends WithStyles<any>, WithTranslation {
  open: boolean;
  invoice: InvoiceDetails | null;
  onClose(): void;
  onPay(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& *": {
        boxSizing: "border-box",
      },
    },

    flexBetween: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    head: {
      borderBottom: "1px solid #E2E8F0",
      padding: 24,
      "& .head__numberText": {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 2,
      },
      "& .head__number": {
        fontFamily: "Inter",
        fontWeight: 700,
        "&.head__amount": {
          textAlign: "right",
        },
      },
      "& .head__status": {
        fontSize: theme.typography.pxToRem(9),
        color: "#FFF",
        fontWeight: 700,
        borderRadius: 8,
        padding: "1.5px 9.5px",
        fontFamily: "Raleway",
        "&[data-status='in_process'], &[data-status='half_paid']": {
          backgroundColor: "#F59E0B",
        },
        "&[data-status='due_in_2_days']": {
          backgroundColor: "#F87171",
        },
        "&[data-status='overdue']": {
          backgroundColor: "#DC2626",
        },
        "&[data-status='paid']": {
          backgroundColor: "#059669",
        },
      },

      "& .head__title": {
        fontWeight: 700,
        lineHeight: 32 / 24,
      },
    },

    body: {
      paddingBlockEnd: 24,
      flex: 1,
      overflow: "auto",
    },

    bottom: {
      borderTop: "1px solid #E2E8F0",
      padding: 24,
      display: "flex",
      justifyContent: "flex-between",
      alignItems: "center",
      gap: 16,
      "& > *": { flex: 1 },
      "& > :first-child": {
        backgroundColor: "#333333",
      },
    },

    fontInter: {
      fontFamily: "Inter",
    },

    root: {
      "& .MuiPaper-root.MuiDrawer-paper": {
        width: "100%",
        maxWidth: 765,
      },
    },

    table: {
      "& thead tr th": {
        color: "#FF6666",
      },
      "& thead tr th:first-child, & tbody tr td:first-child": {
        paddingInlineStart: 24,
      },
      "& thead tr th:last-child, & tbody tr td:last-child": {
        paddingInlineEnd: 24,
      },
      "& tbody tr:nth-last-child(2) td": {
        borderBottomWidth: 3,
        borderBottomColor: "#FF6666",
      },
      "& tbody tr:last-child td": {
        borderBottomWidth: 0,
      },
      "& .MuiTableCell-root": {
        borderBottomColor: "rgba(238, 238, 238, 1)",
        borderRight: "1px solid rgba(238, 238, 238, 1)",
        verticalAlign: "baseline",
        padding: 12,
      },
      "& .table__name": {
        fontWeight: "700",
        color: "#333",
      },
      "& .table__subNameContainer": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 12,
        "&[dir='rtl']": {
          flexDirection: "row-reverse",
        },
      },
      "& .table__subName": {
        color: "#979797",
        fontFamily: "Inter",
      },
      "& .table__cell__unit": {
        fontSize: 10,
        fontWeight: 600,
        color: "#333",
      },
      "& .table__cell__number": {
        textAlign: "right",
        lineHeight: 1,
        fontFamily: "Inter",
      },
      "& .table__cell__total": {
        backgroundColor: "#FFEEEE",
        "& span.font-bold": {
          fontWeight: 700,
        },
        "& .divider": {
          width: "calc(100% + 36px)",
          marginInlineStart: "-12px",
          marginBlock: 14,
          height: 1,
          backgroundColor: "#FF6666",
          marginInline: -24,
        },
      },
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 10,
      paddingInlineEnd: 16,
      "& .action__now": {
        textDecoration: "underline",
      },
    },
  });

const getTotalAmount = (orders: InvoiceDetails["orders"]) => {
  return orders.reduce((total, currentOrder) => total + +currentOrder.total, 0);
};

export const formatMonth = (
  date: string,
  language: string,
  t: TFunction<"translation", undefined>
) => {
  const [month, year] = date.split(" ");
  const capitalizedMonth = capitalize(month.toLocaleLowerCase());

  const i18nMonth = customLocaleMoment()
    .locale(language)
    .month(t(capitalizedMonth))
    .format("MMMM");

  return i18nMonth + " " + year;
};

export class CustomPaymentadmin2FinaceView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, invoice, onClose, onPay, t, i18n } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    if (!invoice) {
      return <></>;
    }

    return (
      <Drawer
        className={classes.root}
        anchor={rtl ? "left" : "right"}
        open={open}
        onClose={onClose}
      >
        <div className={classes.container}>
          <Box className={classes.head} dir={dir}>
            <div className={classes.flexBetween} dir={dir}>
              <div>
                <CustomTypography variant="h2" className={"head__title"}>
                  {t("Invoice for")}{" "}
                  {formatMonth(
                    invoice.payment_admin.data.attributes.formatted_month,
                    i18n.language,
                    t
                  )}{" "}
                </CustomTypography>
                <span
                  className="head__status"
                  data-status={
                    invoice.payment_admin.data.attributes.status_value
                  }
                >
                  {t(invoice.payment_admin.data.attributes.status_value)}
                </span>
              </div>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>

            <Box className={classes.flexBetween} marginTop={"35px"} dir={dir}>
              <div>
                <CustomTypography className="head__numberText">
                  {t("Invoice Number")}
                </CustomTypography>
                <CustomTypography className={"head__number"}>
                  {invoice.payment_admin.data.attributes.invoice_number}
                </CustomTypography>
              </div>
              <div>
                <CustomTypography className="head__numberText">
                  {t("Invoice of (SAR)")}
                </CustomTypography>
                <CustomTypography className={"head__number head__amount"}>
                  {invoice.payment_admin.data.attributes.amount.split(" ")[1]}{" "}
                  {t("SAR")}
                </CustomTypography>
              </div>
            </Box>
          </Box>

          <Box className={classes.body}>
            <TableContainer>
              <CustomTable className={classes.table} dir={dir}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Order Details")}</TableCell>
                    <TableCell>{t("Payment method")}</TableCell>
                    <TableCell className="table__cell__number">
                      {t("Cash")}
                      <br />
                      <span className="table__cell__unit">{t("SAR")}</span>
                    </TableCell>
                    <TableCell className="table__cell__number">
                      {t("Points")}
                      <br />
                      <span className="table__cell__unit">{t("SAR")}</span>
                    </TableCell>
                    <TableCell className="table__cell__number">
                      {t("Total")}
                      <br />
                      <span className="table__cell__unit">{t("SAR")}</span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoice.orders.map((order, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span className="table__name">{order.catalogues}</span>
                        <div className="table__subNameContainer" dir={dir}>
                          <span className="table__subName">
                            {t("Date")}: {order.created_at}
                          </span>
                          <span className="table__subName">
                            {t("Order no")}: {order.order_number}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>{t(order.payment_status)}</TableCell>
                      <TableCell className="table__cell__number">
                        {order.sub_total_price}
                      </TableCell>
                      <TableCell className="table__cell__number">0</TableCell>
                      <TableCell className="table__cell__number">
                        {order.total}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell />
                    <TableCell colSpan={4} className="table__cell__total">
                      <div className="table__subNameContainer">
                        <span className="font-bold">{t("Total Amount")}</span>
                        <span className={classes.fontInter}>
                          {getTotalAmount(invoice.orders)}
                        </span>
                      </div>

                      <div className="table__subNameContainer">
                        <span>{t("Paid by loyalty points")}</span>
                        <span className={classes.fontInter}>0</span>
                      </div>

                      <div className="divider" />

                      <div className="table__subNameContainer">
                        <span className="font-bold">{t("Payable Amount")}</span>
                        <span className={classes.fontInter + " font-bold"}>
                          {getTotalAmount(invoice.orders)}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </CustomTable>
            </TableContainer>

            <div className={classes.actions} dir={dir}>
              <CustomButton
                variant="text"
                color="primary"
                className={"action__now"}
                onClick={onPay}
              >
                {t("Pay Now")}
              </CustomButton>
              <CustomButton variant="text" color="primary" onClick={onClose}>
                {t("Pay Later")}
              </CustomButton>
            </div>
          </Box>

          <Box className={classes.bottom} dir={dir}>
            <CustomButton variant="contained" color="primary" onClick={onClose}>
              {t("Cancel")}
            </CustomButton>
            <CustomButton variant="contained" color="primary">
              {t("Download Invoice")}
            </CustomButton>
          </Box>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(CustomPaymentadmin2FinaceView)
);
