import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "../CustomButton.web";
import CustomFormInputField from "../CustomFormInputField.web";
import KitchenDrawer from "../KitchenDrawer.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";

const getSchemaAccept = (t: TFunction<"translation", undefined>) =>
  Yup.object({
    point: Yup.string()
      .required(t("Please enter message"))
      .transform((message) => message.trim()),
  });

const getSchemaReject = (t: TFunction<"translation", undefined>) =>
  Yup.object({
    message: Yup.string()
      .required(t("Please enter message"))
      .transform((message) => message.trim()),
  });

interface Props extends WithStyles<any>, WithTranslation {
  type: "accept" | "reject";
  open: boolean;
  loading: boolean;
  onClose(): void;
  onSubmit(values: { point: string } | { message: string }): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      paddingBlockStart: "2.5rem",

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

const getError = (
  props: FormikProps<
    | {
        point: string;
      }
    | {
        message: string;
      }
  >
) => {
  let error: boolean | undefined;

  if ("point" in props.touched && "point" in props.errors) {
    error = props.touched.point && !!props.errors.point;
  }

  if ("message" in props.touched && "message" in props.errors) {
    error = props.touched.message && !!props.errors.message;
  }

  return error;
};

export class CustomLoyaltySystemFormDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, loading, type, onClose, onSubmit, t, i18n } =
      this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const isAccept = type === "accept";

    return (
      <KitchenDrawer
        open={open}
        title={isAccept ? t("Add Reward Point") : t("Reject")}
        dir={dir}
        anchor={rtl ? "left" : "right"}
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom} dir={dir}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
              dir={dir}
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formCompensation"
              dir={dir}
            >
              {loading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                t(`Submit${isAccept ? " Accept" : " Reject"}`)
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={
              isAccept
                ? getSchemaAccept(t).default()
                : getSchemaReject(t).default()
            }
            validationSchema={
              isAccept ? getSchemaAccept(t) : getSchemaReject(t)
            }
            onSubmit={onSubmit}
            data-test-id="formikFormCompensation"
          >
            {(props) => {
              return (
                <Form
                  id="formCompensation"
                  data-test-id="formCompensation"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner} dir={dir}>
                    <CustomFormInputField
                      fullWidth
                      name={isAccept ? "point" : "message"}
                      multiline={type === "reject"}
                      maxRows={3}
                      minRows={3}
                      label={
                        isAccept
                          ? t("Enter Loyalty Point")
                          : t("Compensations Reject")
                      }
                      placeholder=""
                      variant="outlined"
                      error={getError(props)}
                      data-test-id="input"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withTranslation()(
  withStyles(styles)(CustomLoyaltySystemFormDrawer)
);
