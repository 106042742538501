// Customizable Area Start
import React, { useState } from "react";

import NotificationsAllController, {
  Props,
} from "./NotificationsAllController.web";
import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Portal,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CustomTypography from "../../../components/src/CustomTypography.web";
import { CheckRounded, MoreVertRounded } from "@material-ui/icons";

import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { icSend } from "./assets";
import { Form, Formik } from "formik";
import CustomFormSelectFieldWeb from "../../../components/src/CustomFormSelectField.web";
import CustomFormInputFieldWeb from "../../../components/src/CustomFormInputField.web";
import { object, string } from "yup";

import { withTranslation, useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const getSchema = (t: TFunction<"translation", undefined>) =>
  object({
    email: string().required(t("Please enter email")),
    text: string().required(t("Please enter message")),
  });

export class NotificationsAll extends NotificationsAllController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const schema = getSchema(t);

    const {
      notifications,
      loading,
      snackbar,
      openModal,
      emailIDs,
      loadingSendMessage,
      isNotificationCenter,
    } = this.state;

    return (
      <>
        <Portal>
          <CustomSnackbarWeb
            open={snackbar.open}
            message={snackbar.message}
            type={snackbar.type}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={this.hideSnackbar}
          />
        </Portal>
        <CustomLoadingBackdropWeb open={loading} />
        <Container dir={dir}>
          <div className="btnMarkAllContainer" dir={dir}>
            {notifications.length > 0 && (
              <ButtonBase
                className="btnMarkAll"
                dir={dir}
                onClick={this.handleMarkAllAsRead}
                data-testid="btnMarkAll"
              >
                <CheckRounded htmlColor="#334155" />
                <CustomTypography dir={dir}>
                  {t("Mark all as read")}
                </CustomTypography>
              </ButtonBase>
            )}
            {isNotificationCenter && (
              <CustomButton
                className="btnSendMessage"
                color="primary"
                variant="contained"
                onClick={this.openModal}
                startIcon={<img alt="" src={icSend} />}
                data-testid="btnSendMessage"
              >
                {t("Send message")}
              </CustomButton>
            )}
          </div>
          {notifications.length === 0 && (
            <Box textAlign={"center"}>
              <CustomTypography dir={dir}>
                {t("No notifications")}
              </CustomTypography>
            </Box>
          )}
          {notifications.map((notification) => {
            return (
              <div key={notification.id} className="notification" dir={dir}>
                {notification.attributes.account?.data.attributes.image?.url ? (
                  <img
                    alt=""
                    className="notification__image"
                    src={
                      notification.attributes.account.data.attributes.image.url
                    }
                  />
                ) : (
                  <div className="notification__image" />
                )}

                <div dir={dir}>
                  <CustomTypography
                    className={
                      "notification__title " +
                      (notification.attributes.is_read
                        ? ""
                        : "notification__title--unread")
                    }
                    dir={dir}
                  >
                    {notification.attributes.headings}
                  </CustomTypography>
                  <CustomTypography
                    className="notification__description"
                    dir={dir}
                  >
                    {notification.attributes.contents}
                  </CustomTypography>
                </div>
                <div className="notification__actionContainer">
                  <IconButton className="notification__action">
                    <CustomDropDown
                      isRead={notification.attributes.is_read}
                      handleView={() =>
                        this.updateSingleNotification(notification.id)
                      }
                      handleDelete={() =>
                        this.deleteSingleNotification(notification.id)
                      }
                    />
                  </IconButton>
                  <CustomTypography className="notification__time" dir={dir}>
                    {this.formatNotiticationCreatedTime(
                      notification.attributes.created_at
                    )}
                  </CustomTypography>
                </div>
              </div>
            );
          })}

          {isNotificationCenter && (
            <ModalSendMessage
              open={openModal}
              className="modalSendMessage"
              id="modalSendMessage"
              onClose={this.closeModal}
              data-testid="modalSendMessage"
            >
              <div className="modalSendMessage__body" dir={dir}>
                <Formik
                  initialValues={schema.default()}
                  validationSchema={schema}
                  onSubmit={this.sendMessage}
                >
                  {(props) => {
                    return (
                      <Form>
                        <CustomFormSelectFieldWeb
                          fullWidth
                          name="email"
                          label={t("Recipient")}
                          data-testid="formSelectRecipient"
                          variant="outlined"
                          error={props.touched.email && !!props.errors.email}
                          dir={dir}
                        >
                          {emailIDs.map((email) => (
                            <MenuItem key={email} value={email}>
                              <ListItemText
                                primary={email}
                                dir={dir}
                                style={{ textAlign: "start" }}
                              />
                            </MenuItem>
                          ))}
                        </CustomFormSelectFieldWeb>
                        <CustomFormInputFieldWeb
                          fullWidth
                          name="text"
                          multiline
                          maxRows={10}
                          minRows={10}
                          label={t("Message Text")}
                          variant="outlined"
                          error={props.touched.text && !!props.errors.text}
                          dir={dir}
                        />

                        <CustomButton
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="btnSendMessageSubmit"
                          dir={dir}
                        >
                          {loadingSendMessage ? (
                            <CircularProgress color="inherit" size={"1.5rem"} />
                          ) : (
                            t("Send message")
                          )}
                        </CustomButton>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </ModalSendMessage>
          )}
        </Container>
      </>
    );
  }
}

export default withTranslation()(NotificationsAll);

function CustomDropDown(props: {
  isRead: boolean;
  handleView(): void;
  handleDelete(): void;
}) {
  const [openMenu, setOpenMenu] = useState<null | HTMLButtonElement>(null);

  const open = (elem: HTMLButtonElement) => {
    setOpenMenu(elem);
  };

  const close = () => {
    setOpenMenu(null);
  };

  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  const options = [t("view"), t("delete")];

  const handleViewClick = () => {
    props.handleView();
    close();
  };

  const handleDeleteClick = () => {
    props.handleDelete();
    close();
  };

  return (
    <>
      <IconButton size="small" onClick={(event) => open(event.currentTarget)}>
        <MoreVertRounded htmlColor="#334155" />
      </IconButton>

      <StyledMenu
        elevation={1}
        getContentAnchorEl={null}
        anchorEl={openMenu as Element}
        keepMounted
        open={Boolean(openMenu)}
        onClose={close}
        MenuListProps={{
          style: {
            minWidth: 150,
          },
          disablePadding: true,
        }}
      >
        {options.map((item) => {
          const isView = item === options[0];
          const showCheck = isView && props.isRead;

          return (
            <MenuItem
              key={item}
              className="dropDownMenuItem"
              data-selected={showCheck}
              dir={dir}
              onClick={isView ? handleViewClick : handleDeleteClick}
            >
              <CheckRounded
                fontSize="small"
                style={{ visibility: showCheck ? "visible" : "hidden" }}
              />
              <CustomTypography className="dropDownMenuItem__name" dir={dir}>
                {item}
              </CustomTypography>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
}

const Container = styled("div")(({ theme }) => {
  return {
    width: "calc(100% - 48px)",
    border: "1px solid #00000014",
    borderRadius: 8,
    padding: 24,

    "&[dir='rtl']": {
      float: "right",
      clear: "right",
    },

    "& .btnMarkAllContainer": {
      padding: "16px 8px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: 26,
    },
    "& .btnMarkAll p": {
      paddingInlineStart: 6,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: 700,
      lineHeight: 22 / 14,
      color: "#334155",
      marginBlockStart: "auto",
    },
    "& .notification": {
      padding: "16px 8px",
      display: "grid",
      gridTemplateColumns: "44px auto 80px",
      gap: 24,
      borderTop: "1px solid #E2E8F0",
      "&:last-child": {
        borderBottom: "1px solid #E2E8F0",
      },
    },
    "& .notification__image": {
      borderRadius: "50%",
      backgroundColor: "#F66",
      width: 44,
      height: 44,
      objectFit: "cover",
    },
    "& .notification__title, & .notification__description, & .notification__time":
      {
        color: "#0F172A",
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 20 / 14,
      },
    "& .notification__title": {
      fontWeight: 700,
      "&.notification__title--unread": {
        fontWeight: 600,
        color: "#FF6666",
      },
    },
    "& .notification__time": {
      fontFamily: "Inter",
      color: "#64748B",
    },
    "& .notification__actionContainer": {
      textAlign: "right",
    },
    "& .notification__action": { padding: "0 0 12px 0" },
  };
});

const ModalSendMessage = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& .modalSendMessage__body": {
    width: "100%",
    height: "100%",
    maxWidth: 555,
    maxHeight: 510,
    backgroundColor: "#fff",
    padding: "40px 64px",
    borderRadius: "8px 8px 32px 8px",
    "& div + div": {
      marginBlockStart: 24,
    },
    "& .btnSendMessageSubmit": {
      marginBlockStart: 40,
      padding: 16,
      width: "100%",
      maxWidth: 153,
      textTransform: "capitalize",
    },
    "& .MuiFormHelperText-root": {
      textAlign: "start",
    },
  },
});

const StyledMenu = styled(Menu)({
  "& .dropDownMenuItem": {
    display: "flex",
    alignItems: "center",
    padding: "9px 16px",

    "&[data-selected='true']": {
      borderLeftStyle: "solid",
      backgroundColor: "#FC9797",
      borderLeft: 3,
      borderLeftColor: "#FF6666",
    },
  },

  "& .dropdownMenuItem__name": {
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    marginLeft: 8,
    textTransform: "capitalize",
  },
});

// Customizable Area End
