// Customizable Area Start
import { InferType, array, object, string } from "yup";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { ChangeEvent } from "react";
import { WithTranslation } from "react-i18next";

export const configJSON = require("./config");

export interface Props extends WithTranslation {}

export const ENUM_SOCIAL_TYPE = {
  EMAIL: 0,
  FACEBOOK: 1,
  LINKEDIN: 2,
  TWITTER: 3,
} as const;

export const schema = (t: (key: string) => string) =>
  object({
    name: string(),
    email: string()
      .email(t("Please enter email"))
      .required(t("Email must be a valid email")),
    location: string(),
    bio: string(),
    socials: array().of(string()).default([]),
  });

export type Schema = InferType<ReturnType<typeof schema>>;

type UserProfile = {
  id: string;
  type: string;
  attributes: {
    email: string;
    user_name: string | null;
    bio: string | null;
    add_location: string | null;
    twitter_handle: string | null;
    facebook_profile: string | null;
    linkedin_profile: string | null;
    image_url: string | null;
  };
};

interface S {
  showEdit: boolean;
  loading: boolean;
  userProfile: UserProfile | null;
  avatar: string | File | null;
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {
  id: any;
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetSuperadminProfileCallId = "";
  apiUpdateSuperadminProfileCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loading: false,
      userProfile: null,
      showEdit: false,
      avatar: null,
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getSuperAdminProfile = () => {
    this.setState({ loading: true });

    const accountId = window.localStorage.getItem("account_id");
    const isKitchenUser =
      window.localStorage.getItem("account_type") === "KitchenAccount";

    const token = window.localStorage.getItem("token");
    const endPoint =
      "account_block/accounts/" +
      accountId +
      (isKitchenUser ? "/show_kitchen_account" : "/show_super_admin");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "get");
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    this.apiGetSuperadminProfileCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  updateSupserAdminProfile = (values: Schema) => {
    this.setState({ loading: true });

    const accountId = window.localStorage.getItem("account_id");
    const token = window.localStorage.getItem("token");

    const isKitchenUser =
      window.localStorage.getItem("account_type") === "KitchenAccount";

    const endPoint =
      "account_block/accounts/" +
      accountId +
      (isKitchenUser ? "/update_kitchen_account" : "/update_super_admin");

    const header = {
      token,
    };

    const body = new FormData();
    body.append("data[bio]", values.bio);
    body.append("data[add_location]", values.location);
    body.append("data[email]", values.email);
    body.append("data[user_name]", values.name);
    body.append("data[facebook_profile]", values.socials[0]);
    body.append("data[linkedin_profile]", values.socials[1]);
    body.append("data[twitter_handle]", values.socials[2]);

    if (this.state.avatar instanceof File) {
      body.append("data[image]", this.state.avatar);
    } else if (this.state.avatar === null) {
      body.append("data[is_remove_image]", "true");
    }

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "patch");
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    this.apiUpdateSuperadminProfileCallId = message.messageId;

    runEngine.sendMessage(message.id, message);
  };

  async componentDidMount(): Promise<void> {
    this.getSuperAdminProfile();
  }

  handleGetSuperAdminProfileResponse = (responseJson: any) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      this.setState({
        userProfile: responseJson.data,
        avatar: responseJson.data.attributes.image_url,
      });
    }
  };

  handleUpdateSupserAdminProfileResponse = (responseJson: any) => {
    this.setState({ loading: false });

    if (responseJson && responseJson.data) {
      this.showSnackbar(
        "Profile successfully updated",
        CustomSnackbarType.Success
      );
      this.setState({ showEdit: false, loading: false }, () => {
        this.getSuperAdminProfile();
      });

      const message = new Message(
        getName(MessageEnum.UserUpdateProfileMessage)
      );

      runEngine.sendMessage(message.id, message);
    }

    if (responseJson && responseJson.errors) {
      this.showSnackbar(responseJson.errors[0], CustomSnackbarType.Error);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiGetSuperadminProfileCallId) {
        this.handleGetSuperAdminProfileResponse(responseJson);
      }

      if (apiRequestCallId === this.apiUpdateSuperadminProfileCallId) {
        this.handleUpdateSupserAdminProfileResponse(responseJson);
      }
    }
  }

  handleSubmit = (values: Schema) => {
    this.updateSupserAdminProfile(values);
  };

  getFormInitialValues = () => {
    const userProfile = this.state.userProfile;
    if (!userProfile) {
      return schema(this.props.t).default();
    }

    const { twitter_handle, facebook_profile, linkedin_profile } =
      userProfile.attributes;

    const socials = [
      facebook_profile || "",
      twitter_handle || "",
      linkedin_profile || "",
    ];

    return {
      bio: userProfile.attributes.bio || "",
      email: userProfile.attributes.email || "",
      location: userProfile.attributes.add_location || "",
      socials,
      name: userProfile.attributes.user_name || "",
    };
  };

  handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      this.setState({ avatar: files[0] });
    }
  };

  handleImageRemove = () => {
    this.setState({ avatar: null });
  };

  getAvatarUrl = () => {
    if (!this.state.avatar) {
      return "";
    } else if (typeof this.state.avatar === "string") {
      return this.state.avatar;
    } else {
      return URL.createObjectURL(this.state.avatar);
    }
  };

  handleCopyEmail = async () => {
    if (!this.state.userProfile) {
      return;
    }
    try {
      await window.navigator.clipboard.writeText(
        this.state.userProfile.attributes.email
      );
      this.showSnackbar(
        "Email was copied to clipboard.",
        CustomSnackbarType.Success
      );
    } catch (error) {
      this.showSnackbar("Error! Please try again!", CustomSnackbarType.Error);
    }
  };

  handleOpenSocialMediaLink = (socialMediaLink: string) => {
    if (
      socialMediaLink === "No Facebook" ||
      socialMediaLink === "No Twitter" ||
      socialMediaLink === "No Linkedin"
    ) {
      return;
    }

    window.open(socialMediaLink, "_blank", "noopener noreferrer");
  };

  showEdit = () => {
    this.setState({ showEdit: true });
  };

  hideEdit = () => {
    this.setState({
      showEdit: false,
      avatar: this.state.userProfile
        ? this.state.userProfile.attributes.image_url
        : null,
    });
  };

  extractUserSocialLinks = () => {
    const socialLinks = this.state.userProfile
      ? [
          {
            id: this.state.userProfile.attributes.email,
            type: ENUM_SOCIAL_TYPE.EMAIL,
          },
          {
            id:
              this.state.userProfile.attributes.facebook_profile ||
              "No Facebook",
            type: ENUM_SOCIAL_TYPE.FACEBOOK,
          },
          {
            id:
              this.state.userProfile.attributes.linkedin_profile ||
              "No Linkedin",
            type: ENUM_SOCIAL_TYPE.LINKEDIN,
          },
          {
            id:
              this.state.userProfile.attributes.twitter_handle || "No Twitter",
            type: ENUM_SOCIAL_TYPE.TWITTER,
          },
        ]
      : [];

    return socialLinks;
  };

  showSnackbar = (message: string, type: CustomSnackbarType) => {
    this.setState({
      snackbar: {
        message,
        open: true,
        type,
      },
    });
  };

  hideSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
    });
  };
}

// Customizable Area End
