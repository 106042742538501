import { WithStyles, withStyles } from "@material-ui/core/styles";
import { Alert, AlertProps } from "@material-ui/lab";
import React from "react";

type Props = {} & AlertProps & WithStyles;

const CustomPaymentadmin2FinanceAlert = withStyles((theme) => {
  return {
    root: {
      backgroundColor: "rgba(255, 102, 102, 0.05)",
      borderInlineStart: "4px solid #FF6666",
      borderRadius: 4,
      padding: "12px 16px",
    },
    message: {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      lineHeight: 18 / 12,
      textAlign: "start",
      color: "rgba(51, 51, 51,1)",
    },
    icon: {
      display: "none",
    },
  };
})(({ classes, ...other }: Props) => (
  <Alert
    classes={{
      root: classes.root,
      message: classes.message,
      icon: classes.icon,
    }}
    {...other}
  />
));

export default CustomPaymentadmin2FinanceAlert;
