// Customizable Area Start
import React from "react";

import Servicespecificsettingsadmin2OpeningTimesAdminController, {
  Props,
} from "./Servicespecificsettingsadmin2OpeningTimesAdminController.web";
import {
  Box,
  InputAdornment,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import CustomButton from "../../../components/src/CustomButton.web";
import { icDownload } from "./assets";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomMenuItemWeb from "../../../components/src/CustomMenuItem.web";
import CustomInputFieldWeb from "../../../components/src/CustomInputField.web";
import { Search } from "@material-ui/icons";
import CustomTable from "../../../components/src/CustomTable";
import RestaurantsTableActionMenu from "../../../components/src/servicesspecificsettingsadmin2/RestaurantsTableActionMenu.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomOpeningTimesEditDialogWeb from "../../../components/src/servicesspecificsettingsadmin2/CustomOpeningTimesEditDialog.web";

export class Servicespecificsettingsadmin2OpeningTimesAdmin extends Servicespecificsettingsadmin2OpeningTimesAdminController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const {
      requestNames,
      optionsRestaurant,
      optionsTime,
      selectRestaurant,
      selectTime,
      restaurants,
      pagination,
      currentEditValue,
    } = this.state;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <>
        <Container>
          <Box display={"flex"} gridColumnGap={8} className="actions" dir={dir}>
            <CustomSelect
              id="selectTime"
              data-test-id="selectTime"
              variant="outlined"
              fullWidth
              value={selectTime}
              onChange={(event) => {
                this.setState({ selectTime: event.target.value as string });
              }}
              dir={dir}
              inputProps={{ dir: dir, placeholder: t("Time period") }}
            >
              {optionsTime.map((option) => (
                <CustomMenuItemWeb
                  value={option.value}
                  key={option.id}
                  dir={dir}
                >
                  {t(option.name)}
                </CustomMenuItemWeb>
              ))}
            </CustomSelect>

            <CustomSelect
              id="selectRestaurant"
              data-test-id="selectRestaurant"
              variant="outlined"
              fullWidth
              value={selectRestaurant}
              onChange={(event) => {
                this.setState({
                  selectRestaurant: event.target.value as string,
                });
              }}
              dir={dir}
              inputProps={{ dir: dir, placeholder: t("Restaurants") }}
            >
              {optionsRestaurant.map((option) => (
                <CustomMenuItemWeb
                  value={option.value}
                  key={option.id}
                  dir={dir}
                >
                  {t(option.name)}
                </CustomMenuItemWeb>
              ))}
            </CustomSelect>

            <CustomInputFieldWeb
              value={requestNames}
              onChange={(event) => {
                this.setState({ requestNames: event.target.value });
              }}
              variant="outlined"
              placeholder={t("Request Names")}
              dir={dir}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
                onKeyDown: this.handleKeydown,
              }}
            />

            <CustomButton
              variant="contained"
              color="primary"
              startIcon={<img src={icDownload} />}
            >
              {t("Download Report")}
            </CustomButton>
          </Box>

          <Box className="content">
            <TableContainer>
              <CustomTable dir={dir}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Restaurant Name")}</TableCell>
                    <TableCell>{t("Branch")}</TableCell>
                    <TableCell>{t("Day of Week")}</TableCell>
                    <TableCell>{t("Opening Time")}</TableCell>
                    <TableCell>{t("Closing Time")}</TableCell>
                    <TableCell>{t("Actions")}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {restaurants.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={7} style={{ textAlign: "center" }}>
                        {t("No restaurants")}
                      </TableCell>
                    </TableRow>
                  )}
                  {restaurants.map((restaurant) => (
                    <TableRow key={restaurant.id}>
                      <TableCell>{restaurant.restaurant_name}</TableCell>
                      <TableCell>{restaurant.branch}</TableCell>
                      <TableCell>{t(restaurant.day_of_week)}</TableCell>
                      <TableCell>
                        {restaurant.opening_time.split(" ")[0]}{" "}
                        {t(restaurant.opening_time.split(" ")[1])}
                      </TableCell>
                      <TableCell>
                        {restaurant.closing_time.split(" ")[0]}{" "}
                        {t(restaurant.closing_time.split(" ")[1])}
                      </TableCell>
                      <TableCell>
                        <RestaurantsTableActionMenu
                          onEdit={() => this.openEditForm(restaurant)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </CustomTable>
            </TableContainer>

            <Box className="pagination" dir={dir}>
              <CustomPagination
                data-testid="pagination"
                variant="text"
                count={pagination.totalPages}
                page={pagination.page}
                total={pagination.totalCount}
                limit={pagination.limit}
                onChange={(event, page) => this.changePage(page)}
                showOffset
                fullWidth
                dir={dir}
              />
            </Box>
          </Box>
        </Container>

        <CustomOpeningTimesEditDialogWeb
          open={Boolean(currentEditValue)}
          onClose={this.closeEditForm}
          initValue={currentEditValue}
        />
      </>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    "& .actions": {
      "& > button": {
        marginInlineStart: "auto",
      },
      "& >:nth-child(1), & >:nth-child(2)": {
        maxWidth: 180,
        width: "100%",
      },
      "& input::placeholder": { opacity: 1 },
    },
    "& .content": {
      border: "1px solid #00000014",
      padding: 16,
      marginBlockStart: 24,
    },
    "& .pagination": {
      marginBlockStart: 16,
      paddingBlockStart: 20,
      borderTop: "1px solid #00000014",
      marginInline: -16,
      paddingInline: 16,
    },
  };
});

export default withTranslation()(
  Servicespecificsettingsadmin2OpeningTimesAdmin
);
// Customizable Area End
