// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { WithTranslation } from "react-i18next";
import { KeyboardEvent } from "react";
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";

export const configJSON = require("./config");

export interface Props extends WithTranslation {}

type Restaurant = {
  id: string;
  restaurant_name: string;
  branch: string;
  day_of_week: string;
  opening_time: string;
  closing_time: string;
};

interface S {
  requestNames: string;
  optionsTime: CustomFilterMenu[];
  optionsRestaurant: CustomFilterMenu[];
  selectTime: string;
  selectRestaurant: string;
  restaurants: Restaurant[];
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  currentEditValue: Restaurant | null;
}

interface SS {
  id: any;
}

export default class Servicespecificsettingsadmin2OpeningTimesAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.receive = this.receive.bind(this);

    this.state = {
      requestNames: "",
      optionsRestaurant: [{ id: "0", value: "all", name: "All" }],
      optionsTime: [{ id: "0", value: "last_7_days", name: "Last 7 days" }],
      selectTime: "last_7_days",
      selectRestaurant: "all",
      pagination: {
        page: 1,
        totalPages: 8,
        totalCount: 48,
        limit: 12,
      },
      currentEditValue: null,
      restaurants: [
        {
          id: "1",
          restaurant_name: "Pizza Hut",
          day_of_week: "Monday",
          branch: "Mecca",
          opening_time: "09:00 AM",
          closing_time: "10:00 PM",
        },
        {
          id: "2",
          branch: "Mecca",
          day_of_week: "Tuesday",
          opening_time: "09:00 AM",
          restaurant_name: "Burger King",
          closing_time: "10:00 PM",
        },
        {
          id: "3",
          restaurant_name: "Burger King",
          branch: "Riyadh",
          day_of_week: "Wednesday",
          opening_time: "09:00 AM",
          closing_time: "10:00 PM",
        },
        {
          id: "4",
          restaurant_name: "Burger Palace",
          branch: "Mecca",
          day_of_week: "Thursday",
          opening_time: "09:00 AM",
          closing_time: "10:00 PM",
        },
        {
          id: "5",
          branch: "Riyadh",
          restaurant_name: "Burger King",
          opening_time: "09:00 AM",
          day_of_week: "Friday",
          closing_time: "10:00 PM",
        },
        {
          id: "6",
          restaurant_name: "Burger King",
          branch: "Mecca",
          day_of_week: "Tuesday",
          opening_time: "09:00 AM",
          closing_time: "10:00 PM",
        },
        {
          id: "7",
          restaurant_name: "Burger Palace",
          branch: "Riyadh",
          closing_time: "10:00 PM",
          opening_time: "09:00 AM",
          day_of_week: "Tuesday",
        },
        {
          id: "8",
          restaurant_name: "Pizza Hut",
          branch: "Riyadh",
          day_of_week: "Tuesday",
          opening_time: "09:00 AM",
          closing_time: "10:00 PM",
        },
        {
          restaurant_name: "Burger Palace",
          id: "9",
          day_of_week: "Tuesday",
          opening_time: "09:00 AM",
          branch: "Mecca",
          closing_time: "10:00 PM",
        },
        {
          branch: "Riyadh",
          day_of_week: "Tuesday",
          opening_time: "09:00 AM",
          id: "10",
          restaurant_name: "Pizza Hut",
          closing_time: "10:00 PM",
        },
      ],
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleKeydown = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key !== "Enter") {
      return;
    }

    this.setState({
      requestNames: event.currentTarget.value,
    });
  };

  changePage = (page: number) => {
    this.setState({
      pagination: {
        ...this.state.pagination,
        page,
      },
    });
  };

  openEditForm = (restaurant: Restaurant) => {
    this.setState({ currentEditValue: restaurant });
  };

  closeEditForm = () => {
    this.setState({ currentEditValue: null });
  };
}

// Customizable Area End
