import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { WithStyles } from "@material-ui/core";
import { KitchenOrderAcceptStatus } from "../../../components/src/KitchenOrderAcceptStatusMenu.web";
import { KitchenOrderPaymentStatus } from "../../../components/src/KitchenOrderPaymentStatusTag.web";
import {
  KitchenOrder,
  KitchenOrderDish,
  KitchenOrderDishStatus,
} from "../../../components/src/KitchenOrderTableRow.web";
import {
  KitchenOrderCatalogue,
  KitchenOrderChangeCategory,
} from "../../../components/src/KitchenOrderChangeAccordion.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { KitchenOrderSummaryStatus } from "../../../components/src/KitchenOrderSummaryDrawer.web";
import { WithTranslation } from "react-i18next";

export enum KitchenOrderDrawerType {
  Summary = "summary",
  ChangeItem = "change_item",
}

export const defaultSelectedDish = {
  id: "",
  name: "",
  available: "Available",
  image: "",
  price: 0,
  status: KitchenOrderDishStatus.Accept,
  orderSummaryStatus: KitchenOrderSummaryStatus.Accept,
  quantity: 0,
  subtotal: 0,
  message: "",
};

export const defaultSelectedOrder = {
  id: "",
  status: "Cancel",
  orderId: "",
  time: new Date(),
  paymentStatus: KitchenOrderPaymentStatus.Online,
  restaurantName: "",
  orderAcceptStatus: KitchenOrderAcceptStatus.Approved,
  orderType: "",
  scheduledTime: "",
  orderDetailData: [],
  subtotal: 0,
};
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  timeFilter: number | string;
  statusFilter: string | number;
  orderIdSearch: string;
  orders: KitchenOrder[];
  orderDrawer: {
    open: KitchenOrderDrawerType | null;
    selectedOrder: KitchenOrder;
    categories: KitchenOrderChangeCategory[];
    selectedDish: KitchenOrderDish;
    loading: boolean;
  };
  loading: boolean;
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  drawerSnackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  orderStatusOptions: {
    id: string;
    name: string;
    value: number | string;
  }[];

  statusFilterOptions: {
    id: string;
    name: string;
    value: number | string;
  }[];

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class KitchenOrderManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputRef: React.RefObject<HTMLInputElement>;

  fetchOrdersApiId: string = "";
  fetchOrderStatusesApiId: string = "";
  changeAcceptStatusApiId: string = "";
  acceptOrderStatusApiId: string = "";
  openOrderChangeDrawerApiId: string = "";
  addCatalogueApiId: string = "";
  updateOrderItemStatusApiId: string = "";
  downloadReportApiId: string = "";

  timeOptions = [
    {
      id: uuidv4(),
      name: "Today",
      value: 1,
    },

    {
      id: uuidv4(),
      name: "Yesterday",
      value: 2,
    },

    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },

    {
      id: uuidv4(),
      name: "Last 30 days",
      value: 30,
    },

    {
      id: uuidv4(),
      name: "All",
      value: "",
    },
  ];

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      timeFilter: "",
      statusFilter: "all",
      orderIdSearch: "",
      orders: [],
      orderDrawer: {
        open: null,
        categories: [],
        selectedDish: defaultSelectedDish,
        selectedOrder: defaultSelectedOrder,
        loading: false,
      },
      loading: false,
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      drawerSnackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      orderStatusOptions: [],

      statusFilterOptions: [
        {
          id: uuidv4(),
          name: "Order Status",
          value: "all",
        },
      ],

      pagination: {
        page: 1,
        limit: 8,
        totalPages: 0,
        totalCount: 0,
      },
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.inputRef = React.createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start

    this.fetchOrders();
    this.fetchOrderStatuses();
    // Customizable Area End
  }

  // Customizable Area Start
  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  };

  changeStatusFilter = (statusFilter: string | number) => {
    this.setState(
      {
        statusFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  };

  openOrderSummaryDrawer = (selectedOrder: KitchenOrder) => {
    this.setState({
      orderDrawer: {
        ...this.state.orderDrawer,
        selectedOrder: JSON.parse(JSON.stringify(selectedOrder)),
        open: KitchenOrderDrawerType.Summary,
      },
    });
  };

  closeOrderDrawer = () => {
    this.setState({
      orderDrawer: {
        categories: [],
        selectedDish: defaultSelectedDish,
        selectedOrder: defaultSelectedOrder,
        loading: false,
        open: null,
      },
    });
  };

  closeOrderChangeDrawer = () => {
    this.setState({
      orderDrawer: {
        ...this.state.orderDrawer,
        open: KitchenOrderDrawerType.Summary,
      },
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  fetchOrders = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchOrdersApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders?per_page=${
        this.state.pagination.limit
      }&page=${this.state.pagination.page}&order_id=${
        this.state.orderIdSearch
      }&days_ago=${this.state.timeFilter}&order_status_id=${
        this.state.statusFilter === "all" ? "" : this.state.statusFilter
      }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchOrders = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const orders = responseJson.data.map((order: any) => {
      const {
        id,
        status,
        order_number,
        created_at,
        order_status_id,
        sub_total_price,
        order_items,
        order_type,
        scheduled_time,
        restaurant_name,
        payment_status,
      } = order.attributes;

      return {
        id,
        status: status.name,
        orderId: order_number,
        time: created_at,
        paymentStatus: payment_status,
        restaurantName: restaurant_name,
        orderAcceptStatus: order_status_id,
        orderType: order_type,
        scheduledTime: scheduled_time,
        orderDetailData: order_items.map((item: any) => {
          const { quantity, catalogue, total_price, decline_reason } =
            item.attributes;
          const { name, image_url, availability, price } = catalogue.attributes;

          return {
            id: item.id,
            name,
            available: availability,
            image: image_url,
            price,
            quantity,
            status: item.attributes.status,
            orderSummaryStatus: item.attributes.status,
            subtotal: total_price,
            message: decline_reason,
          };
        }),
        subtotal: sub_total_price,
      };
    });

    const pagination = {
      limit: 8,
      page: responseJson.pagination.current_page,
      totalCount: responseJson.pagination.total_count,
      totalPages: responseJson.pagination.total_pages,
    };

    this.setState({
      orders,
      pagination,
    });
  };

  fetchOrderStatuses = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchOrderStatusesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/order_statuses`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchOrderStatuses = async (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Fetch order statuses failed! Please try again"
      );

      return;
    }

    const orderStatusOptions = responseJson.map(
      (status: { id: string | number; name: string }) => ({
        id: status.id,
        name: status.name,
        value: status.id,
      })
    );

    const statusFilterOptions = JSON.parse(
      JSON.stringify(this.state.statusFilterOptions)
    );

    orderStatusOptions.forEach(
      (option: {
        id: string | number;
        name: string;
        value: string | number;
      }) => {
        statusFilterOptions.push(option);
      }
    );

    this.setState({
      orderStatusOptions,
      statusFilterOptions,
    });
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  };

  handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        orderIdSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  };

  clearSearchOrderId = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        orderIdSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  };

  onChangeAcceptStatus = async (
    orderId: string | number,
    statusId: string | number
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();

    formData.append("order_status_id", statusId.toString());

    this.changeAcceptStatusApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${orderId}/update_order_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ loading: true });
  };

  handleChangeAcceptStatus = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Fetch order statuses failed! Please try again"
      );

      return;
    }

    this.fetchOrders();
  };

  onAcceptOrder = async (orderId: string | number) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.acceptOrderStatusApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/${orderId}/accept_order`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ loading: true });
  };

  handleAcceptOrder = async (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Fetch order statuses failed! Please try again"
      );

      return;
    }

    this.fetchOrders();
  };

  openOrderChangeDrawer = async (selectedDish: KitchenOrderDish) => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.openOrderChangeDrawerApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues/categorized_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      orderDrawer: {
        ...this.state.orderDrawer,
        selectedDish: JSON.parse(JSON.stringify(selectedDish)),
        open: KitchenOrderDrawerType.ChangeItem,
        loading: true,
      },
    });
  };

  handleOpenOrderChangeDrawer = (responseJson: any) => {
    this.setState({
      orderDrawer: {
        ...this.state.orderDrawer,
        loading: false,
      },
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const categories = responseJson.data.map((category: any) => ({
      id: category.id,
      name: category.attributes.name,
      image: category.attributes.image_url,
      catalogues: category.attributes.catalogues.map((catalogue: any) => ({
        id: catalogue.id,
        name: catalogue.name,
        available: catalogue.availability,
        image: catalogue.image_url,
        price: catalogue.price,
      })),
    }));

    this.setState({
      orderDrawer: {
        ...this.state.orderDrawer,
        open: KitchenOrderDrawerType.ChangeItem,
        categories,
      },
    });
  };

  addCatalogue = async (catalogue: KitchenOrderCatalogue) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();

    formData.append("catalogue_id", catalogue.id);

    this.addCatalogueApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/order_items/${this.state.orderDrawer.selectedDish.id}/update_catalogue`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ loading: true });
  };

  handleAddCatalogue = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Fetch order statuses failed! Please try again"
      );

      return;
    }

    const { id, catalogue, quantity, status } = responseJson.data.attributes;

    const { name, availability, image_url, price, decline_reason } =
      catalogue.attributes;

    const changedDish = {
      id,
      name,
      available: availability,
      image: image_url,
      price,
      quantity,
      subtotal: price * quantity,
      message: decline_reason,
      status,
      orderSummaryStatus: KitchenOrderSummaryStatus.Replace,
    };

    const selectedOrder = { ...this.state.orderDrawer.selectedOrder };

    const index = selectedOrder.orderDetailData.findIndex(
      (detail) => detail.id.toString() === id.toString()
    );

    if (index > -1) {
      selectedOrder.orderDetailData[index] = changedDish;
    }

    this.setState(
      {
        orderDrawer: {
          ...this.state.orderDrawer,
          selectedDish: defaultSelectedDish,
          selectedOrder,
          open: KitchenOrderDrawerType.Summary,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  };

  updateOrderItemStatus = async (
    id: string,
    status: string,
    declineReason: string
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateOrderItemStatusApiId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("status", status);
    formData.append("decline_reason", declineReason);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/order_items/${id}/update_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUpdateOrderItemStatus = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Update order item status failed! Please try again"
      );

      return;
    }

    this.fetchOrders();
  };

  downloadReport = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downloadReportApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_order_management/orders/generate_pdf.pdf`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleDownloadReport = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Update order item status failed! Please try again"
      );

      return;
    }

    const link = document.createElement("a");
    link.href = responseJson.download_url;
    link.download = "order-report.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  openDrawerSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      drawerSnackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeDrawerSnackbar = () => {
    this.setState({
      drawerSnackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  notifyUser = () => {
    this.openDrawerSnackbar(
      CustomSnackbarType.Success,
      "User notified successfully!"
    );
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchOrdersApiId:
        this.handleFetchOrders(responseJson);
        break;

      case this.fetchOrderStatusesApiId:
        this.handleFetchOrderStatuses(responseJson);
        break;

      case this.changeAcceptStatusApiId:
        this.handleChangeAcceptStatus(responseJson);
        break;

      case this.acceptOrderStatusApiId:
        this.handleAcceptOrder(responseJson);
        break;

      case this.openOrderChangeDrawerApiId:
        this.handleOpenOrderChangeDrawer(responseJson);

        break;

      case this.addCatalogueApiId:
        this.handleAddCatalogue(responseJson);

        break;

      case this.updateOrderItemStatusApiId:
        this.handleUpdateOrderItemStatus(responseJson);

        break;

      case this.downloadReportApiId:
        this.handleDownloadReport(responseJson);

        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
