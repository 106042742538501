import { Box, makeStyles } from "@material-ui/core";
import { icArrowUp } from "../../../blocks/analytics/src/assets";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

type Props = {
  targetNumber: number;
  ordersNumber: number;
  data: { name: string; revenue: number }[];
};

function CustomAnalyticsAdminOrdersDelayAndPrepChart({
  targetNumber,
  ordersNumber,
  data,
}: Props) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box dir={dir}>
        <h3 className={classes.title}>
          {ordersNumber} {t("Orders")}{" "}
          <img src={icArrowUp} width={13} height={13} />
        </h3>
        <p className={classes.subTitle}>
          {t("Order delay and Preparation time")}
        </p>
      </Box>
      <ResponsiveContainer maxHeight={162} className={classes.chartContainer}>
        <>
          <Box
            dir={dir}
            textAlign={"start"}
            paddingBottom={"5px"}
            fontFamily={"Inter"}
            fontSize={"0.625rem"}
            fontWeight={700}
            borderBottom="0.5px solid #B1B1B1"
          >
            <span className={classes.targetText}>{t("Target")}:</span>{" "}
            <span className={classes.targetNumber}>
              {t("SAR")} {targetNumber}
            </span>
          </Box>
          <AreaChart width={400} height={120} data={data} margin={{ top: -5 }}>
            <defs>
              <linearGradient
                id="bgGrad"
                x1="152.705"
                y1="12.0192"
                x2="152.308"
                y2="83.3639"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F87171" />
                <stop offset="100%" stopColor="#FFC8C8" />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" hide reversed={rtl} />
            <YAxis hide max={targetNumber + targetNumber * 0.1} />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <Box fontFamily={"Inter"} dir={dir}>
                      {t("SAR")} {payload[0].value}
                    </Box>
                  );
                }

                return null;
              }}
              cursor={{
                stroke: "#FF6666",
                strokeWidth: 0.5,
              }}
            />

            <Area
              type="monotone"
              dataKey="revenue"
              stroke="#FF6666"
              fill="url(#bgGrad)"
              fillOpacity={"0.1"}
              activeDot={{ fill: "#FFFFFF", stroke: "#FF6666" }}
            />
          </AreaChart>
        </>
      </ResponsiveContainer>
    </Box>
  );
}

export default CustomAnalyticsAdminOrdersDelayAndPrepChart;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginInline: "auto",
      maxWidth: 500,
    },
    chartContainer: {
      "& svg.recharts-surface": {
        overflow: "visible",
      },
    },
    targetText: {
      color: "#939393",
    },
    targetNumber: {
      color: "#333333",
    },

    title: {
      color: "#2E2E30",
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(30),
      lineHeight: 36.31 / 30,
      marginBlock: 0,
    },
    subTitle: {
      marginBlockEnd: 44,
      fontWeight: 700,
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(10),
      color: "#939393",
      lineHeight: 12.1 / 10,
      marginBlock: 0,
    },
  };
});
