import React from "react";
import { useTranslation } from "react-i18next";
import CustomTypography from "../CustomTypography.web";
import { Box } from "@material-ui/core";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a;
const invlerp = (x: number, y: number, a: number) => clamp((a - x) / (y - x));
const clamp = (a: number, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const range = (x1: number, y1: number, x2: number, y2: number, a: number) =>
  lerp(x2, y2, invlerp(x1, y1, a));

type CustomAnalyticsAdminCustomersCompareChartProps = {
  oldCustomers: number;
  newCustomers: number;
};

function CustomAnalyticsAdminCustomersCompareChart({
  oldCustomers,
  newCustomers,
}: CustomAnalyticsAdminCustomersCompareChartProps) {
  const { t, i18n } = useTranslation();
  const rtl = i18n.language === "ar";

  const classes = useStyles();

  const total = oldCustomers + newCustomers;

  const newCustomersValue = range(0, total, 0, 80, newCustomers);

  return (
    <Box width={"100%"} maxWidth={276}>
      <CustomTypography className={classes.chartTitle}>
        {t("New Vs Returning Customers")}
      </CustomTypography>

      <div className={classes.chartContainer}>
        <div
          style={{
            transform: `${rtl ? "" : "rotateY(180deg)"} rotate(216.5deg)`,
          }}
        >
          <CircularProgress
            variant="determinate"
            className={classes.bottom}
            classes={{
              circle: classes.circle,
            }}
            size={180}
            thickness={3}
            value={80}
          />
          <CircularProgress
            variant="determinate"
            disableShrink
            className={classes.top}
            classes={{
              circle: classes.circle,
            }}
            size={180}
            thickness={6}
            value={newCustomersValue}
          />
        </div>
        <div className={classes.total}>
          <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              opacity="0.9"
              cx="16.0005"
              cy="16.5889"
              r="15.7476"
              fill="#ECEAF8"
            />
            <path
              d="M16.3389 8.59082C17.3997 8.59082 18.4171 9.01225 19.1673 9.76239C19.9174 10.5125 20.3389 11.53 20.3389 12.5908C20.3389 13.6517 19.9174 14.6691 19.1673 15.4192C18.4171 16.1694 17.3997 16.5908 16.3389 16.5908C15.278 16.5908 14.2606 16.1694 13.5104 15.4192C12.7603 14.6691 12.3389 13.6517 12.3389 12.5908C12.3389 11.53 12.7603 10.5125 13.5104 9.76239C14.2606 9.01225 15.278 8.59082 16.3389 8.59082ZM16.3389 18.5908C20.7589 18.5908 24.3389 20.3808 24.3389 22.5908V24.5908H8.33887V22.5908C8.33887 20.3808 11.9189 18.5908 16.3389 18.5908Z"
              fill="#FF6666"
            />
          </svg>
          <span>{total}</span>
        </div>
      </div>

      <ul className={classes.legend}>
        <li>
          <Box
            bgcolor={"#E9EDF0"}
            width={10}
            height={10}
            borderRadius={"50%"}
          />
          {t("Old Customer Orders")}

          <span>{oldCustomers}</span>
        </li>
        <li>
          <Box
            bgcolor={"#FF6666"}
            width={10}
            height={10}
            borderRadius={"50%"}
          />
          {t("New Customer Orders")}

          <span>{newCustomers}</span>
        </li>
      </ul>
    </Box>
  );
}

export default CustomAnalyticsAdminCustomersCompareChart;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartTitle: {
      color: "#2E2E30",
      fontWeight: 700,
      fontFamily: "Inter",
    },
    total: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& span": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(30),
        lineHeight: 36 / 30,
        color: "#404040",
      },
    },
    chartContainer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBlockStart: 20,
    },
    bottom: {
      color: "#E9EDF0",
    },
    top: {
      color: "#ff6b6b",
      animationDuration: "550ms",
      position: "absolute",
      left: 0,
    },
    circle: {
      strokeLinecap: "round",
    },
    legend: {
      marginBlockStart: 30,
      listStyle: "none",

      "& li": {
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "14.52px",
        color: "#767676",
        display: "flex",
        gap: 5,
        alignItems: "center",
        "& span": { color: "#404040" },
      },
    },
  })
);
