import React, { ReactNode } from "react";

import {
  AppBar,
  Box,
  Drawer,
  Theme,
  Toolbar,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomTypography from "./CustomTypography.web";

const bgKitchenNav = require("./bg_kitchen_nav.png");
const bgKitchenNavReverse = require("./bg_kitchen_nav_reverse.png");

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "flex",

      "&.rtl": {
        flexDirection: "row-reverse",
      },
    },

    appBar: {
      width: "100%",

      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - 265px)`,
        marginLeft: 265,
      },

      "&.rtl": {
        [theme.breakpoints.up("sm")]: {
          marginLeft: 0,
          marginRight: 265,
        },
      },

      "&.MuiAppBar-colorDefault": {
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
      },
    },

    appBarHead: {
      position: "relative",
      height: 9,

      "&::before": {
        position: "absolute",
        content: '""',
        top: 0,
        left: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FF6666",
      },

      "&::after": {
        position: "absolute",
        content: '""',
        top: 0,
        right: 0,
        width: "50%",
        height: 9,
        backgroundColor: "#FFCC00",
      },
    },

    appBarHeadToolbar: {
      flexWrap: "wrap",
      gap: 8,

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      [theme.breakpoints.down("xs")]: {
        paddingTop: 10,
        paddingBottom: 10,
      },

      "&.rtl": {
        direction: "rtl",

        [theme.breakpoints.up("lg")]: {
          paddingRight: 10,
          paddingLeft: 80,
        },
      },
    },

    pageName: {
      flex: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    main: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflow: "hidden",

      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 265px)",
      },

      [theme.breakpoints.up("lg")]: {
        paddingRight: 80,
      },

      "&.rtl": {
        [theme.breakpoints.up("lg")]: {
          paddingRight: 10,
          paddingLeft: 80,
        },
      },
    },

    mainToolbar: {
      [theme.breakpoints.down("xs")]: {
        height: 125,
      },
    },

    drawerMobile: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },

    drawerMobilePaper: {
      width: 265,
      backgroundColor: "#FFFBF4",
      minHeight: "100vh",

      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    drawerWeb: {
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    drawerWebPaper: {
      boxSizing: "border-box",
      width: 265,
      backgroundColor: "#FFFBF4",
    },

    nav: {
      [theme.breakpoints.up("sm")]: {
        width: 265,
        flexShrink: 0,
      },
    },
  });

interface NavMenuComponentProps extends WithTranslation, WithStyles {
  ToolbarTop?: ReactNode;
}

const navMenuStyles = (theme: Theme) =>
  createStyles({
    navMenu: {
      background: `url(${bgKitchenNav})`,
      backgroundRepeat: "no-repeat",
      height: "100%",
      backgroundPositionY: "100%",
      backgroundSize: "contain",

      "&.rtl": {
        background: `url(${bgKitchenNavReverse})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "100%",
        backgroundSize: "contain",
        height: "100%",
      },
    },

    navMenuToolbar: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  });

const NavMenuComponent = (props: NavMenuComponentProps) => {
  const { ToolbarTop, i18n, classes } = props;
  const rtl = i18n.language === "ar";

  return (
    <Box className={clsx(classes.navMenu, { ["rtl"]: rtl })}>
      <Toolbar className={classes.navMenuToolbar}>{ToolbarTop}</Toolbar>
    </Box>
  );
};

const NavMenu = withStyles(navMenuStyles)(withTranslation()(NavMenuComponent));

export interface CustomNavigationMenuEmptyProps
  extends WithStyles,
    WithTranslation {
  title: string;

  Content: ReactNode;
  ToolbarTop?: ReactNode;
}

interface CustomNavigationMenuEmptyState {
  mobileOpen: boolean;
  isClosing: boolean;
}

export class CustomNavigationMenuEmpty extends React.Component<
  CustomNavigationMenuEmptyProps,
  CustomNavigationMenuEmptyState
> {
  constructor(props: CustomNavigationMenuEmptyProps) {
    super(props);

    this.state = {
      mobileOpen: false,
      isClosing: false,
    };
  }

  setMobileOpen = (mobileOpen: boolean) => {
    this.setState({
      mobileOpen,
    });
  };

  setIsClosing = (isClosing: boolean) => {
    this.setState({
      isClosing,
    });
  };

  handleDrawerClose = () => {
    this.setIsClosing(true);
    this.setMobileOpen(false);
  };

  handleDrawerTransitionEnd = () => {
    this.setIsClosing(false);
  };

  handleDrawerToggle = () => {
    if (!this.state.isClosing) {
      this.setMobileOpen(!this.state.mobileOpen);
    }
  };

  render() {
    const { classes, i18n, t, title, Content, ToolbarTop } = this.props;

    const { mobileOpen } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, { ["rtl"]: rtl })}
          color="default"
        >
          <Box className={classes.appBarHead} />
          <Toolbar
            className={clsx(classes.appBarHeadToolbar, { ["rtl"]: rtl })}
          >
            <CustomTypography className={classes.pageName} variant="h2">
              {t(title)}
            </CustomTypography>
          </Toolbar>
        </AppBar>

        <Box component="nav" className={classes.nav}>
          <Drawer
            anchor={rtl ? "right" : "left"}
            className={classes.drawerMobile}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={this.handleDrawerTransitionEnd}
            ModalProps={{
              keepMounted: true,
            }}
            classes={{
              paper: classes.drawerMobilePaper,
            }}
            onClose={this.handleDrawerClose}
          >
            <NavMenu data-test-id="drawer-mobile" ToolbarTop={ToolbarTop} />
          </Drawer>

          <Drawer
            anchor={rtl ? "right" : "left"}
            variant="permanent"
            className={classes.drawerWeb}
            classes={{
              paper: classes.drawerWebPaper,
            }}
            open
          >
            <NavMenu data-test-id="drawer-web" ToolbarTop={ToolbarTop} />
          </Drawer>
        </Box>

        <Box
          className={clsx(classes.main, {
            ["rtl"]: rtl,
          })}
          component="main"
        >
          <Toolbar className={classes.mainToolbar} />

          {Content}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomNavigationMenuEmpty));
