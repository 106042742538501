import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";

export enum BusinessTabValue {
  Today = "today",
  Yesterday = "yesterday",
  SevenDays = "7_day",
  ThirtyDays = "30_day",
  All = "all",
}

export interface RestaurantBusiness {
  id: string | number;
  name: string;
  orderCount: number;
  sales: number;
}

export enum BusinessView {
  OutletView = "Outlet View",
  ChartView = "Chart View",
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

export interface LiveOpsMonitorDataProps {
  number: number;
  isGood: boolean;
}

export interface LiveOpsMonitorData {
  offlineOutlets: LiveOpsMonitorDataProps;
  cancelledOrders: LiveOpsMonitorDataProps;
  delayedOrders: LiveOpsMonitorDataProps;
  rating: LiveOpsMonitorDataProps;
}

export interface OperationsHealth {
  name: string;
  title: string;
  data: string | number;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  businessTab: BusinessTabValue;
  businessView: BusinessView;
  restaurantBusinessData: RestaurantBusiness[];
  liveOpsMonitor: LiveOpsMonitorData;

  operationHealthData: {
    vendorCancellations: OperationsHealth,
    offlineTime: OperationsHealth,
    avgPreparationTime: OperationsHealth,
    orderDelayRate: OperationsHealth
  }

  loading: boolean;

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  totalOrders: number;
  totalSales: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardKitchenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  businessSummaryTabs = [
    {
      id: uuidv4(),
      name: "Today",
      value: BusinessTabValue.Today,
    },

    {
      id: uuidv4(),
      name: "Yesterday",
      value: BusinessTabValue.Yesterday,
    },

    {
      id: uuidv4(),
      name: "7 Days",
      value: BusinessTabValue.SevenDays,
    },

    {
      id: uuidv4(),
      name: "30 Days",
      value: BusinessTabValue.ThirtyDays,
    },

    {
      id: uuidv4(),
      name: "All",
      value: BusinessTabValue.All,
    },
  ];

  fetchLiveOpsMonitorApiId: string = "";
  fetchBussinessSummaryApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      businessTab: BusinessTabValue.Today,
      businessView: BusinessView.OutletView,

      restaurantBusinessData: [],

      liveOpsMonitor: {
        offlineOutlets: {
          number: 7,
          isGood: false,
        },

        cancelledOrders: {
          number: 0,
          isGood: true,
        },

        delayedOrders: {
          number: 0,
          isGood: true,
        },

        rating: {
          number: 0,
          isGood: true,
        },
      },

      operationHealthData: {
        vendorCancellations: {
          name: "",
          title: "",
          data: ""
        },
        offlineTime: {
          name: "",
          title: "",
          data: ""
        },
        avgPreparationTime: {
          name: "",
          title: "",
          data: ""
        },
        orderDelayRate: {
          name: "",
          title: "",
          data: ""
        },
      },

      loading: false,

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },

      pagination: {
        page: 1,
        limit: 7,
        totalPages: 0,
        totalCount: 0,
      },

      totalOrders: 0,
      totalSales: 0,
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchLiveOpsMonitor();
    this.fetchBusinessSummary();
    // Customizable Area End
  }

  // Customizable Area Start
  changeBusinessTab = (businessTab: BusinessTabValue) => {
    this.setState(
      {
        businessTab,
      },
      () => {
        this.setState(
          {
            pagination: {
              page: 1,
              limit: 7,
              totalPages: 0,
              totalCount: 0,
            },
          },
          () => {
            this.fetchBusinessSummary();
          }
        );
      }
    );
  };

  changeBusinessView = (businessView: BusinessView) => {
    this.setState({
      businessView,
    });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  fetchLiveOpsMonitor = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchLiveOpsMonitorApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_statisticsreports2/statistics/live_ops_monitor?filter=7_day`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchLiveOpsMonitor = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const {
      cancelled_orders,
      delayed_orders,
      offline_outlets,
      one_star_ratings,
    } = responseJson.data;

    this.setState({
      liveOpsMonitor: {
        offlineOutlets: {
          number: offline_outlets,
          isGood: false,
        },

        cancelledOrders: {
          number: cancelled_orders,
          isGood: true,
        },

        delayedOrders: {
          number: delayed_orders,
          isGood: true,
        },

        rating: {
          number: one_star_ratings,
          isGood: true,
        },
      },
    });
  };

  fetchBusinessSummary = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchBussinessSummaryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_statisticsreports2/statistics/kitchen_business_summary?filter=${this.state.businessTab}&per_page=${this.state.pagination.limit}&page=${this.state.pagination.page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchBusinessSummary = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const { total_orders, total_sales, report, pagination } = responseJson;
    const restaurantBusinessData = report.map((item: any) => ({
      id: uuidv4(),
      name: item.store_name,
      orderCount: item.order_count,
      sales: item.sales,
    }));

    this.setState({
      restaurantBusinessData,
      totalOrders: total_orders,
      totalSales: total_sales,
      pagination: {
        limit: 7,
        page: pagination.current_page,
        totalCount: pagination.total_count,
        totalPages: pagination.total_pages,
      },
    });
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchBusinessSummary();
      }
    );
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchLiveOpsMonitorApiId:
        this.handleFetchLiveOpsMonitor(responseJson);

        break;

      case this.fetchBussinessSummaryApiId:
        this.handleFetchBusinessSummary(responseJson);

        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
