import React from "react";
import { Box, createStyles, WithStyles, withStyles } from "@material-ui/core";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Area,
} from "recharts";
import CustomTypography from "../CustomTypography.web";
import clsx from "clsx";

export interface Operation {
  date: string;
  successful: number;
  unsuccessful: number;
}

export interface S {}

export interface Props extends WithStyles, WithTranslation {
  operationMetric: Operation[];
}

const styles = () =>
  createStyles({
    operationTitle: {
      fontSize: "14px !important",
      textAlign: "center",
      lineHeight: "16.94px !important",
    },

    operationDescription: {
      marginTop: 2,
      fontFamily: "Inter !important",
      textAlign: "center",
      lineHeight: "12.1px !important",
      color: "#2E2E30 !important",
    },

    operationChartLegend: {
      marginTop: 26,
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
    },

    operationChartLegendItem: {
      color: "#767676 !important",
      fontSize: "12px !important",
      display: "flex",
      alignItems: "center",
      gap: 8,

      "&::before": {
        width: 8,
        height: 8,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        boxSizing: "border-box",
      },

      "&.successful": {
        "&::before": {
          backgroundColor: "#FF6666",
        },
      },

      "&.unsuccessful": {
        "&::before": {
          backgroundColor: "#F4A79D",
        },
      },
    },
  });

class CustomAnalyticsOperationMetric extends React.Component<Props, S> {
  render() {
    const { operationMetric, classes } = this.props;

    return (
      <Box>
        <CustomTypography
          variant="subtitle1"
          className={classes.operationTitle}
        >
          Operation Metric
        </CustomTypography>

        <CustomTypography
          variant="body2"
          className={classes.operationDescription}
        >
          Apr 30 - May 30
        </CustomTypography>

        <ResponsiveContainer
          style={{
            marginTop: 30,
          }}
          width="100%"
          height={340}
        >
          <AreaChart data={operationMetric}>
            <defs>
              <linearGradient id="colorSuccessful" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#F4A79D" stopOpacity={1} />
                <stop offset="100%" stopColor="#F4A79D" stopOpacity={0.5} />
              </linearGradient>

              <linearGradient
                id="colorUnsuccessful"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" stopColor="#F4A79D" stopOpacity={0.5} />
                <stop offset="100%" stopColor="#F4A79D" stopOpacity={0.5} />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="date"
              stroke="#B1B1B1"
              strokeWidth="0.29px"
              height={1}
              tickLine={{ stroke: "#8884d8", strokeWidth: 1 }}
              interval={0}
            />

            <YAxis
              tick={false}
              stroke="#B1B1B1"
              strokeWidth="0.29px"
              width={1}
            />

            <Tooltip />

            {operationMetric.map(
              (entry, index) =>
                index % 5 === 0 && (
                  <ReferenceLine
                    x={entry.date}
                    stroke="#B1B1B1"
                    key={`line-${index}`}
                  />
                )
            )}

            <Area
              type="monotone"
              dataKey="successful"
              stroke="#FF7664"
              fill="url(#colorSuccessful)"
              name="Successful Operations"
            />

            <Area
              type="monotone"
              dataKey="unsuccessful"
              stroke="#F4A79D"
              fill="url(#colorUnsuccessful)"
              name="Unsuccessful Operations"
            />
          </AreaChart>
        </ResponsiveContainer>

        <Box className={classes.operationChartLegend}>
          <CustomTypography
            variant="subtitle1"
            className={clsx(classes.operationChartLegendItem, "successful")}
          >
            Successful Operations
          </CustomTypography>

          <CustomTypography
            variant="subtitle1"
            className={clsx(classes.operationChartLegendItem, "unsuccessful")}
          >
            Un Successful Operations
          </CustomTypography>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(CustomAnalyticsOperationMetric)
);
