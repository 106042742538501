import {
  IconButton,
  Menu,
  MenuItem,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "../CustomTypography.web";
import { Check, MoreVert } from "@material-ui/icons";

import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";

interface Props extends WithStyles, WithTranslation {
  order: any;
  onDropDownClick(item: any, orderId: string): void;
}

type State = {
  openMenu: Element | null;
};

const styles = createStyles({
  customFilterItem: {
    display: "flex",
    alignItems: "center",
    padding: "9px 18px",
    gap: 8,

    "&.is-selected": {
      backgroundColor: "#FC9797",
      borderLeft: 3,
      borderLeftColor: "#FF6666",
      borderLeftStyle: "solid",
    },
  },

  customFilterName: {
    fontFamily: "Inter !important",
    fontSize: "14px !important",
    lineHeight: "22px !important",
    marginLeft: 8,
    textTransform: "capitalize",
  },
});

class CustomLoyaltySystemTableDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openMenu: null,
    };
  }

  open = (elem: HTMLButtonElement) => {
    this.setState({ openMenu: elem });
  };
  close = () => {
    this.setState({ openMenu: null });
  };

  render() {
    const { classes, order, onDropDownClick, t, i18n } = this.props;
    const { openMenu } = this.state;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    return (
      <>
        <IconButton
          size="small"
          onClick={(event) => this.open(event.currentTarget)}
        >
          <MoreVert />
        </IconButton>

        <Menu
          elevation={1}
          getContentAnchorEl={null}
          anchorEl={openMenu as Element}
          keepMounted
          open={Boolean(openMenu)}
          onClose={this.close}
          MenuListProps={{
            style: {
              minWidth: 134,
            },
            disablePadding: true,
          }}
        >
          {["accept", "reject"].map((item) => {
            const isSelected = order.attributes.status?.includes(item);

            return (
              <MenuItem
                key={item}
                className={clsx(
                  classes.customFilterItem,
                  isSelected && "is-selected"
                )}
                onClick={() => {
                  if (isSelected) {
                    return;
                  }
                  onDropDownClick(item, order.id);
                  this.close();
                }}
                dir={dir}
              >
                <Check
                  fontSize="small"
                  style={{ visibility: isSelected ? "visible" : "hidden" }}
                />
                <CustomTypography className={classes.customFilterName}>
                  {t(item)}
                </CustomTypography>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(CustomLoyaltySystemTableDropdown)
);
