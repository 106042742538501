import React from "react";
// Customizable Area Start
import { Box, withStyles, createStyles, Portal } from "@material-ui/core";
import KitchenTermAndConditionsController, {
  Props,
} from "./KitchenTermAndConditionsController.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomNavigationMenuEmpty from "../../../components/src/CustomNavigationMenuEmpty.web";
import { ArrowBack } from "@material-ui/icons";
import { bg } from "./assets";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";

const styles = createStyles({
  container: {},

  content: {
    marginTop: 16,
  },

  banner: {
    width: "100%",
    height: "auto",
    marginBottom: 24,
  },

  text: {
    fontFamily: "Inter !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    whiteSpace: "pre-wrap",
  },

  backToRegister: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& svg": {
      marginRight: 8,
    },
  },

  buttons: {
    marginTop: 122,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: 24,

    "& button": {
      width: 196,
    },
  },
});

// Customizable Area End

export class KitchenTermAndConditions extends KitchenTermAndConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const { content, loading, snackbar } = this.state;

    return (
      <CustomNavigationMenuEmpty
        title="Terms and Conditions"
        ToolbarTop={
          <CustomTypography
            className={classes.backToRegister}
            onClick={this.backToRegister}
          >
            <ArrowBack />
            Back to register
          </CustomTypography>
        }
        Content={
          <Box className={classes.container}>
            <CustomLoadingBackdrop open={loading} />

            <img src={bg} className={classes.banner} />

            <CustomTypography variant="h2">
              Terms and Conditions
            </CustomTypography>

            <Box className={classes.content}>
              {content.map((term) => (
                <CustomTypography className={classes.text} key={term.id}>
                  {term.description}
                </CustomTypography>
              ))}
            </Box>

            <Box className={classes.buttons}>
              <CustomButton
                data-test-id="cancel-btn"
                variant="contained"
                onClick={() => this.handleAgree(false)}
              >
                Cancel
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                data-test-id="button-agree"
                onClick={() => this.handleAgree(true)}
              >
                Agree
              </CustomButton>
            </Box>

            <Portal>
              <CustomSnackbar
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={snackbar.open}
                type={snackbar.type}
                message={snackbar.message}
                onClose={this.closeSnackbar}
              />
            </Portal>
          </Box>
        }
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(KitchenTermAndConditions);
// Customizable Area End
