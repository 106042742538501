// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { AdminFinanceStatistics } from "../../../components/src/AdminFinanceStatisticsCard.web";
import { WithTranslation } from "react-i18next";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { icArrowInscrease } from "./assets";
import { v4 as uuidv4 } from "uuid";
import {
  AdminPaymentFinanceDetailFormDialogMode,
  AdminPaymentFinanceDetailFormDialogProps,
} from "../../../components/src/AdminPaymentFinanceDetailFormDialog.web";
import React from "react";

export interface StatisticsDataProps {
  totalRevenue: AdminFinanceStatistics;
  totalCustomer: AdminFinanceStatistics;
  totalTransaction: AdminFinanceStatistics;
  totalRestaurant: AdminFinanceStatistics;
}

export interface FinanceData {
  transactionId: string | number;
  restaurantName: string;
  branch: string;
  email: string;
  startDate: string;
  dueDate: string;
  amount: number;
  status: string;
}

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  timeFilter: number;
  restaurantFilter: string;
  transactionSearchValue: string;
  statusFilter: string;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  financeData: FinanceData[];
  statisticsData: StatisticsDataProps;
  adminPaymentFinanceDialog: AdminPaymentFinanceDetailFormDialogProps;
}

interface SS {}

export default class AdminPaymentAdminFinanceController extends BlockComponent<
  Props,
  S,
  SS
> {
  timeOptions = [
    {
      id: uuidv4(),
      name: "Today",
      value: 1,
    },

    {
      id: uuidv4(),
      name: "Yesterday",
      value: 2,
    },

    {
      id: uuidv4(),
      name: "Last 7 days",
      value: 7,
    },

    {
      id: uuidv4(),
      name: "Last 30 days",
      value: 30,
    },

    {
      id: uuidv4(),
      name: "All",
      value: "",
    },
  ];

  restaurantOptions = [
    {
      id: uuidv4(),
      name: "Restaurant",
      value: "1",
    },
  ];

  tabList = [
    {
      name: "All Transaction",
      value: "",
      number: 10,
      color: "#B8A4FA",
    },
    {
      name: "Paid",
      value: "paid",
      number: 2,
      color: "#A0FFA1",
    },
    {
      name: "Pending",
      value: "pending",
      number: 7,
      color: "#FFAC61",
    },
    {
      name: "Unpaid",
      value: "unpaid",
      number: 1,
      color: "#F28181",
    },
  ];

  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.inputRef = React.createRef();

    this.state = {
      loading: false,
      statisticsData: {
        totalRevenue: {
          name: "Total Revenue",
          icon: icArrowInscrease,
          value: 12304,
          subValue: "+12.06",
        },

        totalCustomer: {
          name: "Total Customer",
          icon: icArrowInscrease,
          value: 10304,
          subValue: "+12.06",
        },

        totalTransaction: {
          name: "Total Transaction",
          icon: icArrowInscrease,
          value: 12304,
          subValue: "+12.06",
        },

        totalRestaurant: {
          name: "Total Restaurant",
          icon: icArrowInscrease,
          value: 840,
          subValue: "+12.06",
        },
      },
      timeFilter: 7,
      restaurantFilter: "1",
      transactionSearchValue: "",
      statusFilter: "",
      financeData: [],

      pagination: {
        page: 1,
        limit: 8,
        totalPages: 0,
        totalCount: 0,
      },

      adminPaymentFinanceDialog: {
        mode: AdminPaymentFinanceDetailFormDialogMode.View,
        open: false,
        loading: false,
        viewData: [],
        downloadData: [],
        title: "",
      },
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.fetchFinanceData();
  }

  fetchFinanceData = () => {
    const financeData = [
      {
        status: "Paid",
        email: "john@example.com",
        transactionId: "12345",
        restaurantName: "Mcdonald's",
        branch: "Mecca",
        startDate: "2024-03-15",
        dueDate: "2024-03-15",
        amount: 190,
      },
      {
        transactionId: "12347",
        restaurantName: "Jolli Bee",
        dueDate: "2024-03-15",
        amount: 270,
        status: "Unpaid",
        branch: "KinhDo",
        email: "john@example.com",
        startDate: "2024-03-15",
      },
      {
        dueDate: "2024-03-15",
        transactionId: "12347",
        branch: "Affet",
        email: "john@example.com",
        startDate: "2024-03-15",
        restaurantName: "KFC",
        amount: 270,
        status: "Pending",
      },
      {
        transactionId: "12347",
        restaurantName: "Lotteria",
        branch: "Lotte",
        email: "john@example.com",
        startDate: "2024-03-15",
        dueDate: "2024-03-15",
        amount: 270,
        status: "Unpaid",
      },
      {
        branch: "KinhDo",
        transactionId: "12347",
        restaurantName: "Jolli Bee",
        email: "john@example.com",
        dueDate: "2024-03-15",
        amount: 270,
        startDate: "2024-03-15",
        status: "Paid",
      },
      {
        restaurantName: "Metro",
        branch: "Meta",
        dueDate: "2024-03-15",
        email: "john@example.com",
        transactionId: "12347",
        startDate: "2024-03-15",
        amount: 270,
        status: "Paid",
      },
      {
        transactionId: "12347",
        dueDate: "2024-03-15",
        amount: 270,
        restaurantName: "Hell Kitchen",
        branch: "Gordon Ramsay",
        email: "john@example.com",
        startDate: "2024-03-15",
        status: "Pending",
      },
      {
        branch: "KinhDo",
        email: "john@example.com",
        startDate: "2024-03-15",
        dueDate: "2024-03-15",
        amount: 270,
        status: "Unpaid",
        transactionId: "12347",
        restaurantName: "Jolli Bee",
      },
    ];

    this.setState({
      financeData,
      pagination: {
        limit: 8,
        page: 1,
        totalCount: 8,
        totalPages: 1,
      },
    });
  };

  changePage = (page: number) => {
    console.log(page)
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchFinanceData();
      }
    );
  };

  openAdmiPaymentFinanceDialog = (
    mode: AdminPaymentFinanceDetailFormDialogMode,
    data: FinanceData
  ) => {
    let title = "";

    const downloadData = [
      {
        startDate: "02/05/2024",
        endDate: "02/05/2024",
        duration: "3 months",
        pricePaid: 100,
        vat: 146,
        total: 146,
      },
    ];

    const viewData = [
      {
        orderDetails: ["Burger", "Pizza", "Fried Chicken"],
        cash: 46.25,
        points: 100,
        date: "02/05/2024",
        paymentMethod: ["Cash", "Point"],
        orderNumber: "#234567",
        total: 146.25,
      },
      {
        orderDetails: ["Burger", "Pizza", "Fried Chicken"],
        paymentMethod: ["Cash", "Point"],
        date: "02/05/2024",
        orderNumber: "#234567",
        cash: 46.25,
        points: 100,
        total: 146.25,
      },
      {
        points: 100,
        total: 146.25,
        date: "02/05/2024",
        orderNumber: "#234567",
        orderDetails: ["Burger", "Pizza", "Fried Chicken"],
        paymentMethod: ["Cash", "Point"],
        cash: 46.25,
      },
    ];

    if (mode === AdminPaymentFinanceDetailFormDialogMode.View) {
      title =
        data.restaurantName +
        " " +
        this.props.t("Restaurant Invoice") +
        " (" +
        this.props.t("Branch") +
        " " +
        data.branch +
        ")";
    } else {
      title = data.restaurantName + " " + this.props.t("campaign");
    }

    this.setState({
      adminPaymentFinanceDialog: {
        ...this.state.adminPaymentFinanceDialog,
        open: true,
        title,
        mode,
        viewData,
        downloadData,
      },
    });
  };

  closeAdmiPaymentFinanceDialog = () => {
    this.setState({
      adminPaymentFinanceDialog: {
        mode: AdminPaymentFinanceDetailFormDialogMode.View,
        open: false,
        loading: false,
        viewData: [],
        downloadData: [],
        title: "",
      },
    });
  };

  changeCompensationDetailDialogMode = (
    mode: AdminPaymentFinanceDetailFormDialogMode
  ) => {
    this.setState({
      adminPaymentFinanceDialog: {
        ...this.state.adminPaymentFinanceDialog,
        mode,
      },
    });
  };

  handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        transactionSearchValue: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchFinanceData();
      }
    );
  };

  clearSearchTransactionId = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        transactionSearchValue: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchFinanceData();
      }
    );
  };

  changeTimeFilter = (timeFilter: number) => {
    this.setState(
      {
        timeFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchFinanceData();
      }
    );
  };

  changeActiveTab = (statusFilter: string) => {
    this.setState(
      {
        statusFilter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchFinanceData();
      }
    );
  };

  clearFilter = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        timeFilter: 7,
        restaurantFilter: "1",
        transactionSearchValue: "",
        statusFilter: "",
        financeData: [],

        pagination: {
          page: 1,
          limit: 8,
          totalPages: 0,
          totalCount: 0,
        },

        adminPaymentFinanceDialog: {
          mode: AdminPaymentFinanceDetailFormDialogMode.View,
          open: false,
          loading: false,
          viewData: [],
          downloadData: [],
          title: "",
        },
      },
      () => {
        this.fetchFinanceData();
      }
    );
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }
}

// Customizable Area End
