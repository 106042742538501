import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import CustomButton from "./CustomButton.web";
import CustomTypography from "./CustomTypography.web";
import KitchenDrawer from "./KitchenDrawer.web";
import KitchenOrderChangeAccordion, {
  KitchenOrderCatalogue,
  KitchenOrderChangeCategory,
} from "./KitchenOrderChangeAccordion.web";
import { KitchenOrder } from "./KitchenOrderTableRow.web";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomSnackbar, { CustomSnackbarType } from "./CustomSnackbar.web";

export interface Props extends WithStyles, WithTranslation {
  open: boolean;
  selectedOrder: KitchenOrder;
  categories: KitchenOrderChangeCategory[];
  loading: boolean;

  drawerSnackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  onClose(): void;
  onBack(): void;
  addCatalogue(catalogue: KitchenOrderCatalogue): void;
  notifyUser(): void;
  closeDrawerSnackbar(): void;
}

const styles = (theme: Theme) =>
  createStyles({
    container: {},

    title: {
      fontFamily: "Inter !important",
      lineHeight: "22px",
    },

    content: {
      marginTop: 13,
    },

    group: {
      "& + &": {
        marginTop: 27,
      },
    },

    declinedTitle: {
      color: "#DC2626",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    acceptedTitle: {
      color: "#059669",
      fontSize: "12px !important",
      lineHeight: "24px !important",
    },

    list: {
      marginTop: 10,
    },

    drawerBottom: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },

    loading: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    },

    snackbar: {
      position: "absolute",
      bottom: 90,
      width: 258,
    },
  });

export class KitchenOrderChangeDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const {
      classes,
      open,
      selectedOrder,
      categories,
      loading,
      t,
      i18n,
      drawerSnackbar,

      addCatalogue,
      onClose,
      onBack,
      notifyUser,
      closeDrawerSnackbar,
    } = this.props;

    const rtl = i18n.language === "ar";

    return (
      <KitchenDrawer
        open={open}
        title={t("Change Item")}
        dir={rtl ? "rtl" : ""}
        anchor={rtl ? "left" : "right"}
        onClose={onClose}
        width={568}
        body={
          <Box className={classes.container}>
            <CustomTypography variant="body1" className={classes.title}>
              {t("Order id {{orderId}} on date of {{date}} at {{time}}", {
                orderId: selectedOrder.orderId,
                date: moment(selectedOrder.time).format("DD/MM/yyyy"),
                time: moment(selectedOrder.time).format("h:mmA"),
              })}
            </CustomTypography>

            <Box className={classes.content}>
              {loading ? (
                <Box className={classes.loading}>
                  <CircularProgress color="inherit" />
                </Box>
              ) : (
                categories.map((category) => (
                  <KitchenOrderChangeAccordion
                    data-test-id={`category-${category.id}`}
                    category={category}
                    key={category.id}
                    addCatalogue={addCatalogue}
                  />
                ))
              )}
            </Box>
          </Box>
        }
        bottom={
          <Box className={classes.drawerBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onBack}
              data-test-id="back-btn"
            >
              {t("Back to Order Summary")}
            </CustomButton>

            <CustomButton
              data-test-id="notify-user-btn"
              color="primary"
              variant="contained"
              onClick={notifyUser}
            >
              {t("Notify User")}
            </CustomButton>

            <CustomSnackbar
              className={classes.snackbar}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={drawerSnackbar.open}
              type={drawerSnackbar.type}
              message={drawerSnackbar.message}
              onClose={closeDrawerSnackbar}
            />
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(KitchenOrderChangeDrawer));
