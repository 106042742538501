// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithTranslation } from "react-i18next";

export enum TABS {
  SALES = 0,
  ORDERS = 1,
  CUSTOMERS = 2,
}

export const configJSON = require("./config");

export interface Props extends WithTranslation {}

interface S {
  loading: boolean;
  tabActive: TABS;
  tabs: { value: TABS; name: string }[];

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  orderStatusOptions: {
    id: string;
    name: string;
    value: number | string;
  }[];

  statusFilterOptions: {
    id: string;
    name: string;
    value: number | string;
  }[];

  salesVisitChartYear: Date;
}

interface SS {
  id: any;
}

export default class AnalyticsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,

      tabActive: TABS.SALES,

      tabs: [
        { value: TABS.SALES, name: "Sales" },
        { value: TABS.ORDERS, name: "Orders" },
        { value: TABS.CUSTOMERS, name: "Customers" },
      ],

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },

      orderStatusOptions: MOCK_ORDER_STATUS_OPTIONS,

      statusFilterOptions: [
        {
          id: "-1",
          name: "Order Status",
          value: "all",
        },
      ],

      salesVisitChartYear: new Date(),
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  async componentDidMount() {
    const statusFilterOptions = JSON.parse(
      JSON.stringify(this.state.statusFilterOptions)
    );

    MOCK_ORDER_STATUS_OPTIONS.forEach(
      (option: {
        id: string | number;
        name: string;
        value: string | number;
      }) => {
        statusFilterOptions.push(option);
      }
    );

    this.setState({
      statusFilterOptions,
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    runEngine.debugLog("apiRequestCallId", apiRequestCallId);
    runEngine.debugLog("responseJson", responseJson);
  }

  changeActiveTab = (tab: TABS) => {
    this.setState({ tabActive: tab });
  };

  getActiveTabName = () => {
    switch (this.state.tabActive) {
      case TABS.ORDERS:
        return "Orders";

      case TABS.CUSTOMERS:
        return "Customers";

      case TABS.SALES:
        return "Sales";
    }
  };
}

const MOCK_ORDER_STATUS_OPTIONS = [
  {
    id: "1",
    name: "Approved",
    value: 1,
  },
  {
    id: "4",
    name: "Completed",
    value: 4,
  },
  {
    id: "5",
    name: "Declined",
    value: 5,
  },
  {
    id: "6",
    name: "Not Started",
    value: 6,
  },
  {
    id: "7",
    name: "Order is Preparing",
    value: 7,
  },
];
// Customizable Area End
