export const circleIcon = require("../assets/circle.png");
export const crossIcon = require("../assets/cross.png");
export const promotionIcon = require("../assets/ic_promotion.svg");
export const calenderIcon = require("../assets/ic_calender.svg");
export const iconCheck = require("../assets/ic_check.svg");
export const iconUncheck = require("../assets/ic_uncheck.svg");
export const iconView = require("../assets/ic_view.svg");
export const iconBoost = require("../assets/ic_boost.png");
export const iconCheckPerf = require("../assets/ic_check_perf.png");
export const iconPay = require("../assets/ic_pay.png");
export const imgInfo = require("../assets/info.png");