// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { CustomFilterMenu } from "../../../components/src/CustomFilter.web";

import { WithStyles } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";
import { WithTranslation } from "react-i18next";

export const configJSON = require("./config");

type Review = {
  id: string;
  type: "review";
  attributes: {
    id: number;
    catalogue_id: number;
    rating: number;
    comment: string;
    title: string;
    status: string;
    is_recommended: boolean;
    impressions: string;
    account_id: number;
    kitchen_account_id: number;
    created_at: string;
  };
};

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  loadingAllReviewNoFilter: boolean;
  loadingReply: boolean;
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  reviews: Review[];
  filterRating: string;
  filterRatingList: CustomFilterMenu[];
  date: string;
  drawerData: Review | null;
  ratingAvg: number;
}

interface SS {}

export default class ReviewsRatingAndReviewsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetAllReviewsId: string = "";
  apiGetAllReviewsNoFilterId: string = "";
  apiCreateReviewReplyId: string = "";
  apiUpdateReviewStatusId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      loadingAllReviewNoFilter: false,
      pagination: {
        limit: 6,
        page: 1,
        totalCount: 10,
        totalPages: 6,
      },
      snackbar: {
        message: "",
        open: false,
        type: undefined,
      },
      reviews: [],
      filterRating: "-1",
      filterRatingList: [
        { id: uuidv4(), name: "All", value: "0" },
        {
          id: uuidv4(),
          name: "5",
          value: "5",
        },
        {
          id: uuidv4(),
          name: "4",
          value: "4",
        },
        {
          id: uuidv4(),
          name: "3",
          value: "3",
        },
        {
          id: uuidv4(),
          name: "2",
          value: "2",
        },
        {
          id: uuidv4(),
          name: "1",
          value: "1",
        },
      ],
      date: "",
      drawerData: null,
      ratingAvg: 0,
      loadingReply: false,
    };
  }

  getAllReviews = () => {
    this.setState({ loading: true });

    const header = {
      token: window.localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAllReviewsId = requestMessage.messageId;

    const query = new URLSearchParams();

    const rating =
      this.state.filterRating === "-1" || this.state.filterRating === "0"
        ? ""
        : this.state.filterRating;

    query.append("page", this.state.pagination.page.toString());
    query.append("per_page", this.state.pagination.limit.toString());
    query.append("rating", rating);
    query.append("created_at", this.state.date);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReviewsAPiEndPoint + "?" + query.toString()
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllReviewsNoFilter = () => {
    this.setState({ loadingAllReviewNoFilter: true });

    const header = {
      token: window.localStorage.getItem("token"),
    };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetAllReviewsNoFilterId = message.messageId;

    const queryParams = new URLSearchParams();

    const rating =
      this.state.filterRating === "-1" || this.state.filterRating === "0"
        ? ""
        : this.state.filterRating;

    queryParams.append("page", this.state.pagination.page.toString());
    queryParams.append("per_page", this.state.pagination.limit.toString());
    queryParams.append("rating", rating);
    queryParams.append("created_at", this.state.date);

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReviewsAPiEndPoint + "?" + queryParams.toString()
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(message.id, message);
  };

  createReviewReply = (reply: string) => {
    if (!this.state.drawerData) {
      return;
    }

    this.setState({ loadingReply: true });

    const header = {
      token: window.localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCreateReviewReplyId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReviewsAPiEndPoint +
        `/${this.state.drawerData.id}/create_reply`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        comment: reply,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateReviewStatus = (checked: boolean, id: string) => {
    this.setState({ loading: true });

    const header = {
      token: window.localStorage.getItem("token"),
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiUpdateReviewStatusId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReviewsAPiEndPoint + `/${id}/change_status`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        status: checked ? 1 : 0,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    this.getAllReviews();
    this.getAllReviewsNoFilter();
  }

  handleGetAllReviews = (responseJson?: {
    data?: Review[];
    pagination: {
      current_page: number;
      total_pages: number;
      total_count: number;
    };
  }) => {
    this.setState({ loading: false });
    if (responseJson && responseJson.data) {
      this.setState({
        reviews: responseJson.data,
        pagination: {
          limit: 6,
          page: responseJson.pagination.current_page,
          totalCount: responseJson.pagination.total_count,
          totalPages: responseJson.pagination.total_pages,
        },
      });
    }
  };

  handleGetAllReviewsNoFilter = (responseJson?: { data?: Review[] }) => {
    this.setState({ loadingAllReviewNoFilter: false });
    if (responseJson && responseJson.data) {
      const totalCount = responseJson.data.length;

      const ratingSum = responseJson.data.reduce(
        (total, review) => total + review.attributes.rating,
        0
      );
      const ratingAvg = totalCount === 0 ? 0 : ratingSum / totalCount;

      this.setState({
        ratingAvg,
      });
    }
  };

  handleCreateReviewReply = (responseJson?: { message?: string }) => {
    this.setState({
      loadingReply: false,
    });
    if (
      responseJson &&
      responseJson.message &&
      responseJson.message === "Reply added successfully"
    ) {
      this.closeDrawer();
      this.setState({
        snackbar: {
          open: true,
          message: this.props.t(responseJson.message),
          type: CustomSnackbarType.Success,
        },
      });
    }
  };

  handleUpdateReviewStatus = (responseJson?: { data?: { id?: string } }) => {
    this.setState({
      loading: false,
    });
    if (responseJson && responseJson.data && responseJson.data.id) {
      this.getAllReviews();
    }
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.apiGetAllReviewsId:
        this.handleGetAllReviews(responseJson);
        break;
      case this.apiGetAllReviewsNoFilterId:
        this.handleGetAllReviewsNoFilter(responseJson);
        break;
      case this.apiCreateReviewReplyId:
        this.handleCreateReviewReply(responseJson);
        break;
      case this.apiUpdateReviewStatusId:
        this.handleUpdateReviewStatus(responseJson);
        break;
    }
  }

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.getAllReviews();
      }
    );
  };

  changeFilterRating = (filter: string) => {
    this.setState(
      {
        filterRating: filter,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.getAllReviews();
        this.getAllReviewsNoFilter();
      }
    );
  };

  toggleShowAll = () => {
    const isShowAll = this.getShowAll();
    if (!isShowAll) {
      this.setState({ date: "", filterRating: "-1" }, () => {
        this.getAllReviews();
        this.getAllReviewsNoFilter();
      });
    }
  };

  changeDate = (date: Date | null) => {
    const extractedDate = date
      ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      : "";
    this.setState(
      {
        date: extractedDate,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.getAllReviews();
        this.getAllReviewsNoFilter();
      }
    );
  };

  openDrawer = (review: Review) => {
    this.setState({ drawerData: review });
  };

  closeDrawer = () => {
    this.setState({ drawerData: null });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        message: "",
        type: undefined,
        open: false,
      },
    });
  };

  getShowAll = () => {
    const isNoRatingFilter =
      this.state.filterRating === "0" || this.state.filterRating === "-1";
    const isNoDateFilter = this.state.date === "";

    return isNoDateFilter && isNoRatingFilter;
  };

  handleSubmit = (values: { reply: string }) => {
    this.createReviewReply(values.reply);
  };
}

// Customizable Area End
