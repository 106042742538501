import { IconButton, MenuItem, styled } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { InferType, object, string } from "yup";
import CustomButton from "../CustomButton.web";
import CustomTypographyWeb from "../CustomTypography.web";
import {
  StylesDialog,
  StylesDialogActions,
  StylesDialogContent,
  StylesDialogTitle,
} from "../promocodes/CustomPromocodesCreateDiscountDialog.web";

import CustomFormInputFieldWeb from "../CustomFormInputField.web";
import CustomFormSelectFieldWeb from "../CustomFormSelectField.web";
import { StyledListItemText } from "../../../blocks/promocodes/src/PromocodesPromotionsCreate.web";
import CustomSelectTime from "../CustomSelectTime.web";

const schema = object({
  restaurant_name: string(),
  branch: string(),
  day_of_week: string(),
  opening_time: string(),
  closing_time: string(),
});

type Schema = InferType<typeof schema>;

interface Props extends WithTranslation {
  open: boolean;
  onClose: () => void;
  initValue: Schema | null;
}

type State = {
  timeOfDayList: string[];
  weekdays: string[];
};

const StyledForm = styled(Form)({
  "& [class*='CustomFormInputField-container'], & [class*='CustomFormSelectField-container']":
    {
      marginBlockStart: 0,
    },
  "& > div.MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    overflowY: "auto",
  },
  "& div:has(.form__input__times)": {
    marginBlockStart: 0,
  },

  "& p.form__inputLabel": {
    color: "#64748B",
    fontWeight: 600,
    lineHeight: 22 / 14,
  },
  "& .form__input__time ul.form__input__timeList": {
    top: "unset",
    bottom: "100%",
  },
});

class CustomLocationScheduleEditDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let timeOfDayList: string[] = [];

    for (let i = 0; i <= 12; i++) {
      const date2 = `${i}:30`.padStart(4, "0");
      const date1 = `${i}:00`.padStart(4, "0");

      timeOfDayList.push(date1, date2);
    }

    this.state = {
      timeOfDayList,
      weekdays: [
        "Tuesday",
        "Saturday",
        "Wednesday",
        "Sunday",
        "Thursday",
        "Monday",
        "Friday",
      ],
    };
  }

  render(): React.ReactNode {
    const { i18n, t, open, onClose, initValue } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    const initialValues = initValue ?? schema.default();

    return (
      <>
        <StylesDialog
          aria-labelledby="formDialogEditSchedule"
          dir={dir}
          open={open}
          onClose={onClose}
        >
          <StylesDialogTitle id="formDialogEditSchedule" dir={dir}>
            <CustomTypographyWeb className="StylesDialogTitle__title">
              {t("Edit Timing")}
            </CustomTypographyWeb>
            <IconButton
              aria-label="close"
              className="StylesDialogTitle__btnClose"
              onClick={onClose}
            >
              <CloseRounded htmlColor="#334155" />
            </IconButton>
          </StylesDialogTitle>
          <Formik<Schema>
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={() => {}}
          >
            {(props) => {
              return (
                <StyledForm>
                  <StylesDialogContent>
                    <CustomFormInputFieldWeb
                      fullWidth
                      name="restaurant_name"
                      label={t("Restaurant Name")}
                      variant="outlined"
                      error={
                        props.touched.restaurant_name &&
                        !!props.errors.restaurant_name
                      }
                    />
                    <CustomFormInputFieldWeb
                      fullWidth
                      name="branch"
                      label={t("Branch")}
                      variant="outlined"
                      error={props.touched.branch && !!props.errors.branch}
                    />
                    <CustomFormSelectFieldWeb
                      fullWidth
                      name="day_of_week"
                      label={t("Day of Week")}
                      id="formSelectDayOfWeek"
                      data-test-id="formSelectDayOfWeek"
                      variant="outlined"
                      error={
                        props.touched.day_of_week && !!props.errors.day_of_week
                      }
                      dir={dir}
                    >
                      {this.state.weekdays.map((weekday) => {
                        return (
                          <MenuItem value={weekday} dir={dir}>
                            <StyledListItemText
                              primary={t(weekday)}
                              dir={dir}
                            />
                          </MenuItem>
                        );
                      })}
                    </CustomFormSelectFieldWeb>
                    <CustomSelectTime
                      labelFromOutside={t("Opening Time")}
                      labelFrom={`${
                        props.values.opening_time.split(" ")[0]
                      } ${t(props.values.opening_time.split(" ")[1])}`}
                      labelTo={`${props.values.closing_time.split(" ")[0]} ${t(
                        props.values.closing_time.split(" ")[1]
                      )}`}
                      labelToOutside={t("Closing Time")}
                      formikKeyFrom={"opening_time"}
                      formikKeyTo={"closing_time"}
                    />
                  </StylesDialogContent>
                  <StylesDialogActions dir={dir}>
                    <CustomButton
                      color="primary"
                      variant="contained"
                      fullWidth
                      className="StylesDialogActions__btnCancel"
                      onClick={onClose}
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {t("Save")}
                    </CustomButton>
                  </StylesDialogActions>
                </StyledForm>
              );
            }}
          </Formik>
        </StylesDialog>
      </>
    );
  }
}

export default withTranslation()(CustomLocationScheduleEditDialog);
