// Customizable Area Start
import React from "react";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import AdminPromocodesPromotionsController, {
  Props,
} from "./AdminPromocodesPromotionsController.web";
import { withTranslation } from "react-i18next";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import clsx from "clsx";
import moment from "moment";
import {
  Box,
  IconButton,
  InputAdornment,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Portal,
} from "@material-ui/core";
import { Close, Search, SaveAlt } from "@material-ui/icons";
import AdminPromotionDetailFormDialog from "../../../components/src/AdminPromotionDetailFormDialog.web";
import AdminPromotionManagementMenu from "../../../components/src/AdminPromotionManagementMenu.web";
import AdminPromotionManagementStatusTag from "../../../components/src/AdminPromotionManagementStatusTag.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomInputField from "../../../components/src/CustomInputField.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        gap: 10,
      },
    },

    filter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    filterSelect: {
      width: "100%",
    },

    statusFilterSelect: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 3,
        flex: 1,
      },
    },

    filterSelectTime: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 2,
        flex: 1,
      },
    },

    downloadButton: {
      marginLeft: "auto",

      "&.rtl": {
        marginLeft: 0,
        marginRight: "auto",
      },
    },

    table: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 38,
    },

    promotionTable: {
      "& td": {
        padding: 16,
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    promotionId: {
      fontFamily: "Inter !important",
    },

    discountType: {
      fontFamily: "Inter !important",
    },

    discount: {
      fontFamily: "Inter !important",
    },

    name: {
      fontFamily: "Inter !important",
    },

    startDate: {
      fontFamily: "Inter !important",
    },

    endDate: {
      fontFamily: "Inter !important",
    },

    pagination: {
      marginTop: 24,
    },

    action: {
      display: "flex",
      gap: 5,
      justifyContent: "end",
    },
  });

export class AdminPromocodesPromotions extends AdminPromocodesPromotionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const {
      adminPromotions,
      timeFilter,
      statusFilter,
      statusFilterOptions,
      promotionIdSearch,
      loading,
      snackbar,
      pagination,
      adminPromotionDetailDialog,
    } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.actions}>
          <Box className={classes.filter}>
            <Box className={classes.filterSelectTime}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.filterSelect}
                data-test-id="time-select"
                variant="outlined"
                value={timeFilter}
                onChange={(e: any) => this.changeTimeFilter(e.target.value)}
              >
                {this.timeOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box className={classes.statusFilterSelect}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.filterSelect}
                data-test-id="status-select"
                variant="outlined"
                value={statusFilter}
                onChange={(e: any) => this.changeStatusFilter(e.target.value)}
                displayEmpty
                inputProps={{
                  renderValue: (selected: string) => {
                    if (!selected) {
                      return t("Status");
                    }

                    const selectedOption = statusFilterOptions.find(
                      (option: any) => option.value === selected
                    );

                    if (!selectedOption) {
                      return "";
                    }

                    return selectedOption.name;
                  },
                }}
              >
                {statusFilterOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <CustomInputField
              data-test-id="search-promotion-id-input"
              className={classes.promotionIdSearch}
              inputRef={this.inputRef}
              variant="outlined"
              placeholder={t("Promotion ID")}
              InputProps={{
                endAdornment: (
                  <>
                    {promotionIdSearch && (
                      <IconButton
                        data-test-id="clear-search-request-btn"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.clearPromotionIdSearch}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyDown={this.handleSearch}
            />
          </Box>

          <CustomButton
            className={clsx(classes.downloadButton, { ["rtl"]: rtl })}
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="download-order-report-btn"
            onClick={this.downloadReport}
          >
            {t("Download Report")}
          </CustomButton>
        </Box>

        <Box className={classes.table}>
          <TableContainer>
            <CustomTable className={classes.promotionTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Promotion ID")}</TableCell>
                  <TableCell>{t("Promotion Name")}</TableCell>
                  <TableCell>{t("Discount Type")}</TableCell>
                  <TableCell>{t("Discount")}</TableCell>
                  <TableCell>{t("Start Date")}</TableCell>
                  <TableCell>{t("End Date")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {adminPromotions.length ? (
                  adminPromotions.map((promotion) => (
                    <TableRow
                      key={promotion.id}
                      data-test-id={`table-row-${promotion.id}`}
                    >
                      <TableCell className={classes.promotionId}>
                        {promotion.promotionId}
                      </TableCell>
                      <TableCell className={classes.name}>
                        {promotion.name}
                      </TableCell>
                      <TableCell className={classes.discountType}>
                        {promotion.discountType}
                      </TableCell>
                      <TableCell className={classes.discount}>
                        {promotion.discount}
                      </TableCell>
                      <TableCell className={classes.startDate}>
                        {moment(promotion.startDate).format("yyyy-MM-DD")}
                      </TableCell>
                      <TableCell className={classes.endDate}>
                        {moment(promotion.endDate).format("yyyy-MM-DD")}
                      </TableCell>
                      <TableCell className={classes.status}>
                        <AdminPromotionManagementStatusTag
                          status={promotion.status}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className={classes.action}>
                          <AdminPromotionManagementMenu
                            data-test-id="menu"
                            onSelectAction={this.openAdminPromotionDetailDialog}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              data-test-id="pagination"
              fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        <AdminPromotionDetailFormDialog
          data-test-id="admin-promotion-detail-dialog"
          dialog={adminPromotionDetailDialog}
          onClose={this.closeAdminPromotionDetailDialog}
          editAdminPromotion={this.closeAdminPromotionDetailDialog}
          changePromotionDetailDialogMode={this.changePromotionDetailDialogMode}
        />

        <CustomLoadingBackdrop open={loading} />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(AdminPromocodesPromotions));
// Customizable Area End
