import {
  Box,
  FormHelperText,
  IconButton,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { Component, ChangeEvent, DragEvent } from "react";
import clsx from "clsx";
import { Close } from "@material-ui/icons";

interface ImageUploaderState {
  image: string | null;
  dragging: boolean;
}

export interface CustomSingleImageUploadProps extends WithStyles<any> {
  name?: string;
  helperText?: string;
  error?: boolean;
  value: File | string | null;
  placeholder?: string;
  dir?: "rtl" | "ltr";

  onChange(file: File | null): void;
}

const styles = () =>
  createStyles({
    imageUploader: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "1px dashed #333333",
      position: "relative",
      width: "100%",
      height: 252,
      cursor: "pointer",
      transition: "border-color 0.3s",
      borderRadius: 8,
      backgroundColor: "#FFFBF4",
      color: "#333333",

      "&.is-dragging": {
        borderColor: "#61dafb",
      },

      "&.error": {
        borderColor: "#f44336",
        color: "#f44336 ",
      },
    },

    input: {
      display: "none",
    },

    uploadInput: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      borderRadius: 8,
      overflow: "hidden",
    },

    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },

    removeButton: {
      position: "absolute",
      top: 0,
      right: 0,

      "&.rtl": {
        left: 0,
        right: "initial",
      },
    },

    empty: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      fontFamily: "Raleway",
      fontSize: 12,
      lineHeight: "22px",
    },

    errorText: {
      color: "#DC2626",
      fontSize: 12,
      fontFamily: "Inter",
      lineHeight: "18px",
      textAlign: "initial",
    },
  });

class CustomSingleImageUpload extends Component<
  CustomSingleImageUploadProps,
  ImageUploaderState
> {
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: CustomSingleImageUploadProps) {
    super(props);

    this.state = {
      image: null,
      dragging: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidMount(): void {
    // eslint-disable-next-line no-undef
    if (this.props.value instanceof File) {
      this.handleReadFile(this.props.value);

      return;
    }

    this.setImage(this.props.value);
  }

  setImage = (image: string | null) => {
    this.setState({
      image,
    });
  };

  handleReadFile = (file: File) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({ image: reader.result as string });
    };
    reader.readAsDataURL(file);
  };

  handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && this.isValidFileType(file)) {
      // eslint-disable-next-line no-undef
      this.handleReadFile(file);
      this.props.onChange(file);
    }
  };

  handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    this.setState({ dragging: false });
    const file = event.dataTransfer.files[0];
    if (file && this.isValidFileType(file)) {
      this.handleReadFile(file);
      this.props.onChange(file);
    }
  };

  handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!this.state.dragging) {
      this.setState({ dragging: true });
    }
  };

  handleDragLeave = () => {
    this.setState({ dragging: false });
  };

  handleRemoveImage = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ image: null });
    this.props.onChange(null);

    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }
  };

  isValidFileType(file: File): boolean {
    return file.type === "image/jpeg" || file.type === "image/png";
  }

  render() {
    const { classes, name, helperText, error, placeholder, dir } = this.props;
    const { image, dragging } = this.state;

    return (
      <>
        <Box
          className={clsx(classes.imageUploader, {
            ["is-dragging"]: dragging,
            ["error"]: error,
          })}
          onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}
          onDrop={this.handleDrop}
        >
          <input
            className={classes.input}
            type="file"
            accept="image/jpeg, image/png"
            onChange={this.handleImageChange}
            id="fileInput"
            name={name}
            ref={this.inputRef}
          />
          <label htmlFor="fileInput" className={classes.uploadInput}>
            {image ? (
              <>
                <img src={image} alt="Uploaded" className={classes.image} />
                <IconButton
                  onClick={this.handleRemoveImage}
                  className={clsx(classes.removeButton, dir)}
                >
                  <Close />
                </IconButton>
              </>
            ) : (
              <Box className={classes.empty}>
                <img src={require("./ic_upload.svg")} alt="upload" />
                {placeholder || ""}
              </Box>
            )}
          </label>
        </Box>
        {helperText && (
          <FormHelperText className={classes.errorText}>
            {helperText}
          </FormHelperText>
        )}
      </>
    );
  }
}

export default withStyles(styles)(CustomSingleImageUpload);
