import React from "react";
import { Box, styled } from "@material-ui/core";
import CustomTypographyWeb from "../CustomTypography.web";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton.web";
import { imgInfo } from "../../../blocks/promocodes/src/assets";

const MOCK_CONTENT = `Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.
It’s also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact – there is no difference. 
In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.

Terms and conditions outline what users can and cannot do with your website, products, and services. They lay out the rules to protect you in case of misuse and enable you to take action if it becomes necessary.
It’s also referred to by other names such as terms of service (ToS) and terms of use (ToU). Even though they have different names, in fact – there is no difference. 
In order to use your website, products, or services, your customers usually must agree to abide by your terms and conditions first.`;

type Props = {
  type: "tnc" | "pp";
  onCancel(): void;
  onAgree(): void;
};

export const Container = styled(Box)(({ theme }) => {
  return {
    "& img.banner": {
      maxWidth: "100%",
      width: "100%",
      maxHeight: 221,
      objectFit: "cover",
      objectPosition: "0 24%",
    },
    "& .title": {
      marginBlock: 24,
    },
    "& .content": {
      whiteSpace: "pre",
      marginBlockStart: 16,
      color: "#0F172A",
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 24 / 16,
    },
    "& .actions": {
      "& button": {
        maxWidth: 196,
        height: 44,
      },
    },
  };
});

export default function CustomPromocodesAdvertisingInfoPage({
  type,
  onAgree,
  onCancel,
}: Props) {
  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";
  const title =
    type === "tnc" ? t("Terms and Conditions") : t("Privacy policy");

  return (
    <Container dir={dir}>
      <img src={imgInfo} className="banner" />
      <CustomTypographyWeb variant="h2" className="title">
        {title}
      </CustomTypographyWeb>

      <CustomTypographyWeb
        className="content"
        dangerouslySetInnerHTML={{ __html: MOCK_CONTENT }}
      />

      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        dir={dir}
        mt={14}
        className="actions"
        gridGap={24}
      >
        <CustomButton
          variant="contained"
          color="primary"
          dir={dir}
          fullWidth
          onClick={onCancel}
          data-variant="cancel"
        >
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          dir={dir}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onAgree}
        >
          {t("Agree")}
        </CustomButton>
      </Box>
    </Container>
  );
}
