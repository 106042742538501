import { Box, WithStyles, createStyles, withStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomTypography from "./CustomTypography.web";

export interface Props extends WithStyles, WithTranslation {
  status: string;

  onSelectAction(value: string): void;
}

const styles = createStyles({
  action: {
    display: "flex",
    gap: 5,
    alignItems: "center",
  },

  actionButton: {
    borderRadius: 14,
    padding: "6px 9px",
    cursor: "pointer",

    "&:hover": {
      opacity: 0.5,
    },

    "&.approved": {
      backgroundColor: "#059669",
    },

    "&.rejected": {
      backgroundColor: "#FF6666",
    },
  },

  actionButtonText: {
    fontSize: "10px !important",
    fontWeight: "500 !important" as any,
    lineHeight: "11.74px !important",
    textAlign: "center",
    color: "#FFFFFF !important",
  },
});

export class AdminCompensationManagementStatus extends React.Component<
  Props,
  {}
> {
  render(): React.ReactNode {
    const { classes, t, i18n, status } = this.props;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.action, { rtl })}>
        {["approved", "pending"].includes(status) && (
          <Box className={clsx(classes.actionButton, "approved")}>
            <CustomTypography className={classes.actionButtonText}>
              {t("Approved")}
            </CustomTypography>
          </Box>
        )}
        {["pending", "rejected"].includes(status) && (
          <Box className={clsx(classes.actionButton, "rejected")}>
            <CustomTypography className={classes.actionButtonText}>
              {t("Reject")}
            </CustomTypography>
          </Box>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminCompensationManagementStatus)
);
