// Customizable Area Start
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  Portal,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Close, SaveAlt, Search } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import { withTranslation } from "react-i18next";
import AdminCompensationManagementMenu from "../../../components/src/AdminCompensationManagementMenu.web";
import AdminCompensationManagementStatusTag from "../../../components/src/AdminCompensationManagementStatusTag.web";
import AdminCompensationManagementStatus from "../../../components/src/AdminCompensationManagementStatus.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTable from "../../../components/src/CustomTable";
import AdminLoyaltySystemCompensationsController, {
  Props,
} from "./AdminLoyaltySystemCompensationsController.web";
import AdminCompensationDetailFormDialog from "../../../components/src/AdminCompensationDetailFormDialog.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    compensationFilter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    compensationAction: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        gap: 10,
      },
    },

    compensationFilterSelect: {
      width: "100%",
    },

    restaurantFilterSelect: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 3,
        flex: 1,
      },
    },

    requestIdSearch: {
      "& input::placeholder": {
        opacity: 1,
      },
    },

    filterSelectTime: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 2,
        flex: 1,
      },
    },

    downloadCompensationButton: {
      marginLeft: "auto",

      "&.rtl": {
        marginLeft: 0,
        marginRight: "auto",
      },
    },

    compensationList: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 38,
    },

    compensationTable: {
      "& td": {
        padding: 16,
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    requestId: {
      fontFamily: "Inter !important",
    },

    branch: {
      fontFamily: "Inter !important",
    },

    restaurantName: {
      fontFamily: "Inter !important",
    },

    dateSubmitted: {
      fontFamily: "Inter !important",
    },

    amount: {
      fontFamily: "Inter !important",
    },

    role: {
      fontFamily: "Inter !important",
    },

    compensationPagination: {
      marginTop: 24,
    },

    action: {
      display: "flex",
      gap: 5,
      justifyContent: "end",
    },
  });

export class AdminLoyaltySystemCompensations extends AdminLoyaltySystemCompensationsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const {
      adminCompensations,
      timeFilter,
      restaurantFilter,
      restaurantFilterOptions,
      requestIdSearch,
      loading,
      snackbar,
      pagination,
      adminCompensationDetailDialog,
    } = this.state;

    const rtl = i18n.language === "ar";

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.compensationAction}>
          <Box className={classes.compensationFilter}>
            <Box className={classes.filterSelectTime}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.compensationFilterSelect}
                data-test-id="time-select"
                variant="outlined"
                value={timeFilter}
                onChange={(e: any) => this.changeTimeFilter(e.target.value)}
              >
                {this.timeOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box className={classes.restaurantFilterSelect}>
              <CustomSelect
                dir={rtl ? "rtl" : ""}
                className={classes.compensationFilterSelect}
                data-test-id="restaurant-select"
                variant="outlined"
                value={restaurantFilter}
                onChange={(e: any) =>
                  this.changeRestaurantFilter(e.target.value)
                }
                displayEmpty
                inputProps={{
                  renderValue: (selected: string) => {
                    if (!selected) {
                      return t("Restaurants");
                    }

                    const selectedOption = restaurantFilterOptions.find(
                      (option: any) => option.value === selected
                    );

                    if (!selectedOption) {
                      return "";
                    }

                    return selectedOption.name;
                  },
                }}
              >
                {restaurantFilterOptions.map((option) => (
                  <CustomMenuItem value={option.value} key={option.id}>
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <CustomInputField
              data-test-id="search-request-id-input"
              className={classes.requestIdSearch}
              inputRef={this.inputRef}
              variant="outlined"
              placeholder={t("Request ID")}
              InputProps={{
                endAdornment: (
                  <>
                    {requestIdSearch && (
                      <IconButton
                        data-test-id="clear-search-request-btn"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.clearRequestIdSearch}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyDown={this.handleSearch}
            />
          </Box>

          <CustomButton
            className={clsx(classes.downloadCompensationButton, {
              ["rtl"]: rtl,
            })}
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="download-order-report-btn"
            onClick={this.downloadReport}
          >
            {t("Download Report")}
          </CustomButton>
        </Box>

        <Box className={classes.compensationList}>
          <TableContainer>
            <CustomTable className={classes.compensationTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Request ID")}</TableCell>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Branch")}</TableCell>
                  <TableCell>{t("Date Submitted")}</TableCell>
                  <TableCell>{t("Amount")}</TableCell>
                  <TableCell>{t("Request Status")}</TableCell>
                  <TableCell>{t("Action")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {adminCompensations.length ? (
                  adminCompensations.map((compensation) => (
                    <TableRow
                      key={compensation.id}
                      data-test-id={`compensationList-row-${compensation.id}`}
                    >
                      <TableCell className={classes.requestId}>
                        {compensation.requestId}
                      </TableCell>
                      <TableCell className={classes.restaurantName}>
                        {compensation.restaurantName}
                      </TableCell>
                      <TableCell className={classes.branch}>
                        {compensation.branch}
                      </TableCell>
                      <TableCell className={classes.dateSubmitted}>
                        {moment(compensation.dateSubmitted).format(
                          "yyyy-MM-DD"
                        )}
                      </TableCell>
                      <TableCell className={classes.amount}>
                        {t("{{number}} AED", { number: compensation.amount })}
                      </TableCell>
                      <TableCell className={classes.status}>
                        <AdminCompensationManagementStatusTag
                          status={compensation.status}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          width: 124,
                        }}
                      >
                        <Box className={classes.action}>
                          <AdminCompensationManagementStatus
                            status={compensation.status}
                            onSelectAction={() => {}}
                          />

                          <AdminCompensationManagementMenu
                            data-test-id="menu"
                            onSelectAction={
                              this.openAdminCompensationDetailDialog
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.compensationPagination}>
            <CustomPagination
              data-test-id="pagination"
              fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(event, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        <AdminCompensationDetailFormDialog
          data-test-id="admin-compensation-detail-dialog"
          dialog={adminCompensationDetailDialog}
          onClose={this.closeAdminCompensationDetailDialog}
          editAdminCompensation={this.closeAdminCompensationDetailDialog}
          changeCompensationDetailDialogMode={
            this.changeCompensationDetailDialogMode
          }
        />

        <CustomLoadingBackdrop open={loading} />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminLoyaltySystemCompensations)
);
// Customizable Area End
