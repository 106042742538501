import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  styled,
} from "@material-ui/core";
import React from "react";
import CustomTypography from "./CustomTypography.web";

export interface Props extends CheckboxProps {
  label: React.ReactNode;
}

const CheckboxLabel = styled(CustomTypography)({
  color: "#0F172A",
  fontSize: "1rem",
  lineHeight: 24 / 16,
});

const StyledFormControlLabel = styled(FormControlLabel)({
  "&.MuiFormControlLabel-root[dir='rtl']": {
    marginRight: -9,
    marginLeft: 0,
  },
});

export class CustomFormCheckBox extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { label, ...rest } = this.props;

    return (
      <StyledFormControlLabel
        dir={rest.dir}
        control={
          <Checkbox
            {...rest}
            icon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="19"
                  height="19"
                  rx="5.5"
                  stroke="#64748B"
                />
              </svg>
            }
            checkedIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="20" height="20" rx="6" fill="#FF6666" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z"
                  fill="white"
                />
              </svg>
            }
          />
        }
        label={<CheckboxLabel>{label}</CheckboxLabel>}
      />
    );
  }
}

export default CustomFormCheckBox;
